// {
//   "id": 3,
//   "name": "Manual Process",
//   "description": "Manual Process",
//   "badge": "Manual",
//   "bg_color": "#74d2d2",
//   "date_modified": "2022-10-18",
//   "date_entered": "2021-03-12"
// }

export const Columns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Category",
    accessor: "name",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Badge",
    accessor: "badge",
  },
  {
    Header: "Category Color",
    accessor: "bg_color",
  },
];
