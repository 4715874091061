import baseUrl from "../config/baseUrl";

const pdfVersionDetails = async (token, documentID) => {
  // const formData = new FormData();
  // formData.append("document_id", documentID);
  try {
    const response = await fetch(
      `${baseUrl}/api/get-document-version?document_id=${documentID}`,

      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        // body: formData,
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default pdfVersionDetails;
