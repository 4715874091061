import React from "react";
import TextField from "@mui/material/TextField";

const InputUI = ({
	name,
	label,
	type,
	value,
	setValue,
	variant,
	fullWidth,
	required,
	cssStyles,
	disabled,
	register,
}) => {
	return (
		<TextField
			name={name}
			label={label}
			type={type}
			autoComplete="off"
			value={value}
			onChange={(e) => setValue(e.target.value)}
			variant={variant}
			fullWidth={fullWidth}
			required={required}
			style={cssStyles}
			disabled={disabled}
			{...register(name)}
		/>
	);
};

export default InputUI;
