import { Skeleton } from "../../shadcn-components/ui/skeleton";
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../shadcn-components/ui/table";

const TableSkeleton = () => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableHead>
          <TableHead>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableHead>
          <TableHead>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableHead>
          <TableHead>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableHead>
          <TableHead>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableHead>
          <TableHead>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableHead>
          <TableHead>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableHead>
          <TableHead>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
          <TableCell>
            <Skeleton className="w-full h-[20px] rounded-full" />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default TableSkeleton;
