import React, { useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import PermanentlyDeleteAllCategory from "./PermanentlyDeleteAllCategory";
import RestoreAllCategory from "./RestoreAllCategory";

const Dropdown = ({
  selectedRows,
  isEntireTableRowsSelected,
  setCategoryRestoredToTrue,
  setCategoryDeletedToTrue,
}) => {
  const [open, setOpen] = useState(false);
  const [docId, setDocId] = useState();

  const changeDropdownVisibilityFunc = () => {
    setOpen(!open);
  };

  const handleSelectedRows = (selectedRows) => {
    setDocId(selectedRows);
  };

  return (
    <div className="dropdown-button">
      <IoMdArrowDropdown
        onClick={changeDropdownVisibilityFunc}
        style={{ visibility: selectedRows.length > 0 ? "visible" : "hidden" }}
      />
      <div
        className="elements"
        style={{
          display: open ? "block" : "none",
          zIndex: 10,
          marginTop: "3px",
        }}
      >
        <ul className="docs-dropdown-list">
          <li
            className="docs-dropdown-item"
            onClick={() => handleSelectedRows(selectedRows)}
          >
            <RestoreAllCategory
              setCategoryRestoredToTrue={setCategoryRestoredToTrue}
              isEntireTableRowsSelected={isEntireTableRowsSelected}
              changeDropdownVisibilityFunc={changeDropdownVisibilityFunc}
              id={docId}
            />
          </li>
          <li
            className="docs-dropdown-item"
            onClick={() => handleSelectedRows(selectedRows)}
          >
            <PermanentlyDeleteAllCategory
              setCategoryDeletedToTrue={setCategoryDeletedToTrue}
              isEntireTableRowsSelected={isEntireTableRowsSelected}
              changeDropdownVisibilityFunc={changeDropdownVisibilityFunc}
              id={docId}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
