import baseUrl from "../config/baseUrl";

const rejectRequestedCase = async ({ token, docId, reason }) => {

  try {
    const response = await fetch(
      `${baseUrl}/api/reject-document?document_request_id=${docId}&document_status=rejected&reason_for_doc_rejection=${reason}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default rejectRequestedCase;
