import React, { useState, useContext, useEffect } from "react";
import { FaEye } from "react-icons/fa";
import { toast } from "react-toastify";
import { TiDeleteOutline } from "react-icons/ti";

import ButtonUI from "../../material-ui/ButtonUI";
import { AuthContext } from "../../../context/Auth/AuthContext";
import "../../../styles/LinkPopup.css";

/*** Component for viewing the document in pdf viewer in mailroom section***/
const DocumentViewer = ({ tableValues, changeDocumentViewerState }) => {
	return (
		<div className="single-link-popup-container">
			<ButtonUI
				formButton={false}
				variant="outlined"
				buttonText={<FaEye />}
				style={{
					backgroundColor: "#ffc107",
					color: " white",
					marginRight: "5px",
					padding: "8px",
					minWidth: "fit-content",
					border: "none",
				}}
				onClickFunc={() =>
					changeDocumentViewerState(
						tableValues.client_reference_number,
						tableValues.contact_id,
						tableValues.document_name,
						tableValues.category,
						tableValues.document_sub_category,
						tableValues.document_desc,
						tableValues.documentID,
						tableValues.document_type,
						tableValues.phone_number
					)
				}
			/>
		</div>
	);
};

export default DocumentViewer;
