import baseUrl from "../config/baseUrl";

const editFieldSettings = async ({
    token,
    user_id,
    field_setting_id,
    fields_name,
    fields_tab_type,
    fields_description,
    status,
    is_admin,
    category_id,
    ocr_data
}) => {
    let querystring = '';
    if (category_id && category_id !== 'None') {
        querystring += `&category_id=${category_id}`;
    }

    if (ocr_data) {
        querystring += `&document_identifier=${ocr_data}`;
    }

    try {
        const response = await fetch(`${baseUrl}/api/update-field-setting-table-data?field_setting_id=${field_setting_id}&fields_name=${fields_name}&fields_tab_type=${fields_tab_type}&fields_description=${fields_description}&status=${status}&is_admin=${is_admin}${querystring}`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            // body: formData,
        });
        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};

export default editFieldSettings;
