import React, { useState, useContext } from "react";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonUI from "../material-ui/ButtonUI";
import { AuthContext } from "../../context/Auth/AuthContext";
import deleteFieldSettings from "../../api/deleteFieldSettings";

const BulkDeletePopup = ({
  FieldSettingbulkid = [],
  bulkDeleteFieldSetting,
  setBulkDeleteFieldSetting,
  setRowSelection
}) => {
  const { token } = useContext(AuthContext);

  const handleClose = () => {
    setBulkDeleteFieldSetting(false);
  };

  const onBulkDelete = async (FieldSettingbulkid) => {
    const deleteAllFieldSettingFormResponse = await deleteFieldSettings({
      token: token,
      id: FieldSettingbulkid,
    });

    if (deleteAllFieldSettingFormResponse.code === 200) {
      setRowSelection({});
      setBulkDeleteFieldSetting(false);
      toast.success("Field Setting/s deleted successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Error while bulk deleting field setting/s ", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="dialog-modal">
      <Dialog
        open={bulkDeleteFieldSetting}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="edit-doc-modal">
          <div className="popup-header-svg-icon">
            <MdDelete />
          </div>
          <div className="popup-header-title">Delete FieldSetting</div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete these field setting/s?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonUI
            formButton={false}
            variant="outlined"
            buttonText="No"
            style={{ marginTop: "15px" }}
            onClickFunc={handleClose}
          />
          <ButtonUI
            formButton={false}
            variant="outlined"
            buttonText="Yes"
            style={{ marginTop: "15px" }}
            autoFocus={true}
            onClickFunc={() => {
              onBulkDelete(FieldSettingbulkid);
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BulkDeletePopup;
