import React, { useState, useContext } from "react";
import { FaTrashRestore } from "react-icons/fa";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonUI from "../../material-ui/ButtonUI";
import { AuthContext } from "../../../context/Auth/AuthContext";
import restoreDocument from "../../../api/restoreDocument";

const BulkRestorePopup = ({
  setDocumentRestoredToTrue,
  Documentbulkid = [],
  bulkRestoreDocument,
  setBulkRestoreDocument,
  setRowSelection
}) => {
  const { token } = useContext(AuthContext);

  const handleClose = () => {
    setBulkRestoreDocument(false);
  };

  const onBulkRestore = async (Documentbulkid) => {
    const restoreAllDocumentFormResponse = await restoreDocument({
      token: token,
      id: Documentbulkid,
    });

    if (restoreAllDocumentFormResponse.code === 200) {
      setRowSelection({});
      setBulkRestoreDocument(false);
      setDocumentRestoredToTrue();
      toast.success("Document/s restored successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Error while bulk restoring document/s", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="dialog-modal">
      <Dialog
        open={bulkRestoreDocument}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="edit-doc-modal">
          <div className="popup-header-svg-icon">
            <FaTrashRestore />
          </div>
          <div className="popup-header-title">Restore Document</div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to restore these documents ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonUI
            formButton={false}
            variant="outlined"
            buttonText="No"
            style={{ marginTop: "15px" }}
            onClickFunc={handleClose}
          />
          <ButtonUI
            formButton={false}
            variant="outlined"
            buttonText="Yes"
            style={{ marginTop: "15px" }}
            autoFocus={true}
            onClickFunc={() => {
              onBulkRestore(Documentbulkid);
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BulkRestorePopup;
