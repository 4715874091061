import React, { useState, useEffect, useContext } from "react";
import documentData from "../../api/documents";

import { ChevronDownIcon, MixerHorizontalIcon } from "@radix-ui/react-icons";

import TableSkeleton from "../../components/common/TableSkeleton";
import PaginationSection from "../../components/common/PaginationSection";

import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../../shadcn-components/ui/select";

import {
	ColumnDef,
	ColumnFiltersState,
	SortingState,
	VisibilityState,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";

import DataToolbar from "../common/DataToolbar";
import { Button } from "../../shadcn-components/ui/button";
import { Checkbox } from "../../shadcn-components/ui/checkbox";
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "../../shadcn-components/ui/dropdown-menu";
import { Input } from "../../shadcn-components/ui/input";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "../../shadcn-components/ui/table";

/*** Component for listing all the document requests for each client in the table ***/
const RequestsTable = ({
	pageCount,
	loading,
	requestsDocumentsResponse,
	paginationModel,
	handleFilterModelChange,
	setPaginationModel,
	table,
	globalFilter,
	columns,
	setCurrentPage,
	currentPage,
	totalPages,
	setPageSize,
}) => {
	const [showFilterButton, setShowFilterButton] = useState(false);

	return (
		<div class="request-table">
			<div className="documents-content">
				<div className="document__table mt-3">
					<div className="w-full bg-white px-2">
						<div className="flex justify-between items-center py-4 flex-wrap gap-4">
							<Input
								placeholder="Search By Client Name..."
								value={globalFilter}
								onChange={(event) => table.setGlobalFilter(event.target.value)}
								className="max-w-sm"
							/>
							<div className="flex gap-2">
								<Button
									variant="outline"
									className=""
									onClick={() => setShowFilterButton(!showFilterButton)}
								>
									<MixerHorizontalIcon className="mr-1" />
									Filter
								</Button>
								<DropdownMenu>
									<DropdownMenuTrigger asChild>
										<Button variant="outline" className="ml-auto">
											Columns <ChevronDownIcon className="ml-2 h-4 w-4" />
										</Button>
									</DropdownMenuTrigger>
									<DropdownMenuContent align="end">
										{table
											.getAllColumns()
											.filter((column) => column.getCanHide())
											.map((column) => {
												return (
													<DropdownMenuCheckboxItem
														key={column.id}
														className="capitalize"
														checked={column.getIsVisible()}
														onCheckedChange={(value) =>
															column.toggleVisibility(!!value)
														}
													>
														{column.columnDef.displayName}
													</DropdownMenuCheckboxItem>
												);
											})}
									</DropdownMenuContent>
								</DropdownMenu>
							</div>
						</div>
						{showFilterButton && (
							<DataToolbar
								table={table}
								apiUrl="/api/get-request-filter-name"
							/>
						)}
						<div className="rounded-md border">
							{loading ? (
								<TableSkeleton />
							) : (
								<Table>
									<TableHeader className="pb-4 sticky top-0 bg-white z-[1]">
										{table.getHeaderGroups().map((headerGroup) => (
											<TableRow key={headerGroup.id}>
												{headerGroup.headers.map((header) => {
													return (
														<TableHead key={header.id}>
															{header.isPlaceholder
																? null
																: flexRender(
																		header.column.columnDef.header,
																		header.getContext()
																  )}
														</TableHead>
													);
												})}
											</TableRow>
										))}
									</TableHeader>
									<TableBody>
										{table.getRowModel().rows?.length ? (
											table.getRowModel().rows.map((row) => (
												<TableRow
													key={row.id}
													data-state={row.getIsSelected() && "selected"}
												>
													{row.getVisibleCells().map((cell) => (
														<TableCell key={cell.id}>
															{flexRender(
																cell.column.columnDef.cell,
																cell.getContext()
															)}
														</TableCell>
													))}
												</TableRow>
											))
										) : (
											<TableRow>
												<TableCell
													colSpan={columns.length}
													className="h-24 text-center"
												>
													{loading ? <TableSkeleton /> : "no records found!"}
												</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							)}
						</div>
						<div className="flex items-center justify-between space-x-2 py-4 flex-wrap gap-4">
							<div className="flex items-center gap-5">
								<div className="flex items-center space-x-2">
									<p className="text-sm font-medium">Rows per page</p>
									<Select
										value={`${table.getState().pageSize}`}
										onValueChange={(value) => {
											setPageSize(Number(value));
											setCurrentPage(1);
										}}
									>
										<SelectTrigger className="w-auto">
											<SelectValue placeholder={table.getState().pageSize} />
										</SelectTrigger>
										<SelectContent>
											<SelectGroup>
												{[10, 20, 30, 40, 50].map((pageSize) => (
													<SelectItem key={pageSize} value={`${pageSize}`}>
														{pageSize}
													</SelectItem>
												))}
											</SelectGroup>
										</SelectContent>
									</Select>
								</div>
							</div>
							<div className="space-x-2">
								<PaginationSection
									setCurrentPage={setCurrentPage}
									totalPages={totalPages}
									currentPage={currentPage}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RequestsTable;
