import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import { RiCloseCircleFill } from "react-icons/ri";
import PropTypes from "prop-types";
import InputUI from "../../../material-ui/InputUI";
import ButtonUI from "../../../material-ui/ButtonUI";
import { AuthContext } from "../../../../context/Auth/AuthContext";
import addDocument from "../../../../api/addDocument";
import addAdditionalDocument from "../../../../api/addAdditionalDocument";
import { AiOutlineCloudUpload } from "react-icons/ai";
import "../../../../styles/AddDocument.css";
import baseUrl from "../../../../config/baseUrl";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <RiCloseCircleFill />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "application/pdf",
];

const addDocumentFormSchema = yup.object().shape({
  documentName: yup.string().required("Document Name is required field"),
  // documentDescription: yup
  // 	.string()
  // 	.required("Document Description is required field"),
  SubCategory: yup.string().required("Sub Category is required field"),
  addRequestName: yup.string(),
  fileUpload: yup
    .mixed()
    .test("required", "Please select a file", (value) => {
      return value && value.length > 0;
    })
    .test("type", "We only support images and pdf", (value) => {
      return value && SUPPORTED_FORMATS.includes(value[0]?.type);
    })
    .test(
      "fileSize",
      "Please upload file having size less than 5MB",
      (value) => {
        return value && value[0]?.size <= 5000000;
      }
    ),
});

/*** Component for listing the uploading the document form from client section ***/
const UploadDocumentsPopup = ({
  clientID,
  documentUpdatedToTrue,
  setDocumentUpdatedToTrue,
  subCategoryList,
}) => {
  const [loading, setLoading] = useState(false);
  const [requestOptions, setRequestOptions] = useState([]);
  const { token, user_id, is_admin } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [linkRequest, setLinkRequest] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(addDocumentFormSchema),
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    ignoreCase: true, // Ignore case sensitivity
    stringify: (option) => option.fields_name,
  });

  const filterOptionsLinkList = createFilterOptions({
    matchFrom: "any",
    ignoreCase: true, // Ignore case sensitivity
    stringify: (option) => option.document_req_name,
  });

  // Function to reset the form
  const handleResetForm = () => {
    // Clear errors first
    clearErrors();
    // Reset the form
    reset();
  };

  const onAddDocumentFormSubmit = async (addDocumentFormData) => {
    setLoading(true);

    if (
      addDocumentFormData.addRequestName &&
      addDocumentFormData.addRequestName != "Additional Document"
    ) {
      const addDocumentFormResponse = await addDocument({
        token: token,
        user_id: user_id,
        contact_id: clientID,
        document_name: addDocumentFormData.documentName,
        document_desc: addDocumentFormData.documentDescription,
        document_request_id: addDocumentFormData.addRequestName,
        category_id: "0c73a993-4dce-4430-b5a9-9d4ea57739e8",
        document_sub_category: addDocumentFormData.SubCategory,
        favourite: "0",
        //document_status: "active",
        document_url: addDocumentFormData.fileUpload[0],
      });

      if (addDocumentFormResponse?.code === 200) {
        setLoading(false);
        setOpen(false);
        setDocumentUpdatedToTrue((count) => count + 1);
        toast.success("Document is added successfully", {
          position: "top-center",
          autoClose: 3000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setLoading(false);
        setOpen(false);
        toast.error("Error while adding document", {
          position: "top-center",
          autoClose: 5000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      const addAdditionalDocumentFormResponse = await addAdditionalDocument({
        token: token,
        user_id: user_id,
        contact_id: clientID,
        document_name: addDocumentFormData.documentName,
        document_desc: addDocumentFormData.documentDescription,
        category_id: "0c73a993-4dce-4430-b5a9-9d4ea57739e8",
        document_sub_category: addDocumentFormData.SubCategory,
        //document_status: "active",
        content_type: "pdf",
        document_url: addDocumentFormData.fileUpload[0],
      });

      if (addAdditionalDocumentFormResponse?.code === 200) {
        setLoading(false);
        setOpen(false);
        setDocumentUpdatedToTrue((count) => count + 1);
        toast.success("Document is added successfully", {
          position: "top-center",
          autoClose: 3000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setLoading(false);
        setOpen(false);
        toast.error("Error while adding document", {
          position: "top-center",
          autoClose: 5000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const fetchRequestList = async () => {
    const url = `${baseUrl}/api/get-client-document-requests-for-linking?userId=1&isAdmin=1&contact_id=${clientID}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data?.code === 200) {
        // Create the additional item
        const additionalItem = {
          document_request_id: "Additional Document",
          document_req_name: "Additional Document",
        };

        // Add it as the first item to the data array
        const updatedData = [
          additionalItem,
          ...data?.data?.document_requests_for_linking,
        ];

        // Set the updated data to the state
        setRequestOptions(updatedData);
      } else {
        // Set only the additional document in the state
        setRequestOptions([
          {
            document_request_id: "Additional Document",
            document_req_name: "Additional Document",
          },
        ]);
      }
    } catch (error) {
      return error;
    }
  };

  const handleRequestListLink = () => {
    if (clientID && requestOptions?.length > 0) {
      setLinkRequest(!linkRequest);
    }
    if (linkRequest) {
      setValue("addRequestName", "");
    }
  };

  const handleClickOpen = () => {
    setLinkRequest(false);
    handleResetForm();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchRequestList();
  }, [documentUpdatedToTrue, clientID]);

  return (
    <div className="dialog-modal">
      <ButtonUI
        formButton={false}
        variant="contained"
        buttonText="Upload Documents"
        style={{
          width: "169px",
          height: "35px",
          color: "#fff",
          marginRight: "10px",
          backgroundColor: "#008000",
          boxShadow: "none",
          fontWeight: "200",
          borderRadius: "4px",
          letterSpacing: "0.07rem",
        }}
        onClickFunc={handleClickOpen}
      ></ButtonUI>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        className="custom-edit-modal"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          className="edit-doc-modal"
          onClose={handleClose}
        >
          <div className="popup-header-svg-icon">
            <AiOutlineCloudUpload />
          </div>
          <div className="popup-header-title">Upload Document</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
            <div>
              <InputUI
                fullWidth
                name="documentName"
                label="Document Name"
                type="text"
                variant="outlined"
                cssStyles={{ margin: "10px 0px" }}
                register={register}
              />
              <span className="add-doc-error-message">
                {errors["documentName"]?.message}
              </span>
            </div>
            <div>
              <InputUI
                fullWidth
                name="documentDescription"
                label="Document Description"
                type="text"
                variant="outlined"
                cssStyles={{ margin: "10px 0px" }}
                register={register}
              />
              <span className="add-doc-error-message">
                {errors["documentDescription"]?.message}
              </span>
            </div>
            <div>
              <InputUI
                fullWidth
                name="Category"
                label="Category"
                type="text"
                variant="outlined"
                disabled
                value="Manual Process"
                // cssStyles={{ margin: "10px 0px" }}
                register={register}
              />
            </div>
            <div>
              <FormControl className="" fullWidth>
                <Controller
                  control={control}
                  name="SubCategory"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      options={subCategoryList}
                      getOptionLabel={(option) => option.fields_name || ""}
                      filterOptions={filterOptions}
                      onChange={(e, value) => {
                        onChange(value ? value.field_setting_id : "");
                      }}
                      value={
                        subCategoryList?.find(
                          (option) => option.field_setting_id === value
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sub Category"
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
              {/* <FormControl fullWidth>
                <InputLabel id="category-select">Sub Category</InputLabel>
                <Controller
                  control={control}
                  name="SubCategory"
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      name="SubCategory"
                      labelId="category-select"
                      variant="outlined"
                      id="category-select"
                      label="Sub Category"
                      {...field}
                      {...register("SubCategory")}
                    >
                      {subCategory?.map((category) => (
                        <MenuItem
                          key={category.field_setting_id}
                          value={category.field_setting_id}
                        >
                          {category.fields_name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl> */}
              <span className="add-doc-error-message">
                {errors["SubCategory"]?.message}
              </span>
            </div>
            <div>
              <InputUI
                fullWidth
                name="fileUpload"
                type="file"
                variant="outlined"
                register={register}
              />
              <span className="add-doc-error-message">
                {errors["fileUpload"]?.message}
              </span>
            </div>
            <div>
              {!linkRequest && requestOptions?.length > 0 ? (
                <>
                  <span
                    className="request-list-link"
                    onClick={handleRequestListLink}
                  >
                    Link to Request List
                  </span>
                </>
              ) : null}

              {clientID ? (
                <>
                  {linkRequest ? (
                    <FormControl className="" fullWidth>
                      <Controller
                        control={control}
                        name="addRequestName"
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            options={requestOptions}
                            filterOptions={filterOptionsLinkList}
                            getOptionLabel={(option) =>
                              option.document_req_name || ""
                            }
                            onChange={(e, value) => {
                              onChange(value ? value.document_request_id : "");
                            }}
                            value={
                              requestOptions?.find(
                                (option) => option.document_request_id === value
                              ) || null
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Request List"
                                variant="outlined"
                              />
                            )}
                          />
                        )}
                      />
                    </FormControl>
                  ) : // <FormControl fullWidth>
                  //   <InputLabel
                  //     className="select-doc-select-label"
                  //     id="requestName"
                  //   >
                  //     Request List
                  //   </InputLabel>
                  //   <Controller
                  //     control={control}
                  //     name="addRequestName"
                  //     defaultValue=""
                  //     render={({ field }) => (
                  //       <Select
                  //         name="addRequestName"
                  //         labelId="requestName"
                  //         variant="outlined"
                  //         id="request-select"
                  //         label="Category"
                  //         {...field}
                  //         {...register("addRequestName")}
                  //       >
                  //         <MenuItem key="All" value="Select Request List">
                  //           Select Request List
                  //         </MenuItem>
                  //         {requestOptions.map((option) => (
                  //           <MenuItem
                  //             key={option.document_request_id}
                  //             value={option.document_request_id}
                  //           >
                  //             {option.document_req_name}
                  //           </MenuItem>
                  //         ))}
                  //       </Select>
                  //     )}
                  //   />
                  // </FormControl>
                  null}
                </>
              ) : null}
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: "15px !important" }}>
          <div className="popup_submit_btn">
            <ButtonUI
              loading={loading}
              variant="contained"
              buttonText="Upload Document"
              style={{
                width: "200px",
                height: "35px",
                backgroundColor: "#007bff",
                boxShadow: "none",
                border: "1px solid #007bff",
                fontWeight: "500",
                borderRadius: "4px",
                letterSpacing: "0.07rem",
                marginTop: "20px",
              }}
              handleSubmit={handleSubmit}
              onFormSubmit={onAddDocumentFormSubmit}
            />
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default UploadDocumentsPopup;
