const getAllSugarUsers = async ({ token }) => {
  try {
    const response = await fetch(
      `https://uat.consumerlaw.com/rest/v11_20/getdms-users-list`,
      {
        method: "GET",
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default getAllSugarUsers;