import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import documentRequestSettingsData from "../../api/documentRequestSettings";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Button } from "../../shadcn-components/ui/button";
import baseUrl from "../../config/baseUrl";

import DataTableFacetedFilter from "../common/DataTableFacetedFilter";

const DataToolbar = ({ table }) => {
	const { token, id, is_admin } = useContext(AuthContext);
	const [clientName, setClientName] = useState([]);
	const [contractName, setContractName] = useState([]);
	const [documentType, setDocumentType] = useState([]);
	const [clientType, setClientType] = useState([]);
	const [status, setStatus] = useState([]);

	const fetchOptions = async () => {
		const url = `${baseUrl}/api/get-contract-request-data?isAdmin=${is_admin}&userId=${id}`;
		const res = await documentRequestSettingsData({ token, api: url });
		const clientNameOptions = [
			...new Set(res.data[0].contractRequests.map((contract) => contract.name)),
		].map((name) => ({ value: name, label: name }));

		const contractNameOptions = [
			...new Set(
				res.data[0].contractRequests.map((contract) => contract.contract_name)
			),
		].map((name) => ({ value: name, label: name }));

		const clientTypeOptions = [
			...new Set(
				res.data[0].contractRequests.map((contract) => contract.client_type)
			),
		].map((name) => ({ value: name, label: name }));

		const documentTypeOptions = [
			...new Set(
				res.data[0].contractRequests.map((contract) => contract.document_type)
			),
		].map((name) => ({ value: name, label: name }));

		const statusOptions = [
			...new Set(
				res.data[0].contractRequests.map((contract) => contract.contract_status)
			),
		].map((name) => ({ value: name, label: name }));

		setClientName(clientNameOptions);
		setContractName(contractNameOptions);
		setDocumentType(documentTypeOptions);
		setClientType(clientTypeOptions);
		setStatus(statusOptions);
	};

	useEffect(() => {
		fetchOptions();
	}, []);

	const isFiltered = table.getState().columnFilters.length > 0;

	const handleResetFilter = () => {
		table.resetColumnFilters();
	};
	return (
		<div className="my-2 flex gap-2 items-center flex-wrap">
			<p className="text-sm text-muted-foreground uppercase">Filter by</p>
			<DataTableFacetedFilter
				column={table.getColumn("name")}
				title="Client Name"
				options={clientName}
				table={table}
			/>
			<DataTableFacetedFilter
				column={table.getColumn("contract_name")}
				title="Contract Name"
				options={contractName}
				table={table}
			/>
			<DataTableFacetedFilter
				column={table.getColumn("client_type")}
				title="Client Type"
				options={clientType}
				table={table}
			/>
			<DataTableFacetedFilter
				column={table.getColumn("document_type")}
				title="Document Type"
				options={documentType}
				table={table}
			/>
			<DataTableFacetedFilter
				column={table.getColumn("contract_status")}
				title="Status"
				options={status}
				table={table}
			/>

			{isFiltered && (
				<Button
					variant="ghost"
					onClick={handleResetFilter}
					className="h-8 px-2 lg:px-3"
				>
					Reset
					<Cross2Icon className="ml-2 h-4 w-4" />
				</Button>
			)}
		</div>
	);
};

export default DataToolbar;
