import baseUrl from "../config/baseUrl";

const viewDocumentLink = async ({ token, docId }) => {
  try {
    const response = await fetch(
      `${baseUrl}/api/view-document-req-url-link?document_id=${docId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default viewDocumentLink;
