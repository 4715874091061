import baseUrl from "../config/baseUrl";

const changeDocumentRequestSettingsStatus = async ({ token, user_id, document_request_setting_id, status }) => {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("document_request_setting_id", document_request_setting_id);
    formData.append("status", status);

    try {
        const response = await fetch(`${baseUrl}/api/update-document-request-setting-data?document_request_setting_id=${document_request_setting_id}&status=${status}`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        });
        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};

export default changeDocumentRequestSettingsStatus;
