import { useState, useEffect, useCallback, useRef } from "react";
import { useDebounce } from "use-debounce";

const useDebounceSearch = (initialValue, documentId, offset, contactId, delay, callbackFn) => {
    const [searchTerm, setSearchTerm] = useState(initialValue);
    const [debouncedSearchTerm] = useDebounce(searchTerm, delay);

    // Track the previous values of debouncedSearchTerm and offset
    const previousSearchTerm = useRef(debouncedSearchTerm);
    const previousOffset = useRef(offset);

    // Handle search term changes
    const handleClientNameSearch = useCallback((newSearchTerm) => {
        setSearchTerm(newSearchTerm);
    }, []);

    // Reset the search term when the documentId changes
    useEffect(() => {
        setSearchTerm("");
    }, [documentId]);

    // Call the callback function only when debouncedSearchTerm or offset changes genuinely
    useEffect(() => {

        // Check if either searchTerm or offset has genuinely changed
        if (
             ((offset !== previousOffset.current) && offset !== 0) || (debouncedSearchTerm !== previousSearchTerm.current)
        ) {

            callbackFn(debouncedSearchTerm, offset, contactId);

            // Update previous values after callback
            previousSearchTerm.current = debouncedSearchTerm;
            previousOffset.current = offset;
        }
    }, [debouncedSearchTerm, callbackFn, offset]);

    return [searchTerm, handleClientNameSearch];
};

export default useDebounceSearch;
