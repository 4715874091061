import React, { useState, useContext } from "react";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonUI from "../material-ui/ButtonUI";
import { AuthContext } from "../../context/Auth/AuthContext";
import deleteDocument from "../../api/deleteDocument";

const BulkDeletePopup = ({
	setDocumentDeletedToTrue,
	Docbulkid = [],
	bulkDeleteDocument,
	setBulkDeleteDocument,
	setRowSelection,
	bulkDeleteDocumentCounts,
	documentSourceTypes,
	createdAt,
	documentSourceDetails,
	section,
}) => {
	const { token } = useContext(AuthContext);

	const handleClose = () => {
		setBulkDeleteDocument(false);
	};

	const onBulkDelete = async (Docbulkid) => {
		const deleteAllDocumentFormResponse = await deleteDocument({
			token: token,
			id: Docbulkid,
			counts: bulkDeleteDocumentCounts,
			documentSourceTypes: documentSourceTypes,
			createdAt: createdAt,
			documentSourceDetails: documentSourceDetails,
			section: section,
		});

		if (deleteAllDocumentFormResponse.code === 200) {
			setBulkDeleteDocument(false);
			setDocumentDeletedToTrue(true);
			setRowSelection({});
			toast.success("Document/s deleted successfully", {
				position: "top-center",
				autoClose: 3000,
				theme: "dark",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		} else {
			toast.error("Error while bulk deleting document/s", {
				position: "top-center",
				autoClose: 5000,
				theme: "dark",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	};

	return (
		<div className="dialog-modal">
			<Dialog
				open={bulkDeleteDocument}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" className="edit-doc-modal">
					<div className="popup-header-svg-icon">
						<MdDelete />
					</div>
					<div className="popup-header-title">Delete Document</div>
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to delete these documents ?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<ButtonUI
						formButton={false}
						variant="outlined"
						buttonText="No"
						style={{ marginTop: "15px" }}
						onClickFunc={handleClose}
					/>
					<ButtonUI
						formButton={false}
						variant="outlined"
						buttonText="Yes"
						style={{ marginTop: "15px" }}
						autoFocus={true}
						onClickFunc={() => {
							onBulkDelete(Docbulkid);
						}}
					/>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default BulkDeletePopup;
