import React, { useContext, useEffect, useState } from "react";
import { ChevronUp, ChevronDown } from "lucide-react"; // Import Lucide chevron icons
import { useDrag } from "react-dnd";
import performOcr from "../../../api/performOcr";
import getOcrKeys from "../../../api/getOcrKeys";
import baseUrl from "../../../config/baseUrl";
import Loader from "./Loader";
import { AuthContext } from "../../../context/Auth/AuthContext";
import getOcrData from "../../../api/getOcrData";

// Chat Popup Component
const ChatPopup = ({
  document_url,
  subCategory,
  documentID,
  ocrStatus,
  setPerformOcr,
  setLoadingOcr,
  setExistingOcr,
  setValue,
}) => {
  const [ocrData, setOcrData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { token } = useContext(AuthContext);

  const getOcrDetails = async () => {
    const url = `${baseUrl}/api/get-ocr-data?document_id=${documentID}`;
    try {
      const res = await getOcrData({ api: url, token });
      if (res && res.output && res.output.status === "success") {
        //setOcrData(res.output.answers);
        setExistingOcr(true);
        return res.output.answers;
      } else {
        setExistingOcr(false);
      }
      return null;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const fetchOcrKeys = async () => {
    const url = `${baseUrl}/api/get-subtype-ocr-keys?document_sub_category=${subCategory}`;
    try {
      const { data } = await getOcrKeys({ api: url, token });
      return data[0]?.document_identifier || null;
    } catch (error) {
      console.error("Error fetching OCR keys:", error);
      return null;
    }
  };

  const startOcr = async (file, keys) => {
    setExpanded(ocrStatus);
    setLoading(true);
    setLoadingOcr(true);
    const token = "29649f8f-4055-4b7e-b5db-c3e57d875d66";
    const url = `https://filescour.consumerlaw.com/ocr`;
    try {
      const res = await performOcr({ api: url, token, ocrKeys: keys, file });
      //console.log("OCR Response:", res);
      setLoadingOcr(false);
      setOcrData(res.output.answers);
    } catch (err) {
      console.log("Error during OCR:", err);
    } finally {
      setLoading(false);
      setPerformOcr(false);
      setLoadingOcr(false);
    }
  };

  const fetchData = async () => {
    if (ocrStatus) {
      const keys = await fetchOcrKeys();
      if (!keys) {
        console.error("No OCR keys found.");
        setPerformOcr(false);
        return;
      }

      const parsedKeys = JSON.parse(keys);
      if (parsedKeys && document_url) {
        startOcr(document_url, parsedKeys);
      }
    } else {
      const existingOcrData = await getOcrDetails();
      if (existingOcrData) {
        // Set each OCR field to its respective value in form using setValue
        Object.entries(existingOcrData).forEach(([key, value]) => {
          setValue(`ocr_${key}`, value);
        });
        setExistingOcr(true);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [ocrStatus]);

  const answers = ocrData || {};

  return (
    <div className="fixed bottom-0 right-[25%] shadow-lg bg-white rounded-lg flex flex-col min-w-[300px]">
      <button
        onClick={() => setExpanded(!expanded)}
        className="flex justify-between items-center p-2 px-4 inset-2 w-full"
      >
        OCR Data
        {expanded ? <ChevronDown /> : <ChevronUp />}
      </button>

      {expanded && (
        <div className="flex flex-col items-center justify-center  bg-[#f0f9fc] overflow-y-auto min-h-[300px]">
          {loading ? (
            <Loader />
          ) : (
            <div className="rounded-md p-1 grid grid-cols-2 gap-3 justify-start items-start  w-full ">
              {Object.entries(answers).map(([key, value], index) => (
                <DraggableAnswer
                  key={index}
                  value={value}
                  fieldName={`ocr_${key}`}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const DraggableAnswer = ({ value, fieldName }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "DATA",
    item: { fieldName, data: value },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      className={`p-2 my-1 rounded-md bg-white shadow-md ${
        isDragging ? "opacity-50" : "opacity-100"
      }`}
      style={{ cursor: "move" }}
    >
      {value}
    </div>
  );
};

export default ChatPopup;
