import React, { useState, useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { FaRegUserCircle } from "react-icons/fa";
import { RiSettings5Line } from "react-icons/ri";
import { MdOutlineLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logoutUser from "../../api/logout";
import { AuthContext } from "../../context/Auth/AuthContext";
import "../../styles/Dropdown.css";

const Dropdown = () => {
  const [itemClicked, setItemClicked] = useState(false);
  const { instance } = useMsal();

  const { token, dispatch, is_admin, firstName, lastName } =
    useContext(AuthContext);

  const redirect = useNavigate();

  const logoutFn = async () => {
    const data = await logoutUser({ token });
  };

  const handleLogout = async () => {
    logoutFn();
    setItemClicked(!itemClicked);
    dispatch({ type: "LOGOUT" });
    redirect("/login");
    toast.success("Successfully Logged Out", {
      position: "top-center",
      autoClose: 3000,
      theme: "dark",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    // instance
    //   .logoutPopup()
    //   .then(() => {
    //     logoutFn();
    //     setItemClicked(!itemClicked);
    //     dispatch({ type: "LOGOUT" });
    //     redirect("/login");
    //     toast.success("Successfully Logged Out", {
    //       position: "top-center",
    //       autoClose: 3000,
    //       theme: "dark",
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   })
    //   .catch((e) => {
    //     console.error(e);
    //   });
  };

  return (
    <div className={!itemClicked ? "dropdown-content" : "clicked"}>
      <ul className="navbar-dropdown">
        {/* <li>
          <div className="dropdown-links">
            <FaRegUserCircle />
            {`${firstName} ${lastName}`}
          </div>
        </li>
        <li>
          <div className="dropdown-links">
            <RiSettings5Line />
            {is_admin === "1" ? "Admin" : "Non Admin"}
          </div>
        </li> */}
        <li>
          <div
            onClick={handleLogout}
            className="dropdown-links dropdown-links-logout"
          >
            <MdOutlineLogout />
            Logout
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Dropdown;
