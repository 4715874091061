const getChatLogs = async ({ clientID }) => {
	try {
		const response = await fetch(
			`https://uat.consumerlaw.com/rest/v11_20/get-message-chat-log`,
			{
				method: "POST",
				body: JSON.stringify({
					id: clientID,
				}),
			}
		);

		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}

		const data = await response.json();
		return data;
	} catch (error) {
		console.error("Error fetching chat logs:", error);
		return error;
	}
};

export default getChatLogs;
