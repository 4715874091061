import baseUrl from "../config/baseUrl";

const dashboardDocumentCount = async ({ token }) => {
  try {
    const response = await fetch(
      `${baseUrl}/api/get-document-count-dashboard`,
      {
        method: "GET",

        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default dashboardDocumentCount;