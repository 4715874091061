import baseUrl from "../config/baseUrl";

const deleteDocument = async ({ token, id, counts, documentSourceTypes, createdAt, documentSourceDetails, section }) => {
  let query_string = '';
  if (id !== undefined) {
    query_string += `document_ids=${id}&`;
  }
  if (documentSourceTypes !== undefined) {
    query_string += `document_source_type=${documentSourceTypes}&`;
  }
  if (createdAt !== undefined) {
    query_string += `created_at=${createdAt}&`;
  }
  if (documentSourceDetails !== undefined) {
    query_string += `document_source_details=${documentSourceDetails}&`;
  }
  if (section !== undefined) {
    query_string += `section=${section}&`;
  }

  query_string = query_string.endsWith('&') ? query_string.slice(0, -1) : query_string;

  try {
    const response = await fetch(
      `${baseUrl}/api/delete-document-table-data?${query_string}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default deleteDocument;
