import baseUrl from "../config/baseUrl";

const deleteRequestedClient = async ({ token, docId }) => {
    try {
        const response = await fetch(
            `${baseUrl}/api/delete-document-request-table-data?document_request_id=${docId}`,
            {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};

export default deleteRequestedClient;
