import baseUrl from "../config/baseUrl";

const addAdditionalContact = async ({ token, formData }) => {
  console.log(JSON.stringify(formData));
  try {
    const response = await fetch(`${baseUrl}/api/add-client-link-data`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default addAdditionalContact;
