const getServiceList = async ({ token, api }) => {
	try {
		const response = await fetch(api, {
			method: "GET",
			headers: {
				Accept: "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
		const data = await response.json();

		return data;
	} catch (error) {
		return error;
	}
};

export default getServiceList;
