import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/Auth/AuthContext";
import { useDebounce } from "use-debounce";

export const useFetchFilters = (apiUrl) => {
  const { token } = useContext(AuthContext);
  const [data, setData] = useState({});
  const [params, setParams] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [debouncedParams] = useDebounce(params, 1000);


  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const query = new URLSearchParams(debouncedParams).toString();
      const response = await fetch(`${apiUrl}&${query}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      const columnId = Object.keys(debouncedParams)[0];
      setData((prevData) => ({
        ...prevData,
        [columnId]: result.data,
      }));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(debouncedParams).length > 0) {
      fetchData();
    }
  }, [debouncedParams]);

  return [data, setParams, loading, error];
};