import React, { useState, useEffect, useContext } from "react";
import SmsTemplate from "../../../src/images/sms-template.png";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Typography,
	Autocomplete,
	Tab,
	Tabs,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import getSmsTemplates from "../../api/getSmsTemplates";
import getDescription from "../../api/getTemplateDescription";
import getOptinInfo from "../../api/getOptinInfo";
import sendSms from "../../api/sendSms";
import getChatLogs from "../../api/getChatLogs";
import { X } from "lucide-react";
import { AuthContext } from "../../context/Auth/AuthContext";
import { toast } from "react-toastify";

const schema = yup.object().shape({
	channelType: yup.string().required("This field is required"),
	to: yup.string().required("This field is required"),
	smsTemplate: yup.object().required("Template is required").nullable(),
	message: yup.string().required("Message is required").nullable(),
});

const SendSMS = ({ clientPhone, clientID }) => {
	const [open, setOpen] = useState(false);
	const [templates, setTemplates] = useState([]);
	const [optIn, setOptIn] = useState(true);
	const [loading, setLoading] = useState(false);
	const [tabValue, setTabValue] = useState(0);
	const [chatLogs, setChatLogs] = useState([]);

	const { firstName } = useContext(AuthContext);

	const channel_type = [
		{
			id: "text",
			label: "Text SMS",
		},
	];

	const {
		handleSubmit,
		control,
		reset,
		setValue,
		getValues,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			channelType: "",
			to: clientPhone || "",
			smsTemplate: null,
			message: "",
		},
	});

	const handleOpen = async () => {
		setOpen(true);
		setLoading(true);
		setValue("channelType", channel_type[0].label);
		await checkUserOptInStatus(clientID);
		getChatHistory();
		setLoading(false);
	};

	const handleClose = () => {
		setOpen(false);
		reset();
	};

	const onSubmit = async (data) => {
		const selectedTemplate = data.smsTemplate;

		const payload = {
			id: clientID,
			message: data.message,
			messageTemplate: selectedTemplate ? selectedTemplate.name : "",
			toNumber: clientPhone,
			channel: data.channelType,
			media: false,
			checkCondition: "",
			templateId: selectedTemplate
				? `${selectedTemplate.id}__${selectedTemplate.name}`
				: "",
		};

		try {
			setLoading(true);
			const response = await sendSms(payload);
			if (response.status === 200) {
				toast.success("SMS sent successfully", {
					position: "top-center",
					autoClose: 3000,
					theme: "dark",
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			} else {
				toast.error("Error while sending sms", {
					position: "top-center",
					autoClose: 5000,
					theme: "dark",
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			}

			handleClose();
		} catch (err) {
			console.error("Failed to send SMS:", err);
		} finally {
			setLoading(false);
		}
	};

	const fetchSmsTemplateList = async () => {
		try {
			const res = await getSmsTemplates();
			setTemplates(res);
		} catch (err) {
			console.log(err);
		}
	};

	const fetchTemplateDescription = async (cm_name, template_id, client_id) => {
		try {
			const res = await getDescription({
				cm_name,
				template_id,
				client_id,
			});
			setValue("message", res.templateDescription);
		} catch (err) {
			console.log(err);
		}
	};

	const handleTemplateChange = async (selectedTemplate) => {
		console.log(selectedTemplate);
		if (selectedTemplate) {
			const template_id = `${selectedTemplate.id}__${selectedTemplate.name}`;
			fetchTemplateDescription("Case", template_id, clientID);
		}
	};

	const checkUserOptInStatus = async (id) => {
		try {
			const res = await getOptinInfo({ id });
			if (!res.opted_in) {
				setOptIn(false);
			} else {
				setOptIn(true);
			}
		} catch (err) {
			console.log(err);
			setOptIn(false);
		}
	};

	const getChatHistory = async () => {
		try {
			const res = await getChatLogs({ clientID });
			setChatLogs(res.chatLog);
		} catch (error) {
			console.log(error);
		}
	};

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue); // Update the selected tab
	};

	useEffect(() => {
		fetchSmsTemplateList();
	}, []);

	return (
		<div>
			<Button
				variant="contained"
				onClick={handleOpen}
				className="h-full"
				sx={{ backgroundColor: "#3792ed", textTransform: "capitalize" }}
			>
				Send Sms
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				className="max-h-[auto]"
				maxWidth="sm"
				fullWidth={true}
				PaperProps={{
					style: {
						maxHeight: "none",
						borderRadius: "8px",
					},
				}}
			>
				<div className="flex gap-3 items-center px-2 py-1 relative">
					<span className="w-32">
						<img
							src={SmsTemplate}
							alt="envelope"
							className="w-full h-full object-contain"
						/>
					</span>
					<DialogTitle className="text-[#007bff] !text-2xl font-medium p-0 ">
						Send SMS
					</DialogTitle>
					<X
						size={24}
						onClick={() => setOpen(false)}
						className="bg-[#007bff] rounded-full p-1 text-white cursor-pointer absolute right-4 top-3"
					/>
				</div>

				<Tabs
					value={tabValue}
					onChange={handleTabChange}
					sx={{ marginLeft: "20px" }}
				>
					<Tab label="Send SMS" />
					<Tab label="SMS Logs" />
				</Tabs>

				<DialogContent className="h-auto">
					{tabValue === 0 && (
						<>
							{!optIn && (
								<Typography color="error" variant="body1" gutterBottom>
									Note: SMS cannot be sent as the client has selected the STOP
									receiving SMS feature.
								</Typography>
							)}
							<form onSubmit={handleSubmit(onSubmit)}>
								<FormControl fullWidth margin="normal">
									<InputLabel>Channel Type</InputLabel>
									<Controller
										name="channelType"
										control={control}
										render={({ field }) => (
											<Select {...field} label="Channel Type" disabled={!optIn}>
												{channel_type.map((channel) => (
													<MenuItem value={channel.label} key={channel.id}>
														{channel.label}
													</MenuItem>
												))}
											</Select>
										)}
									/>
								</FormControl>
								<FormControl fullWidth margin="normal">
									<InputLabel>To</InputLabel>
									<Controller
										name="to"
										control={control}
										render={({ field }) => (
											<Select {...field} label="To" disabled>
												<MenuItem value={clientPhone}>{clientPhone}</MenuItem>
											</Select>
										)}
									/>
								</FormControl>
								<FormControl fullWidth margin="normal">
									<Controller
										name="smsTemplate"
										control={control}
										render={({ field }) => (
											<>
												<Autocomplete
													{...field}
													options={templates}
													getOptionLabel={(option) => option.name || ""}
													onChange={(e, value) => {
														field.onChange(value);
														handleTemplateChange(value);
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															label="Template"
															variant="outlined"
															error={!!errors.smsTemplate}
															helperText={errors.smsTemplate?.message}
														/>
													)}
													disabled={!optIn}
												/>
											</>
										)}
									/>
								</FormControl>
								<Controller
									name="message"
									control={control}
									render={({ field }) => (
										<TextField
											{...field}
											label="Message"
											fullWidth
											multiline
											rows={4}
											margin="normal"
											variant="outlined"
											disabled={!optIn}
											error={!!errors.message}
											helperText={errors.message?.message}
										/>
									)}
								/>
								<DialogActions>
									<Button
										type="submit"
										color="primary"
										variant="contained"
										disabled={!optIn || loading}
									>
										Send
									</Button>
								</DialogActions>
							</form>
						</>
					)}

					{tabValue === 1 && (
						<div className="h-[400px] bg-gray-300/25 px-4 py-5 overflow-y-auto">
							<div className="flex flex-col gap-3">
								{chatLogs?.length > 0 ? (
									chatLogs?.map((chat) => (
										<div
											key={chat?.timestamp}
											className={`flex flex-col bg-blue-400 text-white p-2 text-sm rounded-3xl ${
												chat?.direction === "out"
													? "justify-end rounded-tr-none"
													: "justify-start rounded-tl-none"
											}`}
										>
											<div className="flex gap-2">
												<span className="badge bg-slate-900 rounded-full italic size-5 flex justify-center items-center mb-1">
													{chat?.channel_type_c?.[0]?.toUpperCase() || "?"}{" "}
												</span>
												<span className="text-slate-800 italic font-medium">
													{firstName || "Guest"}
												</span>
											</div>
											<span>{chat?.message || "No message available"}</span>{" "}
											<span className="italic mt-2 flex justify-end">
												{chat?.timestamp
													? new Date(Number(chat.timestamp)).toLocaleString()
													: "No timestamp"}{" "}
											</span>
										</div>
									))
								) : (
									<span className="text-gray-500 text-lg">No messages yet</span>
								)}
							</div>
						</div>
					)}
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default SendSMS;
