import baseUrl from "../config/baseUrl";

const editClient = async ({
  token,
  contact_id,
  name,
  email_id,
  phone_number,
}) => {
  try {
    const response = await fetch(
      `${baseUrl}/api/update-client-link-table-data?contact_id=${contact_id}&name=${name}&phone_number=${phone_number}&email_id=${email_id}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default editClient;
