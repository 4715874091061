import baseUrl from "../config/baseUrl";

const editContractTemplates = async ({
    token,
    user_id,
    contract_request_setting_id,
    contract_name,
    contract_description,
    client_type,
    document_type,
    ocr_status,
    status,
    is_admin,
}) => {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("contract_request_setting_id", contract_request_setting_id);
    formData.append("contract_name", contract_name);
    formData.append("contract_description", contract_description);
    formData.append("client_type", client_type);
    formData.append("document_type", document_type);
    formData.append("ocr_status", ocr_status);
    formData.append("status", status);
    formData.append("is_admin", is_admin);

    try {
        const response = await fetch(`${baseUrl}/api/update-contract-request-setting-data?contract_request_setting_id=${contract_request_setting_id}&document_type=${document_type}&client_type=${client_type}&contract_description=${contract_description}&contract_name=${contract_name}&status=${status}&ocr_status=${ocr_status}&is_admin=${is_admin}`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        });
        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};

export default editContractTemplates;
