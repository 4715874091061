import baseUrl from "../config/baseUrl";

const getClientPhone = async ({ token, client_id }) => {
	try {
		//https://admindms.consumerlaw.com/public/api/get-client-phone-number?contact_id=5a40c2f0-70eb-11ef-9f5e-2e6dd8c53902
		const response = await fetch(
			`${baseUrl}/api/get-client-phone-number?contact_id=${client_id}`,
			{
				method: "GET",
				headers: {
					Accept: "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		const data = await response.json();
		return data;
	} catch (error) {
		return error;
	}
};

export default getClientPhone;
