// OcrFields.jsx
import React, { useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { useDrop } from "react-dnd";
import getOcrKeys from "../../../api/getOcrKeys";
import { AuthContext } from "../../../context/Auth/AuthContext";
import baseUrl from "../../../config/baseUrl";

const OcrFields = ({ control, errors, setValue, subCategory, documentID }) => {
	const { token } = useContext(AuthContext);
	const [ocrKeys, setOcrKeys] = useState({});

	// Set up the drop area
	const [{ isOver, canDrop }, drop] = useDrop({
		accept: "DATA",
		drop: (item) => {
			console.log("Dropped item:", item);
			// Set the form field based on the dropped item's fieldName
			setValue(item.fieldName, item.data);
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
	});

	// Fetch OCR keys from the API
	const fetchOcrKeys = async () => {
		const url = `${baseUrl}/api/get-subtype-ocr-keys?document_sub_category=${subCategory}`;

		try {
			const { data } = await getOcrKeys({ api: url, token });
			if (data && data[0] && data[0].document_identifier) {
				try {
					const parsedOcrKeys = JSON.parse(data[0].document_identifier);
					setOcrKeys(parsedOcrKeys);
				} catch (parseError) {
					console.error("Error parsing document_identifier:", parseError);
				}
			} else {
				console.error("Invalid OCR keys data structure:", data);
			}
		} catch (err) {
			console.error("Error fetching OCR keys:", err);
		}
	};

	useEffect(() => {
		if (subCategory) {
			fetchOcrKeys();
		}
	}, [subCategory]);

	console.log(ocrKeys);

	const capitalizeText = (text) => {
		const words = text.split("_");

		const capitalizedText = words
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
			.join(" ");

		return capitalizedText;
	};

	return (
		<div className="mb-4">
			<fieldset
				ref={drop}
				className="border border-2 border-gray-600 rounded-md p-[15px]"
			>
				<legend className="w-max text-sm uppercase text-blue-500 font-medium">
					Ocr Fields
				</legend>
				{/* Dynamically render TextFields based on parsedFieldKeys */}
				{Object.entries(ocrKeys).map(([fieldName, fieldLabel]) => (
					<Controller
						key={fieldName}
						name={`ocr_${fieldName}`} // Prefix OCR fields with "ocr_" to distinguish
						control={control}
						defaultValue="" // Ensure default value is set
						render={({ field: { onChange, onBlur, value, name } }) => (
							<TextField
								onChange={onChange}
								onBlur={onBlur}
								value={value || ""}
								name={name}
								label={capitalizeText(fieldName)}
								variant="outlined"
								fullWidth
								error={!!errors[fieldName]}
								helperText={errors[fieldName] ? errors[fieldName].message : ""}
								sx={{ marginBottom: "15px" }}
								className={`mb-[15px] ${isOver ? "bg-[#e0ffe0]" : "bg-white"} ${
									canDrop ? "border border-green-500" : ""
								}`}
							/>
						)}
					/>
				))}
			</fieldset>
		</div>
	);
};

export default OcrFields;
