import React from "react";
import LoaderGif from "../../../images/ocr-loader.gif";

const Loader = () => {
	return (
		<div className="flex justify-center items-center">
			<img src={LoaderGif} alt="loader" />
		</div>
	);
};

export default Loader;
