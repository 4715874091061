import { Skeleton } from "../../shadcn-components/ui/skeleton";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../shadcn-components/ui/card";
import { Link } from "react-router-dom";
import { MdOutlineDocumentScanner } from "react-icons/md";
import { BiMessageSquareDetail } from "react-icons/bi";
import { HiOutlineClipboardList, HiOutlineMail } from "react-icons/hi";
import { TbChecklist } from "react-icons/tb";
import { useEffect, useState } from "react";
import dashboardDocumentCount from "../../api/dashboardDocumentCount";

function DocCountCard({ token, customBoxShadow }) {
  const [countLoading, setCountLoading] = useState(true);
  const [documentCount, setDocumentCount] = useState({});
  const documentCountData = async () => {
    const response = await dashboardDocumentCount({ token });

    if (response.code === 200) {
      setDocumentCount(response);
    }
  };
  const docCards = [
    {
      linkTo: "/assigned-case-list",
      cardTitle: "All Assigned",
      icon: <TbChecklist />,
      docCount: documentCount.getAssigned,
    },
    {
      linkTo: "/unassigned-case-list",
      cardTitle: "All Unassigned",
      icon: <HiOutlineClipboardList />,
      docCount: documentCount.allUnassigned,
    },
    {
      linkTo: "/my-assigned-case-list",
      cardTitle: "My Assigned",
      icon: <TbChecklist />,
      docCount: documentCount.myAssigned,
    },
    {
      linkTo: "/email-case-list",
      cardTitle: "Incoming Emails",
      icon: <HiOutlineMail />,
      docCount: documentCount.email_count,
    },
    {
      linkTo: "/fax-case-list",
      cardTitle: "Incoming Faxes",
      icon: <MdOutlineDocumentScanner />,
      docCount: documentCount.fax_count,
    },
    {
      linkTo: "/scan-case-list",
      cardTitle: "Scanned Files",
      icon: <BiMessageSquareDetail />,
      docCount: documentCount.scanned_count,
    },
  ];

  useEffect(() => {
    async function fetchData() {
      await documentCountData();
      setCountLoading(false);
    }
    fetchData();
  }, []);
  return (
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 w-full">
      {docCards.map(({ linkTo, cardTitle, icon, docCount }) => (
        <Link to={linkTo} key={linkTo}>
          <Card className="w-full hover:bg-[#e2efff]" style={customBoxShadow}>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium letterSpacing">
                {cardTitle}
              </CardTitle>
              {icon}
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-semibold">
                {countLoading ? <Skeleton className="h-8 w-1/2" /> : docCount}
              </div>
            </CardContent>
          </Card>
        </Link>
      ))}
    </div>
  );
}

export default DocCountCard;
