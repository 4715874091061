import baseUrl from "../config/baseUrl";

const restoreDocument = async ({ token, id }) => {
  try {
    const response = await fetch(`${baseUrl}/api/restore-deleted-document?document_id=${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default restoreDocument;
