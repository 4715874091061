import React, { useState, useRef, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Navbar from "../components/common/Navbar";
import ClientsTable from "../components/client-table/ClientsTable";
import ClientDocuments from "../components/client-table/ClientDocuments";
import { AuthContext } from "../context/Auth/AuthContext";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import "../styles/Clients.css";
import "../styles/Documents.css";

/*** Main component for listing the client table, client documents table & for showing the 'Add Clients' button ***/
const Clients = () => {
	const location = useLocation();
	const pathname = location.pathname;
	const showUploads = pathname.endsWith("/uploads") ? "/uploads" : null;

	let { cid } = useParams();
	let clientId = cid;

	const [tab, setTab] = useState([
		{
			id: uuidv4(),
			clientID: null,
			title: "All Clients",
			content: (
				addClientTab,
				showAddClientForm,
				onAddClientButtonClick,
				showClientIdSearch
			) => (
				<ClientsTable
					addClientTab={addClientTab}
					showAddClientForm={showAddClientForm}
					onAddClientButtonClick={onAddClientButtonClick}
					clientId={clientId}
					showClientIdSearch={showClientIdSearch}
				/>
			),
			canClose: false,
		},
	]);

	const { token, id, is_admin } = useContext(AuthContext);
	const [tabValue, setTabValue] = useState(tab[0].id);
	const firstTabId = tab[0].id;

	const [showAddClientForm, setShowAddClientForm] = useState(false);
	const [showClientIdSearch, setShowClientIdSearch] = useState(false);
	const scrollRef = useRef(null);

	const truncate = (string, n) => {
		return string?.length > n ? string.substr(0, n - 1) + "..." : string;
	};

	const scrollLeft = (scrollOffset) => {
		scrollRef.current.scrollLeft += scrollOffset;
	};

	const scrollRight = (scrollOffset) => {
		scrollRef.current.scrollLeft -= scrollOffset;
	};

	const changeActiveTab = () => {
		setTabValue(tab[0].id);
	};

	const onAddClientButtonClick = () => {
		setShowAddClientForm(!showAddClientForm);
	};

	const addClientTab = (client) => {
		const isClientAlreadySelected = tab.find(
			(tab) => tab.clientID === client.contact_id
		);

		if (isClientAlreadySelected !== undefined) {
			return;
		}

		const findIndex = tab.splice(1, 0, {
			id: uuidv4(),
			clientID: client.contact_id,
			title: client.name,
			content: () => {
				return (
					<ClientDocuments
						clientID={client.contact_id}
						clientEmail={client.email_id}
						clientType={client.type}
						setShowClientIdSearch={setShowClientIdSearch}
						showUploads={showUploads}
						beneficiary={client.beneficiary}
						typeOptions={client.typeOptions}
						clientSubType={client.sub_type}
						clientPhone={client.phone_number}
					/>
				);
			},
			canClose: true,
		});

		const filteredTab = tab.filter((value, index) => index !== findIndex);

		setTab(filteredTab);
		if (clientId !== undefined && clientId === client.contact_id) {
			setTabValue(tab[1].id);
			setShowClientIdSearch(true);
		}
	};

	const handleChange = (index) => {
		setTabValue(index);
	};

	const closeTab = (e, index) => {
		e.stopPropagation();
		const newTab = tab.filter((tab) => tab.id !== index);
		const allClientTabId = newTab[0].id;
		setTab(newTab);
		setTabValue(allClientTabId);
		if (clientId !== undefined) {
			window.location.assign("/clients");
		}
	};

	return (
		<div className="document-container">
			<div className="navbar">
				<Navbar />
			</div>
			<div className="document-content">
				{/* <button
          style={{ visibility: firstTabId === tabValue ? "visible" : "hidden" }}
          className="add-document"
          onClick={onAddClientButtonClick}
        >
          Add Client
        </button> */}
				{!showAddClientForm ? (
					<div
						className={
							tab.length > 7 ? "scrollable-tabs" : "not-scrollable-tabs"
						}
					>
						{tab.length > 7 ? (
							<div className="scroll-icons" onClick={() => scrollRight(150)}>
								<IoIosArrowDropleftCircle />
							</div>
						) : null}
						<div className="tabs-header" ref={scrollRef}>
							{tab.map((tab, index) => (
								<div
									key={tab.id}
									className={
										tabValue === tab.id
											? "tab-title active-tab-title"
											: "tab-title"
									}
									onClick={() => handleChange(tab.id)}
								>
									<span className="truncate">
										{tab.title.charAt(0).toUpperCase()}
										{tab.title.slice(1)}
									</span>
									{tab.canClose ? (
										<button
											className="tab-close-icon"
											onClick={(e) => closeTab(e, tab.id)}
										>
											<AiOutlineCloseCircle />
										</button>
									) : null}
								</div>
							))}
						</div>
						{tab.length > 7 ? (
							<div className="scroll-icons" onClick={() => scrollLeft(150)}>
								<IoIosArrowDroprightCircle />
							</div>
						) : null}
					</div>
				) : null}
				<div className="tab-content">
					{tab.map((tab, index) => {
						return tabValue === tab.id ? (
							<div key={index}>
								{tab.title === "All Clients"
									? tab.content(
											addClientTab,
											showAddClientForm,
											onAddClientButtonClick,
											showClientIdSearch
									  )
									: tab.content()}
							</div>
						) : null;
					})}
				</div>
			</div>
		</div>
	);
};

export default Clients;
