import baseUrl from "../config/baseUrl";

const addClient = async ({
    token,
    name,
    description,
    email_id,
    type,
    sub_type,
    status,
    client_reference_number,
    phone_number,
    created_by,
}) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("email_id", email_id);
    formData.append("type", type);
    if (sub_type && sub_type !== 'None') {
        formData.append("sub_type", sub_type);
    }
    formData.append("status", status);
    formData.append("client_reference_number", client_reference_number);
    formData.append("phone_number", phone_number);
    formData.append("created_by", created_by);

    try {
        const response = await fetch(`${baseUrl}/api/add-client-data`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        });
        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};

export default addClient;
