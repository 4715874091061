import baseUrl from "../config/baseUrl";

const getDocumentRequestStatus = async (token, documentRequestId) => {
  try {
    const response = await fetch(
      `${baseUrl}/api/get-document-status?document_request_id=${documentRequestId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.json();
  } catch (error) {
    return error;
  }
};

export default getDocumentRequestStatus;
