import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Loading = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				width: '86vw',
				height: '92vh'
			}}>
			<CircularProgress />
		</Box>
	);
};

export default Loading;
