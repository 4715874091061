import baseUrl from "../config/baseUrl";

const addDocument = async ({
  token,
  user_id,
  contact_id,
  document_name,
  document_desc,
  document_request_id,
  favourite,
  //document_status,
  document_url,
  category_id,
  document_sub_category
}) => {
  const formData = new FormData();
  formData.append("contact_id", contact_id);
  formData.append("document_request_id", document_request_id);
  formData.append("document_name", document_name);
  formData.append("document_desc", document_desc);
  formData.append("favourite", favourite);
  //formData.append("document_status", document_status);
  formData.append("document_url", document_url);
  formData.append("category_id", category_id);
  formData.append("document_sub_category", document_sub_category);

  try {
    const response = await fetch(`${baseUrl}/api/add-document-data`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default addDocument;
