import baseUrl from "../config/baseUrl";

const downloadLink = async ({ token, docRequestId }) => {
  try {
    const response = await fetch(
      `${baseUrl}/api/view-document-by-document-request-id?document_request_id=${docRequestId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default downloadLink;
