import baseUrl from "../config/baseUrl";

const assignUser = async ({
  token,
  document_source_details,
  created_at,
  document_source_type,
  user_id,
  bulkAssignIds
}) => {

  let query_string = '';
  if (document_source_details !== undefined) {
    query_string += `&document_source_details=${document_source_details}`;
  }
  if (created_at !== undefined) {
    query_string += `&created_at=${created_at}`;
  }
  if (document_source_type !== undefined) {
    query_string += `&document_source_type=${document_source_type}`;
  }
  if (bulkAssignIds !== undefined) {
    query_string += `&document_id=${bulkAssignIds}`;
  }

  try {
    const response = await fetch(
      `${baseUrl}/api/assign-user?user_id=${user_id}${query_string}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default assignUser;
