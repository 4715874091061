export const Columns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Category",
    accessor: "name",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Badge",
    accessor: "badge",
  },
  {
    Header: "Category Color",
    accessor: "bg_color",
  },
];
