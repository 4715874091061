import baseUrl from "../config/baseUrl";

const addCategory = async ({ token, name, description, badge, bgColor }) => {
  try {
    const response = await fetch(`${baseUrl}/DMS/api/add_registercategory`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: new URLSearchParams({
        name,
        description,
        badge,
        bg_color: bgColor,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default addCategory;
