import { Skeleton } from "../../shadcn-components/ui/skeleton";

const CheckboxWithLabelSkeleton = () => {
	const skeletonItems = Array.from({ length: 5 });

	return (
		<div className="space-y-4">
			{skeletonItems.map((_, index) => (
				<div key={index} className="flex items-center gap-2 m-2">
					<Skeleton className="w-4 h-5 rounded" />
					<Skeleton className="w-full h-5 rounded" />
				</div>
			))}
		</div>
	);
};

export default CheckboxWithLabelSkeleton;
