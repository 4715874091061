import React, { useRef, forwardRef, useEffect } from "react";

const CheckBox = forwardRef(({ indeterminate, id, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input name={id} type="checkbox" ref={resolvedRef} {...rest} />;
});

export default CheckBox;
