const addTask = async ({
  token,
  payloadParams
}) => {

  try {
    const response = await fetch(`https://uat.consumerlaw.com/rest/v11_20/add-tasks`, {
      method: "POST",
      // headers: {
      //  "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        ...payloadParams,
        task_category_c: "Immigration", // Ensure task_category_c is included
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default addTask;
