import baseUrl from "../config/baseUrl";

const loginUser = async ({ email, password }) => {
  try {
    const response = await fetch(`${baseUrl}/api/login_users`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: new URLSearchParams({
        email: email,
        password: password,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default loginUser;
