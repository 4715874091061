import baseUrl from "../config/baseUrl";

const restoreCategory = async ({ token, id }) => {
  try {
    const response = await fetch(`${baseUrl}/DMS/api/categoriesrestore${id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default restoreCategory;
