import { Pie, PieChart, Cell } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../shadcn-components/ui/card";
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "../../shadcn-components/ui/chart";
import { Skeleton } from "../../shadcn-components/ui/skeleton";
import { useEffect, useState } from "react";
import getProcessedUserCount from "../../api/getProcessedUserCount";
import "../../styles/Dashboard.css";

export const description = "A pie chart";

const availableColors = [
  "hsl(var(--chart-1))", // Color for user 1
  "hsl(var(--chart-2))", // Color for user 2
  "hsl(var(--chart-3))", // Color for user 3
  "hsl(var(--chart-4))", // Color for user 4
  "hsl(var(--chart-5))", // Color for other
];

export function BarChart({ customBoxShadow, token }) {
  const [chartData, setChartData] = useState([]);
  const [chartConfig, setChartConfig] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const res = await getProcessedUserCount({ token });
      const users = res.data;

      const sortedUsers = users.sort(
        (a, b) => b.document_count - a.document_count
      );

      const top4Users = sortedUsers.slice(0, 4);
      const otherUsers = sortedUsers.slice(4);

      const otherDocumentCount = otherUsers.reduce(
        (sum, user) => sum + user.document_count,
        0
      );

      const newChartData = [
        ...top4Users.map((user, index) => ({
          assign_user: user.assign_user,
          document_count: user.document_count,
          fill: availableColors[index],
        })),
        {
          assign_user: "Other",
          document_count: otherDocumentCount,
          fill: availableColors[4],
        },
      ];

      const newChartConfig = {
        ...top4Users.reduce((acc, user, index) => {
          acc[user.assign_user] = {
            label: user.assign_user,
            color: availableColors[index],
          };
          return acc;
        }, {}),
        Other: {
          label: "Other",
          color: availableColors[4],
        },
      };

      setChartData(newChartData);
      setChartConfig(newChartConfig);
      setLoading(false);
    }

    fetchData();
  }, [token]);

  return (
    <Card className="flex flex-col" style={customBoxShadow}>
      <CardHeader className="items-center pb-0">
        <CardTitle>Pie Chart</CardTitle>
        <CardDescription>User Processed Cases</CardDescription>
      </CardHeader>
      <CardContent className="flex-1 pb-0">
        <ChartContainer
          className="mx-auto aspect-square max-h-[300px]"
          config={chartConfig} // Pass chartConfig to the container
        >
          {!loading ? (
            <PieChart>
              <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
              <Pie
                data={chartData}
                dataKey="document_count"
                nameKey="assign_user"
                outerRadius={100}
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.fill} />
                ))}
              </Pie>
              <ChartLegend
                content={<ChartLegendContent nameKey="assign_user" />}
                className="-translate-y-2 flex-wrap gap-2 [&>*]:basis-1/4 [&>*]:justify-center"
              />
            </PieChart>
          ) : (
            <Skeleton className="rounded-full mt-6 mx-auto aspect-square max-h-[200px]" />
          )}
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
