import baseUrl from "../config/baseUrl";

const restoreUser = async ({ token, id }) => {
    try {
        const response = await fetch(`${baseUrl}/api/restore-deleted-record?id=${id}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};

export default restoreUser;
