import React, { useState, useContext } from "react";
import { TbCircleCheck } from "react-icons/tb";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import ButtonUI from "../../material-ui/ButtonUI";
import { AuthContext } from "../../../context/Auth/AuthContext";
import approveRequestedCase from "../../../api/approveRequestedCase";

/*** Component for approving the document from the pdf viewer ***/
const ApprovePDFDocumentPopup = ({
  documentRequestId,
  setReqStatus,
  setDocumentViewer,
  setDocumentUpdatedToTrue,
  setApproveConfirmOpen,
  currentTabId,
  closeTabById }) => {

  const [open, setOpen] = useState(true);
  const { token } = useContext(AuthContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setApproveConfirmOpen(false);
  };

  const onApproveDocumentFormSubmit = async () => {
    const approveCaseFormResponse = await approveRequestedCase({
      token: token,
      docId: documentRequestId,
    });

    if (approveCaseFormResponse?.code === 200) {
      setOpen(false);
      toast.success("Document is approved successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setReqStatus('Approved');
      if (setDocumentViewer) {
        setDocumentViewer(false);
      }

      if (setDocumentUpdatedToTrue) {
        setDocumentUpdatedToTrue();
      }
      if (currentTabId) {
        closeTabById(currentTabId);
      }
      setApproveConfirmOpen(false);
    } else {
      setOpen(false);
      toast.error("Error while approving the document", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      //setDocumentViewer(false);
      setApproveConfirmOpen(false);
    }
  };

  return (
    <div className="dialog-modal">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="edit-doc-modal">
          <div className="popup-header-svg-icon">
            <TbCircleCheck />
          </div>
          <div className="popup-header-title">Approve Document</div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to approve this document ?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-end" }}>
          <ButtonUI
            formButton={false}
            variant="outlined"
            buttonText="No"
            style={{
              margin: "15px 0px",
              marginTop: "0px",
              backgroundColor: "#fff",
              color: "#007bff",
              border: "1px solid #007bff",
            }}
            onClickFunc={handleClose}
          />
          <ButtonUI
            formButton={false}
            variant="outlined"
            buttonText="Yes"
            style={{
              margin: "15px",
              marginTop: "0px",
              backgroundColor: "#fff",
              color: "##007bff",
              border: "1px solid #007bff",
            }}
            autoFocus={true}
            onClickFunc={() => {
              onApproveDocumentFormSubmit();
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ApprovePDFDocumentPopup;
