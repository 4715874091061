import React, { useState, useEffect, useContext, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import Navbar from "../components/common/Navbar";
import PdfViewer from "../components/pdf-viewer/PdfViewer";
import ContractTemplatesTable from "../components/contract-templates/ContractTemplatesTable";
import { AuthContext } from "../context/Auth/AuthContext";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { DocusealForm, DocusealBuilder } from '@docuseal/react'

import {
  CaretSortIcon,
  ChevronDownIcon,
  StarIcon,
  StarFilledIcon,
} from "@radix-ui/react-icons";

import "../styles/Users.css";

const ContractTemplates = () => {

  const customCss = `
  .sticky #expand_form_button {
    display: none;
  }

  .sticky:has(> .tabler-icon-info-circle){
    display: none;
  }

  .shadow-md .field-area {
    pointer-events: none;
  }
`;
  const [
    showAddContractTemplatesForm,
    setShowAddContractTemplatesForm,
  ] = useState(false);

  const [typeOptions, setTypeOptions] = useState([]);
  const [docTypeOptions, setDocTypeOptions] = useState([]);
  const { token, user_id, is_admin } = useContext(AuthContext);
  const [tab, setTab] = useState([
    {
      id: uuidv4(),
      contractTemplateID: null,
      title: "All Contract Templates",
      content: (
        addContractTemplateTab,
        showAddContractTemplatesForm,
        onAddContractTemplatesButtonClick,
        typeOptions,
        setTypeOptions,
        docTypeOptions,
        setDocTypeOptions
      ) => (
        <ContractTemplatesTable
          addContractTemplateTab={addContractTemplateTab}
          showAddContractTemplatesForm={
            showAddContractTemplatesForm
          }
          onAddContractTemplatesButtonClick={
            onAddContractTemplatesButtonClick
          }
          typeOptions={typeOptions}
          setTypeOptions={setTypeOptions}
          docTypeOptions={docTypeOptions}
          setDocTypeOptions={setDocTypeOptions}
        />
      ),
      canClose: false,
    },
  ]);
  const [tabValue, setTabValue] = useState(tab[0].id);
  const firstTabId = tab[0].id;

  const scrollRef = useRef(null);

  const truncate = (string, n) => {
    return string?.length > n ? string.substr(0, n - 1) + "..." : string;
  };

  const scrollLeft = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset;
  };

  const scrollRight = (scrollOffset) => {
    scrollRef.current.scrollLeft -= scrollOffset;
  };

  const changeActiveTab = () => {
    setTabValue(tab[0].id);
  };

  const addContractTemplateTab = (contractTemplate) => {
    console.log(contractTemplate.docuseal_token);

    const dcToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2VtYWlsIjoic2FqaXRoYS5jaGV0dGlhckBuYWJsYXNvbC5jb20iLCJpbnRlZ3JhdGlvbl9lbWFpbCI6InNhaml0aGEuY2hldHRpYXJAbmFibGFzb2wuY29tIiwidGVtcGxhdGVfaWQiOiI5MTgyNiIsImlhdCI6MTcxNTkyMjE1NX0.EVe8clnUBMFlzWQuJYluXxN46i5d_cK7Xk7AAWzzniA";
    const isContractTemplateAlreadySelected = tab.find(
      (tab) => tab.contractTemplateID === contractTemplate.contract_request_setting_id
    );

    if (isContractTemplateAlreadySelected !== undefined) {
      return;
    }

    const findIndex = tab.splice(1, 0, {
      id: uuidv4(),
      contractTemplateID: contractTemplate.contract_request_setting_id,
      title: truncate(contractTemplate.contract_name, 9),
      content: () => {
        return (
          <>
            {contractTemplate.template_slug ? (
              <div className="pdf-viewer-container" style={{ maxHeight: 'fit-content' }}>
                <div className="pdf-viewer">
                  {/* <DocusealForm
                    src={"https://docuseal.co/d/" + contractTemplate.template_slug}
                    email={process.env.REACT_APP_DOCUSEAL_USER_EMAIL}
                    customCss={customCss}
                    withTitle="false"
                    expand="false"
                  /> */}
                  <DocusealBuilder
                    token={contractTemplate.docuseal_token}
                    withTitle="false"
                    autosave="false"
                    withSendButton="false"
                    withSignYourselfButton="false"
                  // customCss={customCss}
                  // onSave={handleSave}
                  // onUpload={handleUpload}
                  />
                </div>
              </div>
            ) : null}
          </>
        );
      },
      canClose: true,
    });

    const filteredTab = tab.filter((value, index) => index !== findIndex);

    setTab(filteredTab);
  };

  const handleChange = (index) => {
    setTabValue(index);
  };

  const closeTab = (e, index) => {
    e.stopPropagation();
    const newTab = tab.filter((tab) => tab.id !== index);
    const allContractTemplateTabId = newTab[0].id;
    setTab(newTab);
    setTabValue(allContractTemplateTabId);
  };

  const onAddContractTemplatesButtonClick = () => {
    setShowAddContractTemplatesForm(!showAddContractTemplatesForm);
  };

  return (
    <div className="user-container">
      <div className="navbar">
        <Navbar />
      </div>
      <div className="user-table">
        <div className="user-content">
          {is_admin === "1" ? (
            <button
              className="add-user"
              onClick={onAddContractTemplatesButtonClick}
              style={{ visibility: firstTabId === tabValue ? 'visible' : 'hidden' }}
            >
              Add Contract Template
            </button>
          ) : null}
          {!showAddContractTemplatesForm ? (
            <div
              className={
                tab.length > 7 ? "scrollable-tabs" : "not-scrollable-tabs"
              }
            >
              {tab.length > 7 ? (
                <div className="scroll-icons" onClick={() => scrollRight(150)}>
                  <IoIosArrowDropleftCircle />
                </div>
              ) : null}
              <div className="tabs-header" ref={scrollRef}>
                {tab.map((tab, index) => (
                  <div
                    key={tab.id}
                    className={
                      tabValue === tab.id
                        ? "tab-title active-tab-title"
                        : "tab-title"
                    }
                    onClick={() => handleChange(tab.id)}
                  >
                    <span>{tab.title}</span>
                    {tab.canClose ? (
                      <button
                        className="tab-close-icon"
                        onClick={(e) => closeTab(e, tab.id)}
                      >
                        <AiOutlineCloseCircle />
                      </button>
                    ) : null}
                  </div>
                ))}
              </div>
              {tab.length > 7 ? (
                <div className="scroll-icons" onClick={() => scrollLeft(150)}>
                  <IoIosArrowDroprightCircle />
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="tab-content">
            {tab.map((tab, index) => {
              return tabValue === tab.id ? (
                <div key={index}>
                  {tab.title === "All Contract Templates"
                    ? tab.content(
                      addContractTemplateTab,
                      showAddContractTemplatesForm,
                      onAddContractTemplatesButtonClick,
                      typeOptions,
                      setTypeOptions,
                      docTypeOptions,
                      setDocTypeOptions
                    )
                    : tab.content()}
                </div>
              ) : null;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractTemplates;
