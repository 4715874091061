import baseUrl from "../config/baseUrl";

const permanentlyDeleteUser = async ({ token, id }) => {
    try {
        const response = await fetch(
            `${baseUrl}/api/delete-permanant-user-record?id=${id}`,
            {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};

export default permanentlyDeleteUser;
