import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MdModeEdit } from "react-icons/md";
import { RiCloseCircleFill } from "react-icons/ri";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import EditClientForm from "./EditClientForm";
import ButtonUI from "../material-ui/ButtonUI";
import editBeneficiary from "../../api/editBeneficiary";
import { Controller } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../material-ui/InputUI";
import { AuthContext } from "../../context/Auth/AuthContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

const BootstrapDialogTitle = (props) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<RiCloseCircleFill />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

const editClientFormSchema = yup.object().shape({
	name: yup.string().required("Name is required field").nullable(),
	email_id: yup
		.string()
		.email("Email must be valid")
		.required("Email is required field")
		.nullable(),
	phone_number: yup.string().nullable().notRequired(),
});

const EditBeneficiaryPopup = ({ tableValues, setIsBeneficiaryEdited }) => {
	const [open, setOpen] = useState(false);
	const { token, user_id } = useContext(AuthContext);

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(editClientFormSchema),
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onEditClientFormSubmit = async (editClientFormData) => {
		const editClientFormResponse = await editBeneficiary({
			contact_id: tableValues.contact_id,
			token: token,
			user_id: user_id,
			name: editClientFormData.name,
			email_id: editClientFormData.email_id,
			phone_number: editClientFormData.phone_number,
		});

		if (editClientFormResponse.code === 200) {
			setOpen(false);
			setIsBeneficiaryEdited((prev) => !prev);

			toast.success("Beneficiary is updated successfully", {
				position: "top-center",
				autoClose: 3000,
				theme: "dark",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		} else {
			toast.error("Error while updating Beneficiary", {
				position: "top-center",
				autoClose: 5000,
				theme: "dark",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	};

	return (
		<div className="dialog-modal">
			<ButtonUI
				formButton={false}
				variant="outlined"
				buttonText={<MdModeEdit />}
				style={{
					backgroundColor: "#17a2b8",
					color: " white",
					padding: "8px",
					minWidth: "fit-content",
					border: "none",
				}}
				onClickFunc={handleClickOpen}
			/>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				className="custom-edit-modal"
				open={open}
			>
				<BootstrapDialogTitle
					id="customized-dialog-title"
					className="edit-doc-modal"
					onClose={handleClose}
				>
					<div className="popup-header-svg-icon">
						<MdModeEdit />
					</div>
					<div className="popup-header-title">Edit Beneficiary</div>
				</BootstrapDialogTitle>
				<DialogContent dividers>
					<Grid className="edit-document">
						<div className="edit-document-form grid grid-cols-1 gap-3 sm:grid-cols-2">
							<div className="add-document-inputs">
								<div className="add-document-inputs-with-error-message !w-full">
									<Controller
										name="name"
										defaultValue={tableValues.name}
										control={control}
										render={({ field: { onChange, value } }) => (
											<InputUI
												name="name"
												label="Name"
												type="text"
												value={value ? value : ""}
												disabled={true}
												setValue={onChange}
												variant="outlined"
												cssStyles={{
													margin: "10px 0px",
												}}
												register={register}
											/>
										)}
									/>
									<span className="error-message">
										{errors["name"]?.message}
									</span>
								</div>
							</div>
							<div className="add-document-form">
								<div className="add-document-inputs-with-error-message !w-full">
									<Controller
										name="email_id"
										control={control}
										defaultValue={tableValues.email_id}
										render={({ field: { onChange, value } }) => (
											<InputUI
												name="email_id"
												label="Email ID"
												type="email"
												value={value ? value : ""}
												setValue={onChange}
												variant="outlined"
												cssStyles={{ margin: "10px 0px" }}
												register={register}
											/>
										)}
									/>

									<span className="error-message">
										{errors["email_id"]?.message}
									</span>
								</div>
							</div>
							<div className="add-document-inputs">
								<div className="add-document-inputs-with-error-message !w-full">
									<Controller
										name="phone_number"
										control={control}
										defaultValue={tableValues.phone_number}
										render={({ field: { onChange, value } }) => (
											<InputUI
												name="phone_number"
												label="Phone Number"
												type="text"
												value={value ? value : ""}
												setValue={onChange}
												variant="outlined"
												cssStyles={{ margin: "10px 0px" }}
												register={register}
											/>
										)}
									/>

									<span className="error-message">
										{errors["phone_number"]?.message}
									</span>
								</div>
							</div>
						</div>
					</Grid>
				</DialogContent>
				<DialogActions sx={{ paddingBottom: "15px !important" }}>
					<div className="popup_submit_btn">
						<ButtonUI
							variant="contained"
							buttonText="Submit"
							handleSubmit={handleSubmit}
							onFormSubmit={onEditClientFormSubmit}
						/>
					</div>
				</DialogActions>
			</BootstrapDialog>
		</div>
	);
};

export default EditBeneficiaryPopup;
