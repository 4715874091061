import React from "react";
import { LoadingButton } from "@mui/lab";

const ButtonUI = ({
  formButton = true,
  loading,
  variant,
  buttonText,
  disabled,
  style,
  autoFocus,
  handleSubmit,
  onFormSubmit,
  onClickFunc,
}) => {
  return (
    <LoadingButton
      loading={loading}
      variant={variant}
      style={style}
      sx={{ textTransform: "none" }}
      disabled={disabled}
      autoFocus={autoFocus}
      onClick={formButton ? handleSubmit(onFormSubmit) : onClickFunc}
    >
      {buttonText}
    </LoadingButton>
  );
};

export default ButtonUI;
