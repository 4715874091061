import React, { useState, useContext } from "react";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonUI from "../material-ui/ButtonUI";
import { AuthContext } from "../../context/Auth/AuthContext";
import deleteAllCategory from "../../api/deleteAllCategory";

const BulkDeletePopup = ({
  setCategoryDeletedToTrue,
  isEntireTableRowsSelected,
  changeDropdownVisibilityFunc,
  Docbulkid = [],
}) => {
  const [open, setOpen] = useState(false);
  const { token } = useContext(AuthContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onBulkDelete = async (Docbulkid) => {
    const deleteAllCategoryFormResponse = await deleteAllCategory({
      token: token,
      id: Docbulkid,
    });

    if (deleteAllCategoryFormResponse.code === 200) {
      setOpen(false);
      setCategoryDeletedToTrue(isEntireTableRowsSelected);
      changeDropdownVisibilityFunc();
      toast.success("Category is deleted successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="dialog-modal">
      <div
        onClick={() => {
          handleClickOpen(Docbulkid);
        }}
        className="deleteBtn"
      >
        Delete
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="edit-doc-modal">
          <div className="popup-header-svg-icon">
            <MdDelete />
          </div>
          <div className="popup-header-title">Delete Category</div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this category ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonUI
            formButton={false}
            variant="outlined"
            buttonText="No"
            style={{ marginTop: "15px" }}
            onClickFunc={handleClose}
          />
          <ButtonUI
            formButton={false}
            variant="outlined"
            buttonText="Yes"
            style={{ marginTop: "15px" }}
            autoFocus={true}
            onClickFunc={() => {
              onBulkDelete(Docbulkid);
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BulkDeletePopup;
