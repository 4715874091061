import React, { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import { MdOutlineSpaceDashboard, MdOutlineLogout } from "react-icons/md";
import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdOutlineDocumentScanner,
  MdOutlineMiscellaneousServices,
  MdOutlineContactMail,
  MdMailOutline,
  MdOutlineScanner,
  MdOutlineAssignmentInd,
} from "react-icons/md";
import { CgFileDocument } from "react-icons/cg";
import { RiSettings5Line, RiListSettingsFill } from "react-icons/ri";
import { FaRegUserCircle } from "react-icons/fa";
import { BiCategory, BiMessageSquareDetail } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import { AiOutlineFieldTime } from "react-icons/ai";
import { BsBookmarkStar } from "react-icons/bs";
import { BiTrash } from "react-icons/bi";
import { HiOutlineClipboardList } from "react-icons/hi";
import { TbMailOff } from "react-icons/tb";
import { IoIosGitPullRequest } from "react-icons/io";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { RiMailStarLine } from "react-icons/ri";
import { LiaFileContractSolid } from "react-icons/lia";
import { GrDocumentExcel, GrDocumentVerified } from "react-icons/gr";
import {
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarRightCollapse,
  TbChecklist,
} from "react-icons/tb";
import logoutUser from "../../api/logout";
import { AuthContext } from "../../context/Auth/AuthContext";
import logo from "../../images/logo.png";
import logo_s from "../../images/logo_collapsed.png";
import "../../styles/Sidebar.css";

const SideBar = () => {
  const [isSideBarCollapsed, setIsSideBarCollapsed] = useState(
    JSON.parse(localStorage.getItem("isSideBarCollapsed")) || false
  );

  const [isOpen, setIsOpen] = useState(false);
  const [openSubMenuName, setOpenSubMenuOpen] = useState("");

  const location = useLocation();
  const pathname = location.pathname;
  const showClients = pathname.endsWith("/clients") ? true : false;

  let { cid } = useParams();
  let clientId = cid;

  const { instance } = useMsal();

  const toggleSubmenu = (name) => {
    if (openSubMenuName === name && isOpen === true) {
      setIsOpen(false);
      openSidebar();
      return;
    }
    setOpenSubMenuOpen(name);
    setIsOpen(true);
    openSidebar();
  };

  const redirect = useNavigate();

  const { token, is_admin, dispatch } = useContext(AuthContext);

  const routes =
    is_admin === "1"
      ? [
          {
            path: "/dashboard",
            name: "Dashboard",
            icon: <MdOutlineSpaceDashboard />,
          },
          {
            name: "Documents",
            icon: <CgFileDocument />,
            submenu: [
              {
                path: "/documents",
                name: "All Documents",
                icon: <CgFileDocument />,
              },
              {
                path: "/clients",
                name: "Clients",
                icon: <HiOutlineClipboardList />,
              },
              {
                path: "/requests",
                name: "Requests",
                icon: <AiOutlineFieldTime />,
              },
              {
                path: "/starred-documents",
                name: "Favourites",
                icon: <BsBookmarkStar />,
              },
            ],
          },
          {
            name: "Mailroom",
            icon: <MdOutlineContactMail />,
            submenu: [
              {
                path: "/assigned-case-list",
                name: "All Assigned",
                icon: <MdOutlineMarkEmailRead />,
              },
              {
                path: "/unassigned-case-list",
                name: "All Unassigned",
                icon: <TbMailOff />,
              },
              {
                path: "/my-assigned-case-list",
                name: "My Assigned",
                icon: <RiMailStarLine />,
              },
              {
                path: "/email-case-list",
                name: "Incoming Emails",
                icon: <MdMailOutline />,
              },
              {
                path: "/fax-case-list",
                name: "Incoming Faxes",
                icon: <BiMessageSquareDetail />,
              },
              {
                path: "/scan-case-list",
                name: "Scanned Files",
                icon: <MdOutlineScanner />,
              },
            ],
          },
          // {
          //   name: "Contracts",
          //   icon: <LiaFileContractSolid />,
          //   submenu: [
          //     {
          //       path: "/contracts",
          //       name: "All Contracts",
          //       icon: <LiaFileContractSolid />,
          //     },
          //     {
          //       path: "/requested-contracts",
          //       name: "Requested Contracts",
          //       icon: <GrDocumentExcel />,
          //     },
          //     {
          //       path: "/signed-contracts",
          //       name: "Signed Contracts",
          //       icon: <GrDocumentVerified />,
          //     },
          //   ],
          // },
          {
            name: "Settings",
            icon: <RiSettings5Line />,
            submenu: [
              {
                path: "/users",
                name: "Users",
                icon: <FaRegUserCircle />,
              },
              {
                path: "/document-request-settings",
                name: "Document Requests",
                icon: <IoIosGitPullRequest />,
              },
              // {
              //   path: "/contract-templates",
              //   name: "Contract Templates",
              //   icon: <LiaFileContractSolid />,
              // },
              {
                path: "/field-settings",
                name: "Field Settings",
                icon: <RiListSettingsFill />,
              },
            ],
          },
          {
            name: "Delete",
            icon: <BiTrash />,
            submenu: [
              {
                path: "/trash-users",
                name: "Users",
                icon: <FaRegUserCircle />,
              },
              {
                path: "/trash-documents",
                name: "Documents",
                icon: <CgFileDocument />,
              },
              // {
              //   path: "/trash-category",
              //   name: "Document Request",
              //   icon: <BiCategory />,
              // },
            ],
          },
          {
            path: "/login",
            name: "Logout",
            icon: <MdOutlineLogout />,
          },
        ]
      : [
          {
            path: "/dashboard",
            name: "Dashboard",
            icon: <MdOutlineSpaceDashboard />,
          },
          {
            name: "Documents",
            icon: <CgFileDocument />,
            submenu: [
              {
                path: "/documents",
                name: "All Documents",
                icon: <CgFileDocument />,
              },
            ],
          },
          {
            name: "Mailroom",
            icon: <MdOutlineContactMail />,
            submenu: [
              {
                path: "/documents",
                name: "My Assignments",
                icon: <MdOutlineAssignmentInd />,
              },
              {
                path: "/email-case-list",
                name: "Incoming Emails",
                icon: <MdMailOutline />,
              },
            ],
          },
          {
            name: "Delete",
            icon: <BiTrash />,
            submenu: [
              {
                path: "/trash-documents",
                name: "Documents",
                icon: <CgFileDocument />,
              },
            ],
          },
          {
            path: "/login",
            name: "Logout",
            icon: <MdOutlineLogout />,
          },
        ];

  useEffect(() => {
    if (clientId !== undefined || showClients) {
      setIsOpen(true);
      setOpenSubMenuOpen("Documents");
    }

    localStorage.setItem(
      "isSideBarCollapsed",
      JSON.stringify(isSideBarCollapsed)
    );

    return () => localStorage.removeItem("isSideBarCollapsed");
  }, [isSideBarCollapsed]);

  const toggleSidebar = () => {
    setIsSideBarCollapsed((isSideBarCollapsed) => !isSideBarCollapsed);
  };

  const openSidebar = () => {
    setIsSideBarCollapsed(false);
  };

  const logoutFn = async () => {
    const data = await logoutUser({ token });
  };

  const handleLogout = async () => {
    logoutFn();
    dispatch({ type: "LOGOUT" });
    redirect("/login");
    toast.success("Successfully Logged Out", {
      position: "top-center",
      autoClose: 3000,
      theme: "dark",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    // instance
    //   .logoutPopup()
    //   .then(() => {
    //     logoutFn();
    //     dispatch({ type: "LOGOUT" });
    //     redirect("/login");
    //     toast.success("Successfully Logged Out", {
    //       position: "top-center",
    //       autoClose: 3000,
    //       theme: "dark",
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //   })
    //   .catch((e) => {
    //     console.error(e);
    //   });
  };

  return (
    <div className="sidebar-component">
      <div
        className={
          isSideBarCollapsed
            ? "collapsed-sidebar-container"
            : "sidebar-container"
        }
      >
        <div className="top-section">
          <NavLink to="/dashboard">
            {!isSideBarCollapsed ? (
              <div className="open-sidebar-img">
                <img src={logo} alt="logo" />
              </div>
            ) : (
              <img src={logo_s} width="100%" alt="logo" />
            )}
          </NavLink>
          <div onClick={toggleSidebar} className="scrollable-bar">
            {!isSideBarCollapsed ? (
              <TbLayoutSidebarLeftCollapse />
            ) : (
              <TbLayoutSidebarRightCollapse />
            )}
          </div>
        </div>
        <section className="sidebar-list-section">
          {routes.map((route, index) => (
            <React.Fragment key={index}>
              {!route.submenu ? (
                <NavLink
                  to={route.path}
                  key={index}
                  className={({ isActive }) =>
                    isActive ? "active-sidebar-list" : "sidebar-list"
                  }
                  onClick={route.name === "Logout" ? handleLogout : null}
                >
                  <div className="sidebar-list-icon">{route.icon}</div>
                  <div
                    className={
                      !isSideBarCollapsed
                        ? "sidebar-list-title"
                        : "hide-sidebar-list-title"
                    }
                  >
                    {route.name}
                  </div>
                </NavLink>
              ) : (
                <>
                  <div
                    onClick={() => toggleSubmenu(route.name)}
                    className={
                      isSideBarCollapsed
                        ? "collpased-submenu-sidebar-container"
                        : "submenu-sidebar-container"
                    }
                  >
                    <div className="submenu-header">
                      <div className="sidebar-list-icon">{route.icon}</div>
                      <div
                        className={
                          !isSideBarCollapsed
                            ? "sidebar-list-title"
                            : "hide-sidebar-list-title"
                        }
                      >
                        {route.name}
                      </div>
                    </div>
                    <div className="submenu-arrow-icon">
                      {!isSideBarCollapsed ? (
                        isOpen && route.name === openSubMenuName ? (
                          <MdKeyboardArrowUp />
                        ) : (
                          <MdKeyboardArrowDown />
                        )
                      ) : null}
                    </div>
                  </div>
                  <div className="submenu-list">
                    {isOpen &&
                    route.name === openSubMenuName &&
                    isSideBarCollapsed === false
                      ? route.submenu.map((subroute, index) => (
                          <NavLink
                            to={subroute.path}
                            key={index}
                            className={({ isActive }) =>
                              isActive ? "active-submenu-item" : "submenu-item"
                            }
                          >
                            <div className="sidebar-list-icon">
                              {subroute.icon}
                            </div>
                            <div className="sidebar-list-title">
                              {subroute.name}
                            </div>
                          </NavLink>
                        ))
                      : null}
                  </div>
                </>
              )}
            </React.Fragment>
          ))}
        </section>
      </div>
    </div>
  );
};

export default SideBar;
