import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../material-ui/InputUI";

const EditContractTemplatesForm = ({ register, control, errors, tableValues, typeOptions, docTypeOptions }) => {

  const statusOptions = [
    {
      name: "Active",
      value: "active",
    },
    {
      name: "Inactive",
      value: "inactive",
    },
  ];

  const ocrStatusOptions = [
    {
      name: "True",
      value: "True",
    },
    {
      name: "False",
      value: "False",
    },
  ];

  return (
    <Grid className="edit-document">
      <div className="edit-document-form">
        <div className="field_wrapper">
          <div className="field">
            <div className="input-field">
              <Controller
                name="contractName"
                defaultValue={tableValues.contract_name}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputUI
                    name="contractName"
                    label="Document Name"
                    type="text"
                    value={value ? value : ""}
                    setValue={onChange}
                    variant="outlined"
                    register={register}
                  />
                )}
              />
            </div>
            <span className="error-message">
              {errors["contractName"]?.message}
            </span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <div className="input-field">
              <Controller
                name="description"
                defaultValue={tableValues.contract_description}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputUI
                    name="description"
                    label="Description"
                    type="text"
                    value={value ? value : ""}
                    setValue={onChange}
                    variant="outlined"
                    register={register}
                  />
                )}
              />
            </div>
            <span className="error-message">{errors["description"]?.message}</span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <FormControl className="edit-doc-select-option" fullWidth>
              <InputLabel className="edit-doc-select-label" id="select-label">
                Document Type
              </InputLabel>
              <Controller
                control={control}
                name="documentType"
                defaultValue={tableValues.document_type_id}
                render={({ field: { onChange, value } }) => (
                  <Select
                    name="documentType"
                    labelId="select-label"
                    variant="outlined"
                    id="simple-select"
                    value={value}
                    onChange={onChange}
                    label="Document Type"
                    style={{ marginBottom: "25px" }}
                    {...register("documentType")}
                  >
                    {docTypeOptions.map((option) => (
                      <MenuItem key={option.typeId} value={option.typeId} selected={option.label === tableValues.document_type}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <span className="error-message">{errors["documentType"]?.message}</span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <FormControl className="edit-doc-select-option" fullWidth>
              <InputLabel className="edit-doc-select-label" id="select-label">Client Type</InputLabel>
              <Controller
                control={control}
                name="clientType"
                defaultValue={tableValues.client_type_id}
                render={({ field: { onChange, value } }) => (
                  <Select
                    name="clientType"
                    labelId="select-label"
                    variant="outlined"
                    id="simple-select"
                    value={value}
                    onChange={onChange}
                    label="Client Type"
                    style={{ marginBottom: "25px" }}
                    {...register("clientType")}
                  >
                    {typeOptions?.map((option) => (
                      <MenuItem key={option.typeId} value={option.typeId} selected={option.label === tableValues.client_type}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <span className="error-message">
              {errors["clientType"]?.message}
            </span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <FormControl className="edit-doc-select-option" fullWidth>
              <InputLabel className="edit-doc-select-label" id="select-label">
                OCR Status
              </InputLabel>
              <Controller
                control={control}
                name="ocrStatus"
                defaultValue={tableValues.ocr_status}
                render={({ field: { onChange, value } }) => (
                  <Select
                    name="ocrStatus"
                    labelId="select-label"
                    variant="outlined"
                    id="simple-select"
                    value={value}
                    onChange={onChange}
                    style={{ marginBottom: "25px" }}
                    label="OCR Status"
                    {...register("ocrStatus")}
                  >
                    {ocrStatusOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <span className="error-message">{errors["ocrStatus"]?.message}</span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <FormControl className="edit-doc-select-option" fullWidth>
              <InputLabel className="edit-doc-select-label" id="select-label">
                Status
              </InputLabel>
              <Controller
                control={control}
                name="status"
                defaultValue={tableValues.status}
                render={({ field: { onChange, value } }) => (
                  <Select
                    name="status"
                    labelId="select-label"
                    variant="outlined"
                    id="simple-select"
                    value={value}
                    onChange={onChange}
                    label="Status"
                    {...register("status")}
                  >
                    {statusOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <span className="error-message">{errors["status"]?.message}</span>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default EditContractTemplatesForm;
