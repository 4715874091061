import React, { useState, useContext, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AiFillCloseCircle } from "react-icons/ai";
import { RiCloseCircleFill } from "react-icons/ri";
import InputUI from "../material-ui/InputUI";
import ButtonUI from "../material-ui/ButtonUI";
import { AuthContext } from "../../context/Auth/AuthContext";
import addClientContracts from "../../api/addClientContracts";
import addContractTemplateImg from "../../images/add-user.png";
import "../../styles/AddDocument.css";
import documentRequestSettingsData from "../../api/documentRequestSettings";
import baseUrl from "../../config/baseUrl";

const SUPPORTED_FORMATS = [
	"image/jpg",
	"image/jpeg",
	"image/gif",
	"image/png",
	"application/pdf",
];

const addContractFormSchema = yup.object().shape({
	addClientName: yup.string().required("Client Name is required field"),
	email_id: yup
		.string()
		.email("Email must be valid")
		.required("Email is required field"),
});

const AddContracts = ({
	onAddContractsButtonClick,
	setContractsAddedToTrue,
	clientNames,
}) => {
	const [loading, setLoading] = useState(false);
	const { token, user_id, is_admin } = useContext(AuthContext);
	const [tags, setTags] = useState([]);
	const [email, setEmail] = useState("");

	const {
		register,
		handleSubmit,
		watch,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(addContractFormSchema),
	});

	const selectedClient = watch("addClientName");

	const onAddContractFormSubmit = async () => {
		setLoading(true);
		const updatedTags = {
			contact_id: selectedClient,
			email_id: email,
			contracts: tags,
		};

		const addContractFormResponse = await addClientContracts({
			token: token,
			contracts: updatedTags,
		});

		if (addContractFormResponse?.code === 200) {
			onAddContractsButtonClick();
			setContractsAddedToTrue();
			toast.success("Contract is added successfully", {
				position: "top-center",
				autoClose: 3000,
				theme: "dark",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		} else {
			toast.error("Error while adding Contract", {
				position: "top-center",
				autoClose: 5000,
				theme: "dark",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
		setLoading(false);
	};

	const removeTag = (index) => {
		const filteredTags = tags.filter((tag, i) => i !== index);
		setTags(filteredTags);
	};

	const onLoadContractFunc = async () => {
		if (selectedClient !== undefined && selectedClient !== null) {
			setLoading(true);
			setEmail("");
			const urlEmail = `${baseUrl}/api/get-client-email?contact_id=${selectedClient}`;
			const responseEmail = await documentRequestSettingsData({
				token,
				api: urlEmail,
			});

			if (responseEmail.code == 200) {
				setEmail(responseEmail?.data[0]?.email_id);
			}

			const url = `${baseUrl}/api/get-contract-name?contact_id=${selectedClient}&isAdmin=${is_admin}&userId=${user_id}`;
			const response = await documentRequestSettingsData({ token, api: url });

			if (response.code == 200) {
				const requiredTagsData =
					response?.data === 0
						? []
						: response?.data?.map((doc) => {
							return {
								contract_request_setting_id: doc.contract_request_setting_id,
								contract_name: doc.contract_name,
								template_id: doc.template_id,
							};
						});

				setTags(requiredTagsData);
			} else {
				setTags([]);
			}
			setLoading(false);
		}
	};

	useEffect(() => {
		onLoadContractFunc();
	}, [selectedClient]);

	return (
		<>
			<div className="add-document-page">
				<div className="add-document-container" style={{ minWidth: "60%" }}>
					<div className="add-document-header">
						<div className="add-document-title-with-img">
							<img
								src={addContractTemplateImg}
								height="100px"
								width="120px"
								alt="Send Contracts for Signature"
							/>
							<div className="add-document-title">
								Send Contracts for Signature
							</div>
						</div>
						<ButtonUI
							formButton={false}
							variant="contained"
							buttonText={<AiFillCloseCircle />}
							style={{
								background: "none",
								padding: "0px",
								minWidth: "auto",
							}}
							onClickFunc={onAddContractsButtonClick}
						></ButtonUI>
					</div>
					<div className="add-document-form">
						<div className="add-document-inputs">
							<div className="add-document-inputs-with-error-message">
								<Controller
									control={control}
									name="addClientName"
									defaultValue=""
									render={({ field }) => (
										<Autocomplete
											className="clientNameAuto"
											name="addClientName"
											sx={{ margin: "10px 0px" }}
											options={clientNames?.map(
												(clientName) => clientName.name
											)}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Client Name"
													inputProps={{
														...params.inputProps,
														autoComplete: "new-password", // disable autocomplete and autofill
													}}
													InputProps={{
														...params.InputProps,
														sx: {
															p: "8px 14px", // add some vertical padding to move the label up
														},
													}}
													{...params} // spread the params object to display the label
												/>
											)}
											filterOptions={(options, state) => {
												return options.filter((option) => {
													return (
														option
															.toLowerCase()
															.indexOf(state.inputValue.toLowerCase()) > -1
													);
												});
											}}
											{...field}
											{...register("addClientName")}
											value={
												clientNames?.find(
													(clientName) => clientName.contact_id === field.value
												)?.name
											}
											onChange={(event, newValue) => {
												const selectedClient = clientNames?.find(
													(clientName) => clientName.name === newValue
												);
												if (selectedClient) {
													field.onChange(selectedClient.contact_id);
												}
											}}
										/>
									)}
								/>
								<span className="error-message">
									{errors["addClientName"]?.message}
								</span>
							</div>
							{selectedClient && email ? (
								<div className="add-document-inputs-with-error-message">
									<InputUI
										name="email_id"
										label="Enter Email ID"
										type="email"
										variant="outlined"
										cssStyles={{ margin: "10px 0px" }}
										register={register}
										value={email}
									// onChange={setEmail(e.target.value)}
									/>
									<span className="error-message">
										{errors["email_id"]?.message}
									</span>
								</div>
							) : null}
						</div>
						{selectedClient ? (
							<div className="add-document-inputs">
								<div className="tags-input-container">
									<div className="tags-input-content">
										{!loading ? (
											<>
												{tags?.map((tag, index) => (
													<div
														className="tag-item"
														key={tag?.contract_request_setting_id}
													>
														<span className="text">{tag?.contract_name}</span>
														<span
															className="close"
															onClick={() => removeTag(index)}
														>
															<RiCloseCircleFill />
														</span>
													</div>
												))}
												{/* <input
                        onKeyDown={handleKeyDown}
                        placeholder="Type here ..."
                        type="text"
                        className="tags-input"
                      /> */}
											</>
										) : (
											"Loading Document List ..."
										)}
									</div>
								</div>
							</div>
						) : null}

						<div className="add-document-button">
							<ButtonUI
								loading={loading}
								variant="contained"
								buttonText="Send Contracts for Signature"
								style={{
									width: "250px",
									height: "35px",
									color: "#fff",
									backgroundColor: "#007bff",
									boxShadow: "none",
									border: "1px solid #007bff",
									fontWeight: "500",
									borderRadius: "4px",
									letterSpacing: "0.07rem",
									marginTop: "20px",
								}}
								handleSubmit={handleSubmit}
								onFormSubmit={onAddContractFormSubmit}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddContracts;
