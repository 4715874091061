import React, { useEffect, useState, useContext } from "react";
import RequestedDocumentsList from "./client-documents/RequestedDocumentsList";

import "../../styles/CaseDocuments.css";

/*** Component for showing the client document requests and uploaded client documents ***/
const ClientDocuments = ({
	clientID,
	clientEmail,
	clientType,
	setShowClientIdSearch,
	showUploads,
	beneficiary,
	typeOptions,
	clientSubType,
	clientPhone,
}) => {
	const [selectedClientDocumentsType, setSelectedClientDocumentsType] =
		useState("All");

	console.log(clientPhone);

	const clientDocumentsType = ["All", "Recommended", "Required"];

	const changeSelectedClientDocumentsType = (e) => {
		setSelectedClientDocumentsType(e.target.value);
	};

	return (
		<div className="case-doc-content">
			<div>
				<RequestedDocumentsList
					clientID={clientID}
					clientEmail={clientEmail}
					clientType={clientType}
					clientDocumentsType={clientDocumentsType}
					selectedClientDocumentsType={selectedClientDocumentsType}
					changeSelectedClientDocumentsType={changeSelectedClientDocumentsType}
					setShowClientIdSearch={setShowClientIdSearch}
					showUploads={showUploads}
					beneficiary={beneficiary}
					typeOptions={typeOptions}
					clientSubType={clientSubType}
					clientPhone={clientPhone}
				/>
			</div>
		</div>
	);
};

export default ClientDocuments;
