import baseUrl from "../config/baseUrl";

const pdfVersion = async (token, id) => {
  const formData = new FormData();
  formData.append("document_id", id);
  try {
    const response = await fetch(`${baseUrl}/DMS/api/view_docVersion/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      // body: formData,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default pdfVersion;
