import baseUrl from "../config/baseUrl";

const editDocument = async ({
  token,
  document_id,
  document_name,
  document_desc,
  category,
  //status,
  contact_id,
  document_request_id,
  document_sub_category
}) => {
  let query_string = '';
  if (document_name !== undefined) {
    query_string += `&document_name=${document_name}`;
  }
  if (document_desc !== undefined && document_desc !== null) {
    query_string += `&document_desc=${document_desc}`;
  }
  if (category !== undefined) {
    query_string += `&category_id=${category}`;
  }
  // if (status !== undefined) {
  //   query_string += `&document_status=${status}`;
  // }
  if (contact_id !== undefined) {
    query_string += `&contact_id=${contact_id}`;
  }
  if (document_request_id !== undefined) {
    query_string += `&document_request_id=${document_request_id}`;
  }
  if (document_sub_category !== undefined) {
    query_string += `&document_sub_category=${document_sub_category}`;
  }

  try {
    const response = await fetch(
      `${baseUrl}/api/update-document-table-data?document_id=${document_id}${query_string}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default editDocument;
