// PDFViewer.jsx
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TbCircleCheck } from "react-icons/tb";
import { TiDeleteOutline } from "react-icons/ti";
import { RiCloseCircleFill } from "react-icons/ri";
import { toast } from "react-toastify";
import ButtonUI from "../../material-ui/ButtonUI";
import getClientRefNo from "../../../api/getClientRefNo";
import editDocument from "../../../api/editDocument";
import getClientName from "../../../api/getClientName";
import InputUI from "../../material-ui/InputUI";
import FormControl from "@mui/material/FormControl";
import ApprovePDFDocumentPopup from "./ApprovePDFDocumentPopup";
import RejectPDFDocumentPopup from "./RejectPDFDocumentPopup";
import LinkToRequest from "../../document-table/LinkToRequest";
import { BiLinkAlt } from "react-icons/bi";
import AddTask from "../../document-table/AddTask";
import baseUrl from "../../../config/baseUrl";
import SendSMS from "../../pdf-viewer/SendSMS";

const updateDocumentDetailsFormSchema = yup.object().shape({
  name: yup.string().required("Document Name is required"),
  subcategory: yup.string().nullable().required("Sub Category is required"),
});

const PDFViewer = ({
  clientID,
  documentType,
  pdfLink,
  pdfViewerTitle,
  setPdfViewerTitle,
  setDocumentViewer,
  docReqID,
  reqStatus,
  setReqStatus,
  setDocReqID,
  description,
  setDescription,
  category,
  subCategory,
  setSubCategory,
  clientNames,
  subCategoryList,
  token,
  user_id,
  docCaseID,
  ocrData,
  setOcrData,
  selectedRequestLabel,
  setSelectedRequestLabel,
  name,
  setDocumentUpdatedToTrue,
  clientPhone,
}) => {
  const [clientNameLabel, setClientNameLabel] = useState("");
  const [approveConfirmOpen, setApproveConfirmOpen] = useState(false);
  const [disapproveConfirmOpen, setDisapproveConfirmOpen] = useState(false);
  const [linkRequestOpen, setLinkRequestOpen] = useState(false);
  const [requestOptions, setRequestOptions] = useState([]);
  const [linkRequest, setLinkRequest] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updateDocumentDetailsFormSchema),
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    ignoreCase: true, // Ignore case sensitivity
    stringify: (option) => option.fields_name,
  });

  const handleApproveDocument = (e) => {
    setApproveConfirmOpen(true);
  };

  const handleDisapproveDocument = (e) => {
    setDisapproveConfirmOpen(true);
  };

  const handleLinkRequest = (e) => {
    setLinkRequestOpen(true);
  };

  const fetchRequestList = async (selectedClient) => {
    setLinkRequest(false);
    const url = `${baseUrl}/api/get-doc-request-data?userId=1&isAdmin=1&contact_id=${selectedClient}&unlinked=True`;
    //const url = `${baseUrl}/api/get-document-request-for-linking?userId=1&isAdmin=1&contact_id=${selectedClient}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (data?.code === 200) {
        // Create the additional item
        const additionalItem = {
          document_request_id: "Additional Document",
          document_req_name: "Additional Document",
        };

        // Add it as the first item to the data array
        const updatedData = [
          additionalItem,
          ...data?.data[0]?.alldocumentcases,
        ];

        // Set the updated data to the state
        setRequestOptions(updatedData);
      } else {
        // Set only the additional document in the state
        setRequestOptions([
          {
            document_request_id: "Additional Document",
            document_req_name: "Additional Document",
          },
        ]);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (clientID) {
      fetchRequestList(clientID);
    }
  }, [docReqID]);

  const onEditDocumentFormSubmit = async (editDocumentFormData) => {
    const editDocumentFormResponse = await editDocument({
      token: token,
      user_id: user_id,
      document_id: docCaseID,
      document_desc: description,
      document_name: editDocumentFormData.name,
      document_sub_category: editDocumentFormData.subcategory,
      contact_id: editDocumentFormData.addClientName,
    });

    if (editDocumentFormResponse.code === 200) {
      // const urlAddDocumentRequests = `${baseUrl}/api/add-document-requests?contact_id=${editDocumentFormData.addClientName}`;
      // try {
      //   const responseAddDocumentRequests = await fetch(
      //     urlAddDocumentRequests,
      //     {
      //       method: "POST",
      //       headers: {
      //         Accept: "application/json",
      //         Authorization: `Bearer ${token}`,
      //       },
      //     }
      //   );

      //   await responseAddDocumentRequests.json();
      // } catch (error) {
      //   return error;
      // }

      setPdfViewerTitle(editDocumentFormData.name);
      setDocumentUpdatedToTrue((count) => count + 1);
      toast.success("Document is updated successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
      });
    } else {
      toast.error("Error while updating document", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
      });
    }
  };

  const fetchClientRefNo = async () => {
    const clientRefNoResponse = await getClientRefNo({
      contact_id: clientID,
      token: token,
    });

    if (clientRefNoResponse.code === 200) {
      setValue("caseId", clientRefNoResponse?.data[0]?.client_reference_number);
    } else {
      toast.error(
        "Error while getting client details in DMS. Please contact admin!",
        {
          position: "top-center",
          autoClose: 5000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const fetchClientName = async () => {
    const clientNameResponse = await getClientName({
      contact_id: clientID,
      token: token,
    });
    setValue("addClientName", clientNameResponse?.data[0]?.name);
  };

  const getClientNameDetails = async () => {
    const clientNameResponse = await getClientName({
      contact_id: clientID,
      token: token,
    });
    setClientNameLabel(clientNameResponse?.data[0]?.name);
  };

  useEffect(() => {
    setValue("name", name);
    fetchClientRefNo();
    fetchClientName();
    getClientNameDetails();
  }, [clientID, token, setValue]);

  return (
    <>
      {approveConfirmOpen && (
        <ApprovePDFDocumentPopup
          documentRequestId={docReqID}
          setReqStatus={setReqStatus}
          setApproveConfirmOpen={setApproveConfirmOpen}
        />
      )}
      {disapproveConfirmOpen && (
        <RejectPDFDocumentPopup
          documentRequestId={docReqID}
          setReqStatus={setReqStatus}
          setDisapproveConfirmOpen={setDisapproveConfirmOpen}
        />
      )}

      {linkRequestOpen && (
        <LinkToRequest
          documentId={docCaseID}
          requestOptions={requestOptions}
          setLinkRequest={setLinkRequest}
          setReqStatus={setReqStatus}
          setRequestId={setDocReqID}
          setLinkRequestOpen={setLinkRequestOpen}
          setSelectedRequestLabel={setSelectedRequestLabel}
        />
      )}
      <div className="all-document-pdf-viewer">
        <div className="all-document-pdf-viewer-header pb-3">
          <div className="capitalize all-document-pdf-viewer-title">
            {pdfViewerTitle}
            {selectedRequestLabel
              ? " - " + selectedRequestLabel + " (Linked Request)"
              : null}
          </div>
          <div className="document-pdf-actions">
            {clientID && <AddTask contactId={clientID} />}
            {clientID && clientPhone ? (
              <SendSMS clientPhone={clientPhone} clientID={clientID} />
            ) : null}
            {docReqID && reqStatus?.toLowerCase() === "approved" && (
              <div
                className="all-document-pdf-viewer-icon !cursor-default"
                style={{ color: "green", fontSize: 26 }}
                title="Approved"
              >
                <TbCircleCheck />
              </div>
            )}
            {docReqID && reqStatus?.toLowerCase() === "rejected" && (
              <div
                className="all-document-pdf-viewer-icon !cursor-default"
                style={{ color: "red", fontSize: 29 }}
                title="Rejected"
              >
                <TiDeleteOutline />
              </div>
            )}

            <ButtonUI
              formButton={false}
              variant="outlined"
              buttonText={<TbCircleCheck />}
              style={{
                backgroundColor:
                  reqStatus?.toLowerCase() === "uploaded"
                    ? "#28a745"
                    : "#d8d8e4df",

                color:
                  reqStatus?.toLowerCase() === "uploaded"
                    ? "white"
                    : "rgb(18 215 24)",
                marginRight: "5px",
                marginBottom: "5px",
                padding: "8px",
                minWidth: "fit-content",
                border: "none",
              }}
              disabled={reqStatus?.toLowerCase() === "uploaded" ? false : true}
              onClickFunc={handleApproveDocument}
            />
            <ButtonUI
              formButton={false}
              variant="outlined"
              buttonText={<TiDeleteOutline />}
              style={{
                backgroundColor:
                  reqStatus?.toLowerCase() === "uploaded"
                    ? "#dc3545"
                    : "#d8d8e4df",

                color:
                  reqStatus?.toLowerCase() === "uploaded"
                    ? "white"
                    : "rgb(215 18 18)",
                marginRight: "5px",
                marginBottom: "5px",
                padding: "8px",
                minWidth: "fit-content",
                border: "none",
              }}
              disabled={reqStatus?.toLowerCase() === "uploaded" ? false : true}
              onClickFunc={handleDisapproveDocument}
            />
            <ButtonUI
              formButton={false}
              variant="outlined"
              buttonText={<BiLinkAlt />}
              style={{
                backgroundColor: !docReqID ? "#17a2b8" : "#d8d8e4df",
                color: !docReqID ? "white" : "rgb(18 215 24)",
                marginRight: "5px",
                marginBottom: "5px",
                padding: "8px",
                minWidth: "fit-content",
                border: "none",
              }}
              disabled={!docReqID ? false : true}
              onClickFunc={handleLinkRequest}
            />

            <div
              className="all-document-pdf-viewer-icon"
              title="Close Document"
              onClick={() => setDocumentViewer(false)}
            >
              <RiCloseCircleFill />
            </div>
          </div>
        </div>
        <div className="document-viewer-with-from">
          <div className="iframe-wrapper">
            <iframe
              title={pdfViewerTitle}
              src={pdfLink}
              width="100%"
              height="100%"
            ></iframe>
          </div>

          <div className="document-viewer-form">
            <div className="field_wrapper">
              <div className="field">
                <Controller
                  control={control}
                  name="addClientName"
                  disabled
                  render={({ field }) => (
                    <Autocomplete
                      className="clientNameAuto"
                      name="addClientName"
                      options={clientNames?.map(
                        (clientName) => clientName.name
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            !clientNameLabel ? "Client Name" : clientNameLabel
                          }
                          InputProps={{
                            ...params.InputProps,
                          }}
                          {...params} // spread the params object to display the label
                        />
                      )}
                      // filterOptions={(options, state) => {
                      //   return options.filter((option) => {
                      //     return (
                      //       option
                      //         .toLowerCase()
                      //         .indexOf(state.inputValue.toLowerCase()) > -1
                      //     );
                      //   });
                      // }}
                      {...field}
                      {...register("addClientName")}
                      value={
                        clientNames?.find(
                          (clientName) => clientName.contact_id === field.value
                        )?.name
                      }
                      //   onChange={(event, newValue) => {
                      //     const selectedClient = clientNames?.find(
                      //       (clientName) => clientName.name === newValue
                      //     );
                      //     if (selectedClient) {
                      //       field.onChange(selectedClient.contact_id);
                      //     }
                      //   }}
                    />
                  )}
                />
                <span className="error-message">
                  {errors["addClientName"]?.message}
                </span>
              </div>
            </div>
            <div className="field_wrapper">
              <div className="field">
                <Controller
                  name="caseId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputUI
                      disabled
                      name="caseId"
                      label="Client Reference Number"
                      type="text"
                      value={value ? value : ""}
                      setValue={onChange}
                      variant="outlined"
                      register={register}
                      cssStyles={{
                        width: "100%",
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="field_wrapper">
              <div className="field">
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputUI
                      name="name"
                      label="Document Name"
                      type="text"
                      value={value ? value : ""}
                      setValue={onChange}
                      variant="outlined"
                      register={register}
                      cssStyles={{
                        margin: "20px 0px",
                        width: "100%",
                      }}
                    />
                  )}
                />
                <span className="error-message">{errors["name"]?.message}</span>
              </div>
            </div>
            <div className="field_wrapper">
              <div className="field">
                <Controller
                  name="category"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputUI
                      name="category"
                      label="Category"
                      type="text"
                      value={category ? category : ""}
                      disabled
                      variant="outlined"
                      register={register}
                      cssStyles={{
                        marginBottom: "20px",
                        width: "100%",
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="field_wrapper">
              <FormControl className="" fullWidth>
                <Controller
                  control={control}
                  name="subcategory"
                  defaultValue={
                    subCategory && subCategory !== "null" ? subCategory : ""
                  }
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      options={subCategoryList}
                      getOptionLabel={(option) => option.fields_name || ""}
                      filterOptions={filterOptions}
                      onChange={(e, value) => {
                        onChange(value ? value.field_setting_id : "");
                        if (value) {
                          setSubCategory(value.field_setting_id);
                        } else {
                          setSubCategory("");
                        }
                      }}
                      value={
                        subCategoryList?.find(
                          (option) => option.field_setting_id === subCategory
                        ) || null
                      }
                      sx={{ margin: "10px 0px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sub Category"
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
              {/* {subCategory}
                <FormControl fullWidth>
                  <InputLabel id="select-label">Sub Category</InputLabel>
                  <Controller
                    control={control}
                    name="subcategory"
                    defaultValue={subCategory}
                    render={({ field: { onChange, value } }) => (
                      <SelectBox
                        name="subcategory"
                        labelId="select-label"
                        variant="outlined"
                        id="simple-select"
                        value={subCategory ? subCategory : value}
                        onChange={onChange}
                        label="Sub Category"
                        {...register("subcategory")}
                      >
                        {subCategoryList?.map((category) => (
                          <MenuItem
                            key={category.field_setting_id}
                            value={category.field_setting_id}
                          >
                            {category.fields_name}
                          </MenuItem>
                        ))}
                      </SelectBox>
                    )}
                  />
                </FormControl>*/}
              <span className="error-message">
                {errors["subcategory"]?.message}
              </span>
            </div>
            <div className="field_wrapper">
              <div className="field">
                <TextField
                  name="description"
                  label="Description"
                  multiline
                  minRows={3}
                  maxRows={3}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  variant="outlined"
                  style={{ marginTop: "15px", marginBottom: "15px" }}
                  fullWidth
                />
              </div>
            </div>
            <ButtonUI
              variant="contained"
              buttonText="Submit"
              handleSubmit={handleSubmit}
              onFormSubmit={onEditDocumentFormSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PDFViewer;
