import baseUrl from "../config/baseUrl";

const editClient = async ({
	token,
	name,
	description,
	contact_id,
	addClientType,
	addClientSubType,
	addClientStatus,
	client_reference_number,
	phone_number,
	email_id,
}) => {
	let queryString = "";
	if (addClientSubType) {
		queryString = `&sub_type=${addClientSubType}`;
	}
	try {
		const response = await fetch(
			`${baseUrl}/api/update-client-table-data?contact_id=${contact_id}&name=${name}&description=${description}&type=${addClientType}&status=${addClientStatus}&client_reference_number=${client_reference_number}&phone_number=${phone_number}&email_id=${email_id}${queryString}`,
			{
				method: "PUT",
				headers: {
					Accept: "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		);
		const data = await response.json();
		return data;
	} catch (error) {
		return error;
	}
};

export default editClient;
