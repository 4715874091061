import baseUrl from "../config/baseUrl";

const pdfDetails = async (token, documentID, document_table, document_version_id) => {
  // const formData = new FormData();
  // formData.append("document_id", documentID);

  let document_version_params = '';
  if (document_version_id && document_version_id !== 'undefined') {
    document_version_params = `&document_version_id=${document_version_id}`;
  }

  try {
    const response = await fetch(
      `${baseUrl}/api/view-document-req-url-link?document_id=${documentID}&document_table=${document_table}${document_version_params}`,

      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        // body: formData,
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default pdfDetails;
