import baseUrl from "../config/baseUrl";

const removeDocumentFromFavourite = async ({ token, ids }) => {
  console.log(ids);
  try {
    const response = await fetch(
      `${baseUrl}/api/remove-bulk-all-favourite?ids=${ids}`,

      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default removeDocumentFromFavourite;
