import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MdModeEdit } from "react-icons/md";
import { RiCloseCircleFill } from "react-icons/ri";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import EditFieldSettingsForm from "./EditFieldSettingsForm";
import ButtonUI from "../material-ui/ButtonUI";
import { AuthContext } from "../../context/Auth/AuthContext";
import editFieldSettings from "../../api/editFieldSettings";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <RiCloseCircleFill />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const editDocumentFormSchema = yup.object().shape({
  fieldName: yup.string().required("Field Name is a required field"),
  description: yup.string().required("Description is a required field"),
  status: yup.string().required("Status is a required field"),
  fieldsTabsType: yup.string().required("Field Tab Type is a required field"),
});

const EditFieldSettingsPopup = ({
  setFieldSettingsAddedToTrue,
  tableValues,
  clientTypeOptions,
}) => {
  const [open, setOpen] = useState(false);
  const { token, user_id, is_admin } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(editDocumentFormSchema),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onEditDocumentFormSubmit = async (editFieldSettingsFormData) => {
    const ocrData = tags.reduce((acc, current) => {
      acc[current.ocr_key] = "";
      return acc;
    }, {});

    const ocrDataString = JSON.stringify(ocrData);

    setLoading(true);
    const editDocumentFormResponse = await editFieldSettings({
      token: token,
      user_id: user_id,
      field_setting_id: tableValues.field_setting_id,
      fields_name: editFieldSettingsFormData.fieldName,
      fields_description: editFieldSettingsFormData.description,
      status: editFieldSettingsFormData.status,
      fields_tab_type: editFieldSettingsFormData.fieldsTabsType,
      is_admin: is_admin,
      category_id: editFieldSettingsFormData.addClientType,
      ocr_data: ocrDataString,
    });

    if (editDocumentFormResponse.code === 200) {
      setFieldSettingsAddedToTrue();
      setOpen(false);
      setLoading(false);

      toast.success("Field Settings updated successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      toast.error("Error while updating Field Settings", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="dialog-modal">
      <ButtonUI
        formButton={false}
        variant="outlined"
        buttonText={<MdModeEdit />}
        style={{
          backgroundColor: "#17a2b8",
          color: " white",
          marginRight: "5px",
          padding: "8px",
          minWidth: "fit-content",
          border: "none",
        }}
        onClickFunc={handleClickOpen}
      />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        className="custom-edit-modal"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          className="edit-doc-modal"
          onClose={handleClose}
        >
          <div className="popup-header-svg-icon">
            <MdModeEdit />
          </div>
          <div className="popup-header-title">Edit Field Settings</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <EditFieldSettingsForm
            register={register}
            control={control}
            setValue={setValue}
            watch={watch}
            errors={errors}
            tableValues={tableValues}
            clientTypeOptions={clientTypeOptions}
            tags={tags}
            setTags={setTags}
          />
        </DialogContent>
        <DialogActions sx={{ paddingBottom: "15px !important" }}>
          <div className="popup_submit_btn">
            <ButtonUI
              loading={loading}
              variant="contained"
              buttonText="Submit"
              style={{
                width: "150px",
                height: "35px",
                backgroundColor: "#007bff",
                boxShadow: "none",
                border: "1px solid #007bff",
                fontWeight: "500",
                borderRadius: "4px",
                letterSpacing: "0.07rem",
                marginTop: "20px",
              }}
              handleSubmit={handleSubmit}
              onFormSubmit={onEditDocumentFormSubmit}
            />
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default EditFieldSettingsPopup;
