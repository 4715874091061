const getOcrAnswer = async ({ api, token, ocrKeys, file }) => {
	console.log(ocrKeys);
	try {
		let questions = {};

		for (const key in ocrKeys) {
			if (ocrKeys.hasOwnProperty(key)) {
				questions[key] = `What is the ${key.split("_").join(" ")}?`;
			}
		}

		const formData = new FormData();

		formData.append("file", file);
		formData.append("questions", JSON.stringify(questions));

		const response = await fetch(api, {
			method: "POST",
			headers: {
				Accept: "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: formData,
		});

		const data = await response.json();

		return data;
	} catch (error) {
		return error;
	}
};

export default getOcrAnswer;
