import React, { useState, useContext, useEffect } from "react";
import { Controller } from "react-hook-form";
import { createFilterOptions, Grid, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../material-ui/InputUI";
import Autocomplete from "@mui/material/Autocomplete";
import { AuthContext } from "../../context/Auth/AuthContext";
import getAllSubTypeList from "../../api/getAllSubTypeList";
import getAllServiceList from "../../api/getAllServiceList";
import baseUrl from "../../config/baseUrl";

/*** Component for listing all the form fields for editing the Document Request Settings details ***/
const EditDocumentRequestSettingsForm = ({
  register,
  control,
  errors,
  setValue,
  watch,
  tableValues,
  typeOptions,
}) => {
  const [subTypeOptions, setSubTypeOptions] = useState([]);
  const [services, setServices] = useState([]);
  const [defaultValues, setDefaultValues] = useState({
    services: tableValues.service_sub_type_c,
    client_type: tableValues.client_type,
    client_subtype: tableValues.service_sub_type_c,
  });

  const { token } = useContext(AuthContext);

  const statusOptions = [
    {
      name: "Active",
      value: "active",
    },
    {
      name: "Inactive",
      value: "inactive",
    },
  ];

  const requestOptions = [
    {
      name: "Required",
      value: "required",
    },
    {
      name: "Recommended",
      value: "recommended",
    },
  ];

  const ocrStatusOptions = [
    {
      name: "True",
      value: "True",
    },
    {
      name: "False",
      value: "False",
    },
  ];

  const selectedClientType = watch("clientType");

  const clientSubTypeLoad = async (clientType) => {
    const typeId = clientType;
    const data = await getAllSubTypeList({ token, typeId });
    if (data.code == 200) {
      const filteredTypeList =
        data?.data !== null
          ? data?.data.map((type) => {
              return {
                label: type.fields_name,
                typeId: type.field_setting_id,
              };
            })
          : [];
      filteredTypeList.unshift({ label: "None", typeId: "None" });
      setSubTypeOptions(filteredTypeList);
    } else {
      setSubTypeOptions([]);
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    ignoreCase: true, // Ignore case sensitivity
    stringify: (option) => option.label,
  });

  const fetchAllServices = async () => {
    const url = `${baseUrl}/api/get-document-stages`;
    try {
      const res = await getAllServiceList({ api: url, token });
      const stages = res.document_stages.map((stage) => ({
        label: stage.document_stage,
        id: stage.service_sub_type_c,
      }));
      setServices(stages);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (
      selectedClientType !== undefined &&
      selectedClientType !== tableValues.client_type
    ) {
      setValue("clientSubType", null);
      clientSubTypeLoad(selectedClientType);
    }
    if (
      (selectedClientType === undefined &&
        tableValues.client_type !== undefined) ||
      selectedClientType === tableValues.client_type
    ) {
      clientSubTypeLoad(tableValues.client_type);
    }
  }, [selectedClientType]);

  useEffect(() => {
    fetchAllServices();
  }, []);

  console.log(tableValues);

  return (
    <Grid className="">
      <div className="edit-document-form grid grid-cols-1 gap-3 sm:grid-cols-2">
        <div className="field_wrapper">
          <div className="field">
            <div className="input-field">
              <Controller
                name="documentName"
                defaultValue={tableValues.document_name}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputUI
                    name="documentName"
                    label="Document Name"
                    type="text"
                    value={value ? value : ""}
                    setValue={onChange}
                    variant="outlined"
                    register={register}
                  />
                )}
              />
            </div>
            <span className="error-message">
              {errors["documentName"]?.message}
            </span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <div className="input-field">
              <Controller
                name="description"
                defaultValue={tableValues.document_desc || ""}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputUI
                    name="description"
                    label="Description"
                    type="text"
                    value={value ? value : ""}
                    setValue={onChange}
                    variant="outlined"
                    register={register}
                  />
                )}
              />
            </div>
            <span className="error-message">
              {errors["description"]?.message}
            </span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <FormControl className="" fullWidth>
              <InputLabel className="edit-doc-select-label" id="select-label">
                Request Type
              </InputLabel>
              <Controller
                control={control}
                name="requestType"
                defaultValue={tableValues.request_type?.toLowerCase()}
                render={({ field: { onChange, value } }) => (
                  <Select
                    name="requestType"
                    labelId="select-label"
                    variant="outlined"
                    id="simple-select"
                    value={value}
                    onChange={onChange}
                    label="Request Type"
                    style={{ marginBottom: "25px" }}
                    {...register("requestType")}
                  >
                    {requestOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <span className="error-message">
              {errors["requestType"]?.message}
            </span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <FormControl className="" fullWidth>
              <Controller
                control={control}
                name="stage"
                defaultValue={tableValues.service_sub_type_c}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    options={services}
                    filterOptions={filterOptions}
                    getOptionLabel={(option) => {
                      // Find all services with the same label
                      const duplicates = services.filter(
                        (item) => item.label === option.label
                      );

                      // Find the index of the current option in the list of duplicates
                      const index = duplicates.indexOf(option);

                      // Return the label with a sequential number for duplicates
                      return duplicates.length > 1
                        ? `${option.label} (${index + 1})`
                        : option.label;
                    }}
                    onChange={(e, selectedOption) => {
                      // Update the form control value
                      onChange(selectedOption ? selectedOption.label : "");

                      // Call setDefaultService with the selectedOption.id
                      if (selectedOption) {
                        setDefaultValues({
                          ...defaultValues,
                          services: selectedOption.id,
                        });
                      }
                    }}
                    value={
                      services?.find(
                        (option) => option.id === defaultValues.services
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Service"
                        variant="outlined"
                      />
                    )}
                  />
                )}
              />
            </FormControl>
            <span className="error-message">{errors["stage"]?.message}</span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <FormControl className="" fullWidth>
              <Controller
                control={control}
                name="clientType"
                defaultValue={tableValues.client_type}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    options={typeOptions}
                    style={{ marginBottom: "25px" }}
                    filterOptions={filterOptions}
                    getOptionLabel={(option) => option.label || ""}
                    onChange={(e, selectedOption) => {
                      // Update the form control value
                      onChange(selectedOption ? selectedOption.typeId : "");

                      // Call setDefaultService with the selectedOption.id
                      if (selectedOption) {
                        setDefaultValues({
                          ...defaultValues,
                          client_type: selectedOption.typeId,
                        });
                      }
                    }}
                    value={
                      typeOptions?.find(
                        (option) => option.typeId === defaultValues.client_type
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Client Type"
                        variant="outlined"
                      />
                    )}
                  />
                )}
              />
            </FormControl>
            <span className="error-message">
              {errors["clientType"]?.message}
            </span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <FormControl className="" fullWidth>
              <Controller
                control={control}
                name="clientSubType"
                defaultValue={tableValues.service_sub_type_c}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    options={subTypeOptions}
                    getOptionLabel={(option) => option.label || ""}
                    filterOptions={filterOptions}
                    style={{ marginBottom: "25px" }}
                    onChange={(e, selectedOption) => {
                      // Update the form control value
                      onChange(selectedOption ? selectedOption.typeId : "");

                      // Call setDefaultService with the selectedOption.id
                      if (selectedOption) {
                        setDefaultValues({
                          ...defaultValues,
                          client_subtype: selectedOption.typeId,
                        });
                      }
                    }}
                    value={
                      subTypeOptions?.find(
                        (option) =>
                          option.typeId === defaultValues.client_subtype
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Client Sub Type"
                        variant="outlined"
                      />
                    )}
                  />
                )}
              />
            </FormControl>
            <span className="error-message">
              {errors["clientSubType"]?.message}
            </span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <FormControl className="" fullWidth>
              <InputLabel className="edit-doc-select-label" id="select-label">
                OCR Status
              </InputLabel>
              <Controller
                control={control}
                name="ocrStatus"
                defaultValue={
                  tableValues.ocr_status.toLowerCase() === "true"
                    ? "True"
                    : "False"
                }
                render={({ field: { onChange, value } }) => (
                  <Select
                    name="ocrStatus"
                    labelId="select-label"
                    variant="outlined"
                    id="simple-select"
                    value={value}
                    onChange={onChange}
                    style={{ marginBottom: "25px" }}
                    label="OCR Status"
                    {...register("ocrStatus")}
                  >
                    {ocrStatusOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <span className="error-message">
              {errors["ocrStatus"]?.message}
            </span>
          </div>
        </div>
        <div className="field_wrapper">
          <div className="field">
            <FormControl className="" fullWidth>
              <InputLabel className="edit-doc-select-label" id="select-label">
                Status
              </InputLabel>
              <Controller
                control={control}
                name="status"
                defaultValue={tableValues.status.toLowerCase()}
                render={({ field: { onChange, value } }) => (
                  <Select
                    name="status"
                    labelId="select-label"
                    variant="outlined"
                    id="simple-select"
                    value={value}
                    onChange={onChange}
                    label="Status"
                    {...register("status")}
                  >
                    {statusOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            <span className="error-message">{errors["status"]?.message}</span>
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default EditDocumentRequestSettingsForm;
