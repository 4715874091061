import React, { useContext, useEffect, useState } from "react";
import getBeneficiary from "../../../../api/getBeneficiary";
import { AuthContext } from "../../../../context/Auth/AuthContext";
import {
	CaretSortIcon,
	ChevronDownIcon,
	EyeOpenIcon,
	MixerHorizontalIcon,
} from "@radix-ui/react-icons";

import {
	ColumnDef,
	ColumnFiltersState,
	SortingState,
	VisibilityState,
	getFacetedRowModel,
	getFacetedUniqueValues,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";

import { Button } from "../../../../shadcn-components/ui/button";
import { Checkbox } from "../../../../shadcn-components/ui/checkbox";
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "../../../../shadcn-components/ui/dropdown-menu";
import { Input } from "../../../../shadcn-components/ui/input";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
	TooltipProvider,
} from "../../../../shadcn-components/ui/tooltip";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "../../../../shadcn-components/ui/table";

import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../../../../shadcn-components/ui/select";

import PaginationSection from "../../../../components/common/PaginationSection";
import TableSkeleton from "../../../../components/common/TableSkeleton";
import EditBeneficiaryPopup from "../../EditBeneficiaryPopup";
import DeleteBeneficiaryPopup from "./DeleteBeneficiaryPopup";
import { useDebounce } from "use-debounce";
import baseUrl from "../../../../config/baseUrl";

const BeneficiaryTable = ({
	clientID,
	beneficiaryAdded,
	setBeneficiaryAdded,
	typeOptions,
}) => {
	const { token, user_id, is_admin } = useContext(AuthContext);
	const [beneficiaryData, setBeneficiaryData] = useState([]);
	const [sorting, setSorting] = useState([]);
	const [columnFilters, setColumnFilters] = useState([]);
	const [columnVisibility, setColumnVisibility] = useState({});
	const [rowSelection, setRowSelection] = useState({});
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalPages, setTotalPages] = useState(1);
	const [globalFilter, setGlobalFilter] = useState("");
	const [debouncedGlobalFilter] = useDebounce(globalFilter, 1000);

	const [showFilterButton, setShowFilterButton] = useState(false);
	const [isBeneficiaryEdited, setIsBeneficiaryEdited] = useState(false);
	const [isBeneficiaryDeleted, setIsBeneficiaryDeleted] = useState(false);

	const columns = [
		{
			accessorKey: "name",
			filterable: false,
			displayName: "Name",
			header: ({ column }) => (
				<Button variant="ghost" className="p-0 !cursor-default">
					Name
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize text-left">{row.getValue("name")}</div>
			),
			enableHiding: false,
			// filterFn: (row, id, value) => {
			// 	return value.includes(row.getValue(id));
			// },

			enableSorting: false,
		},

		{
			accessorKey: "phone_number",
			displayName: "Phone Number",
			enableSorting: false,
			header: ({ column }) => (
				<Button variant="ghost" className="p-0 !cursor-default">
					Phone Number
				</Button>
			),
			cell: ({ row }) => (
				<div className="text-left">{row.getValue("phone_number")}</div>
			),
			// filterFn: (row, id, value) => {
			// 	return value.includes(row.getValue(id));
			// },
		},
		{
			accessorKey: "email_id",
			displayName: " Email Id",
			enableSorting: false,
			header: ({ column }) => (
				<Button variant="ghost" className="p-0 !cursor-default">
					Email Id
				</Button>
			),
			cell: ({ row }) => (
				<div className="text-left">{row.getValue("email_id")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "fields_name",
			displayName: "Client Category",
			enableSorting: false,
			header: ({ column }) => (
				<Button variant="ghost" className="p-0 !cursor-default">
					Client Category
				</Button>
			),
			cell: ({ row }) => (
				<div className="text-left">{row.getValue("fields_name")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "subtype_fields_name",
			displayName: "Client Sub Category",
			enableSorting: false,
			header: ({ column }) => (
				<Button variant="ghost" className="p-0 !cursor-default">
					Client Sub Category
				</Button>
			),
			cell: ({ row }) => (
				<div className="text-left">{row.getValue("subtype_fields_name")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			id: "actions",
			displayName: "Actions",
			enableHiding: false,
			header: ({ column }) => (
				<Button variant="ghost" className="p-0 !cursor-default">
					Actions
				</Button>
			),
			cell: ({ row }) => {
				return (
					<div className="flex gap-2">
						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger>
									<EditBeneficiaryPopup
										tableValues={row.original}
										setIsBeneficiaryEdited={setIsBeneficiaryEdited}
									/>
								</TooltipTrigger>
								<TooltipContent className="bg-gray-600">
									<p>Edit Beneficiary</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
						{/* <TooltipProvider>
							<Tooltip>
								<TooltipTrigger>
									<DeleteBeneficiaryPopup
										data={row.original}
										setIsBeneficiaryDeleted={setIsBeneficiaryDeleted}
									/>
								</TooltipTrigger>
								<TooltipContent className="bg-gray-600">
									<p>Delete Beneficiary</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider> */}
					</div>
				);
			},
		},
	];

	const fetchBeneficiary = async () => {
		setLoading(true);
		const url = `${baseUrl}/api/get-client-link-data?contact_id=${clientID}`;
		try {
			const response = await getBeneficiary({ api: url, token });
			setBeneficiaryData(response.client_link_contacts);
			setBeneficiaryAdded(false);
			setLoading(false);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		fetchBeneficiary();
	}, [beneficiaryAdded, isBeneficiaryEdited, isBeneficiaryDeleted]);

	const table = useReactTable({
		data: beneficiaryData,
		columns,
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		getCoreRowModel: getCoreRowModel(),
		initialState: {
			pageSize: 20,
		},
		manualPagination: true,
		manualSorting: true,
		getSortedRowModel: getSortedRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFilteredRowModel: getFilteredRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		onRowSelectionChange: setRowSelection,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
			rowSelection,
			pageSize,
		},

		globalFilterFn: (rows, columnFilters) => {
			const [globalFilter] = columnFilters.filter(
				(filter) => filter.id === "global"
			);
			if (!globalFilter || !globalFilter.value) return rows;

			return rows.filter((row) => {
				const name = row.original.name.toLowerCase(); // Assuming 'name' is the column key
				return name.includes(globalFilter.value.toLowerCase());
			});
		},
		onGlobalFilterChange: (filterValue) => {
			setGlobalFilter(filterValue); // Update the global filter state
		},
	});

	return (
		<div className="documents-content">
			<div className="document__table mt-3">
				<div className="w-full bg-white px-2">
					{loading ? (
						<TableSkeleton />
					) : (
						<Table>
							<TableHeader className="pb-4 sticky top-0 bg-white z-[1]">
								{table.getHeaderGroups().map((headerGroup) => (
									<TableRow key={headerGroup.id}>
										{headerGroup.headers.map((header) => {
											return (
												<TableHead key={header.id}>
													{header.isPlaceholder
														? null
														: flexRender(
																header.column.columnDef.header,
																header.getContext()
														  )}
												</TableHead>
											);
										})}
									</TableRow>
								))}
							</TableHeader>
							<TableBody>
								{table.getRowModel().rows?.length ? (
									table.getRowModel().rows.map((row) => (
										<TableRow
											key={row.id}
											data-state={row.getIsSelected() && "selected"}
										>
											{row.getVisibleCells().map((cell) => (
												<TableCell key={cell.id}>
													{flexRender(
														cell.column.columnDef.cell,
														cell.getContext()
													)}
												</TableCell>
											))}
										</TableRow>
									))
								) : (
									<TableRow>
										<TableCell
											colSpan={columns.length}
											className="h-24 text-center"
										>
											{loading ? <TableSkeleton /> : "no records found"}
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					)}
				</div>
			</div>
		</div>
	);
};

export default BeneficiaryTable;
