import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AiFillCloseCircle } from "react-icons/ai";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../material-ui/InputUI";
import ButtonUI from "../material-ui/ButtonUI";
import { AuthContext } from "../../context/Auth/AuthContext";
import addClient from "../../api/addClient";
import addUserImg from "../../images/add-user.png";
import getAllSubTypeList from "../../api/getAllSubTypeList";
import "../../styles/AddDocument.css";

const addClientFormSchema = yup.object().shape({
  name: yup.string().required("Name is required field"),
  description: yup.string().required("Description is required field"),
  email_id: yup
    .string()
    .email("Email must be valid")
    .required("Email is required field"),
  phone_number: yup.string().required("Phone Number is required field"),
  addClientStatus: yup.string().required("Status is required field"),
  addClientType: yup.string().required("Type is required field"),
  client_reference_number: yup
    .string()
    .required("Reference Number is required field"),
});

/*** Component for adding the client ***/
const AddClient = ({
  typeOptions,
  onAddClientButtonClick,
  setClientAddedToTrue,
  // subTypeOptions,
  // clientSubTypeLoad
}) => {
  const [loading, setLoading] = useState(false);
  const { token, id } = useContext(AuthContext);
  const [subTypeOptions, setSubTypeOptions] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addClientFormSchema),
  });

  const selectedClientType = watch("addClientType");

  const clientSubTypeLoad = async (selectedClientType) => {
    const typeId = selectedClientType;
    const data = await getAllSubTypeList({ token, typeId });
    if (data.code == 200) {
      const filteredTypeList =
        data?.data !== null
          ? data?.data.map((type) => {
              return {
                label: type.fields_name,
                typeId: type.field_setting_id,
              };
            })
          : [];
      filteredTypeList.unshift({ label: "None", typeId: "None" });
      console.log(data?.data);
      setSubTypeOptions(filteredTypeList);
    } else {
      setSubTypeOptions([]);
    }
  };

  const statusOptions = [
    {
      name: "Active",
      value: "active",
    },
    {
      name: "Inactive",
      value: "inactive",
    },
  ];

  const onAddClientFormSubmit = async (addClientFormData) => {
    setLoading(true);

    const addClientFormResponse = await addClient({
      token: token,
      name: addClientFormData.name,
      description: addClientFormData.description,
      email_id: addClientFormData.email_id,
      type: addClientFormData.addClientType,
      sub_type: addClientFormData.addClientSubType,
      phone_number: addClientFormData.phone_number,
      client_reference_number: addClientFormData.client_reference_number,
      status: addClientFormData.addClientStatus,
      created_by: id,
    });

    if (addClientFormResponse?.code === 200) {
      onAddClientButtonClick();
      setClientAddedToTrue();
      toast.success("Client is added successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (addClientFormResponse?.code === 400) {
      toast.error("Email Already Exists", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (addClientFormResponse?.code === 401) {
      toast.error("Unauthorized access", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Error while adding client", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedClientType !== undefined) {
      clientSubTypeLoad(selectedClientType);
    }
  }, [selectedClientType]);

  return (
    <div className="add-document-page">
      <div className="add-document-container">
        <div className="add-document-header">
          <div className="add-document-title-with-img">
            <img
              src={addUserImg}
              height="100px"
              width="120px"
              alt="Add Client"
            />
            <div className="add-document-title">Add New Client</div>
          </div>
          <ButtonUI
            formButton={false}
            variant="contained"
            buttonText={<AiFillCloseCircle />}
            style={{
              background: "none",
              padding: "0px",
              minWidth: "auto",
            }}
            onClickFunc={onAddClientButtonClick}
          ></ButtonUI>
        </div>
        <div className="add-document-form">
          <div className="add-document-inputs">
            <div className="add-document-inputs-with-error-message">
              <InputUI
                name="name"
                label="Enter Name"
                type="text"
                variant="outlined"
                cssStyles={{
                  margin: "10px 0px",
                }}
                register={register}
              />
              <span className="error-message">{errors["name"]?.message}</span>
            </div>
            <div className="add-document-inputs-with-error-message">
              <InputUI
                name="client_reference_number"
                label="Enter Reference Number"
                type="text"
                variant="outlined"
                cssStyles={{
                  margin: "10px 0px",
                }}
                register={register}
              />
              <span className="error-message">
                {errors["client_reference_number"]?.message}
              </span>
            </div>
          </div>
          <div className="add-document-inputs">
            <div
              className="add-document-inputs-with-error-message"
              style={{ width: "100%" }}
            >
              <InputUI
                name="description"
                label="Enter Description"
                type="text"
                variant="outlined"
                cssStyles={{ margin: "10px 0px" }}
                register={register}
              />
              <span className="error-message">
                {errors["description"]?.message}
              </span>
            </div>
          </div>
          <div className="add-document-inputs">
            <div className="add-document-inputs-with-error-message">
              <InputUI
                name="phone_number"
                label="Enter Phone Number"
                type="text"
                variant="outlined"
                cssStyles={{ margin: "10px 0px" }}
                register={register}
              />
              <span className="error-message">
                {errors["phone_number"]?.message}
              </span>
            </div>
            <div className="add-document-inputs-with-error-message">
              <InputUI
                name="email_id"
                label="Enter Email ID"
                type="email"
                variant="outlined"
                cssStyles={{ margin: "10px 0px" }}
                register={register}
              />
              <span className="error-message">
                {errors["email_id"]?.message}
              </span>
            </div>
          </div>
          <div className="add-document-inputs">
            <div className="add-document-inputs-with-error-message select-ui">
              <FormControl fullWidth>
                <InputLabel id="select-label">Client Category</InputLabel>
                <Controller
                  control={control}
                  name="addClientType"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="addClientType"
                      sx={{ margin: "10px 0px" }}
                      labelId="select-label"
                      variant="outlined"
                      id="simple-select"
                      value={value}
                      onChange={onChange}
                      label="Client Category"
                      {...register("addClientType")}
                    >
                      {typeOptions?.map((option) => (
                        <MenuItem key={option.typeId} value={option.typeId}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <span className="add-doc-error-message">
                {errors["addClientType"]?.message}
              </span>
            </div>
            <div className="add-document-inputs-with-error-message select-ui">
              <FormControl fullWidth>
                <InputLabel id="select-label">Client Sub Category</InputLabel>
                <Controller
                  control={control}
                  name="addClientSubType"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="addClientSubType"
                      sx={{ margin: "10px 0px" }}
                      labelId="select-label"
                      variant="outlined"
                      id="simple-select"
                      value={value}
                      onChange={onChange}
                      label="Client Sub Category"
                      {...register("addClientSubType")}
                    >
                      {subTypeOptions?.map((option) => (
                        <MenuItem key={option.typeId} value={option.typeId}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </div>
          </div>
          <div className="add-document-inputs">
            <div className="add-document-inputs-with-error-message select-ui">
              <FormControl fullWidth>
                <InputLabel id="select-label">Status</InputLabel>
                <Controller
                  control={control}
                  name="addClientStatus"
                  defaultValue="active"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="addClientStatus"
                      sx={{ margin: "10px 0px" }}
                      labelId="select-label"
                      variant="outlined"
                      id="simple-select"
                      value={value}
                      onChange={onChange}
                      label="Status"
                      {...register("addClientStatus")}
                    >
                      {statusOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <span className="add-doc-error-message">
                {errors["addClientStatus"]?.message}
              </span>
            </div>
          </div>
          <div className="add-document-button">
            <ButtonUI
              loading={loading}
              variant="contained"
              buttonText="Add Client"
              style={{
                width: "150px",
                height: "35px",
                color: "#fff",
                backgroundColor: "#007bff",
                boxShadow: "none",
                border: "1px solid #007bff",
                fontWeight: "500",
                borderRadius: "4px",
                letterSpacing: "0.07rem",
                marginTop: "20px",
              }}
              handleSubmit={handleSubmit}
              onFormSubmit={onAddClientFormSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddClient;
