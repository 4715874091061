import getAllFieldTypeList from "../../api/getAllFieldTypeList";
import { AuthContext } from "../Auth/AuthContext";
const { createContext, useContext, useState } = require("react");

const ShortcutContext = createContext();

function ShortcutProvider({ children }) {
  const [showAddFieldSettingsForm, setShowAddFieldSettingsForm] =
    useState(false);
  const [showAddDocumentForm, setShowAddDocumentForm] = useState(false);
  const [documentAdded, setDocumentAdded] = useState(0);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [userAdded, setUserAdded] = useState(0);
  const { token } = useContext(AuthContext);

  // ADD DOCUMENT
  const setDocumentAddedToTrue = () => {
    setDocumentAdded((count) => count + 1);
  };

  const fetchSubCategory = async (token) => {
    try {
      const response = await getAllFieldTypeList({
        token,
        type: "document_subtype",
      });
      setSubCategoryOptions(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const toggleAddDocumentForm = () => {
    fetchSubCategory(token);
    setShowAddDocumentForm((prev) => !prev);
  };

  //ADD USER
  const onAddUserButtonClick = () => {
    setShowAddUserForm((prev) => !prev);
  };

  const setUserAddedToTrue = () => {
    setUserAdded((count) => count + 1);
  };
  return (
    <ShortcutContext.Provider
      value={{
        showAddDocumentForm,
        toggleAddDocumentForm,
        documentAdded,
        setDocumentAddedToTrue,
        subCategoryOptions,
        fetchSubCategory,
        showAddUserForm,
        onAddUserButtonClick,
        setUserAddedToTrue,
      }}
    >
      {children}
    </ShortcutContext.Provider>
  );
}

function useShortcut() {
  const context = useContext(ShortcutContext);
  if (context === undefined) {
    throw new Error("Context used outside of Provider");
  }
  return context;
}
export { useShortcut, ShortcutProvider };
