import React, { useContext } from "react";
import { MdContentCopy } from "react-icons/md";
import { toast } from "react-toastify";
import viewDocumentLink from "../../../api/viewDocumentLink";
import { AuthContext } from "../../../context/Auth/AuthContext";
import ButtonUI from "../../material-ui/ButtonUI";

/*** Component for copying the document link from the mailroom section ***/
const CopyLinkPopup = ({ caseNo, tableValues }) => {

  const { token } = useContext(AuthContext);

  const copyLink = async () => {
    const response = await viewDocumentLink({
      token: token,
      docId: tableValues.documentID,
    });
    const data = await response.data[0];
    const link = data?.pdflink;
    navigator.clipboard.writeText(link);
    toast.success("Link copied successfully", {
      position: "top-center",
      autoClose: 3000,
      theme: "dark",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <ButtonUI
      formButton={false}
      variant="outlined"
      buttonText={<MdContentCopy />}
      style={{
        backgroundColor: "#28a745",
        color: "white",
        marginRight: "5px",
        padding: "8px",
        minWidth: "fit-content",
        border: "none",
      }}
      onClickFunc={copyLink}
    />
  );
};

export default CopyLinkPopup;
