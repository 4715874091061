import baseUrl from "../config/baseUrl";

const logoutUser = async ({ token }) => {
  try {
    const response = await fetch(`${baseUrl}/api/logout_users`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    return error;
  }
};

export default logoutUser;
