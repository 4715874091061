import baseUrl from "../config/baseUrl";

const changeContractTemplatesStatus = async ({ token, user_id, contract_request_setting_id, status, is_admin }) => {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("contract_request_setting_id", contract_request_setting_id);
    formData.append("status", status);

    try {
        const response = await fetch(`${baseUrl}/api/update-contract-request-setting-data?contract_request_setting_id=${contract_request_setting_id}&status=${status}&is_admin=${is_admin}`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        });
        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};

export default changeContractTemplatesStatus;
