import React, { useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { nanoid } from "nanoid";
import { CircleX, Upload } from "lucide-react";
import { AuthContext } from "../../context/Auth/AuthContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Cross2Icon } from "@radix-ui/react-icons";
import baseUrl from "../../config/baseUrl";

const MultiFileUpload = ({ setShowUploadButton, documentSourceType }) => {
	const [files, setFiles] = useState([]);
	const [uploadProgress, setUploadProgress] = useState({});
	const { token, user_id, is_admin } = useContext(AuthContext);

	const onDrop = (acceptedFiles) => {
		const filteredFiles = acceptedFiles.filter((file) =>
			/\.(jpg|jpeg|gif|png|pdf)$/i.test(file.name)
		);

		const unsupportedFiles = acceptedFiles.filter(
			(file) => !/\.jpg$|\.jpeg$|\.gif$|\.png$|\.pdf/i.test(file.name)
		);

		if (unsupportedFiles.length > 0) {
			toast.error("Unsupported file format!", {
				position: toast.POSITION.TOP_CENTER,
			});
		}

		const newFiles = filteredFiles.map((file) => ({
			id: nanoid(),
			file,
			preview: URL.createObjectURL(file),
			progress: 0,
		}));
		setFiles((prevFiles) => [...prevFiles, ...newFiles]);
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		multiple: true,
		accept: ".txt,.doc,.xls",
	});

	const handleDelete = (fileId) => {
		setFiles(files.filter((file) => file.id !== fileId));
	};

	const uploadFile = async (file) => {
		const formData = new FormData();

		formData.append("document_source_type", documentSourceType);
		formData.append("documents[]", file.file);

		try {
			const response = await axios.post(
				`${baseUrl}/api/upload-multiple-mailroom-document`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
					onUploadProgress: (progressEvent) => {
						const progress = (progressEvent.loaded / progressEvent.total) * 100;
						setUploadProgress((prevProgress) => ({
							...prevProgress,
							[file.id]: progress,
						}));
					},
				}
			);

			// Handle successful upload
			// console.log("File uploaded:", response.data);
			// console.log(file.file);
		} catch (error) {
			// Handle upload error
			console.error("Error uploading file:", error);
		}
	};

	const uploadAllFiles = async () => {
		for (const file of files) {
			await uploadFile(file);
		}
		setShowUploadButton(false);
	};

	return (
		<div className="h-auto overflow-y-auto w-[65%] my-0 mx-auto flex flex-col items-center bg-white shadow-md rounded-md mt-6 p-12 relative">
			<Cross2Icon
				className=" absolute top-2 right-1 bg-[#007BFF] rounded-full text-white size-6 p-1 cursor-pointer"
				onClick={() => setShowUploadButton(false)}
			/>
			<div
				{...getRootProps({ className: "dropzone" })}
				className="border-dashed border-2 border-gray-400 p-4 w-full text-center cursor-pointer h-[200px] rounded-md flex flex-col items-center justify-center"
			>
				<input {...getInputProps()} />
				<div className="flex flex-col justify-center items-center">
					<Upload />
					<p className="font-bold text-muted-foreground text-lg">
						Drop a file here or click to upload
					</p>
					<small className="text-base ">Maximum upload size: 1.54 MB</small>
				</div>
			</div>
			<div className="w-full mt-4">
				{files.map((fileWrapper) => (
					<div
						key={fileWrapper.id}
						className="flex items-center justify-between gap-2 p-2 border-gray-300 shadow-md rounded-md mt-2"
					>
						<div className="flex flex-1 items-center">
							<div className="w-full">
								<p>{fileWrapper.file.name}</p>
								<progress
									value={uploadProgress[fileWrapper.id] || 0}
									max="100"
									className="w-full [&::-webkit-progress-bar]:rounded-lg [&::-webkit-progress-value]:rounded-lg   [&::-webkit-progress-bar]:bg-slate-100 [&::-webkit-progress-value]:bg-green-300 [&::-moz-progress-bar]:bg-green-300"
								/>
							</div>
						</div>

						<CircleX
							onClick={() => handleDelete(fileWrapper.id)}
							className="text-red-500 cursor-pointer"
						/>
					</div>
				))}
			</div>
			{files.length > 0 && (
				<button
					className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
					onClick={uploadAllFiles}
				>
					Upload
				</button>
			)}
		</div>
	);
};

export default MultiFileUpload;
