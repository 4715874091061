import React, { useState, useEffect, useContext } from "react";
import ReactPaginate from "react-paginate";
import { format } from "date-fns";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { RiFilter2Fill } from "react-icons/ri";
import { AiOutlineSearch } from "react-icons/ai";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import reportsData from "../api/reports";
import { AuthContext } from "../context/Auth/AuthContext";
import Navbar from "../components/common/Navbar";
import Table from "../components/report-table/Table";
import Loading from "../components/material-ui/Loading";
import ButtonUI from "../components/material-ui/ButtonUI";
import BasicDatePicker from "../components/material-ui/DatePicker";
import baseUrl from "../config/baseUrl";
import "../styles/DocumentTable.css";

const Reports = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [openFilter, setOpenFilter] = useState(false);
  const [docsCount, setDocsCount] = useState(0);

  const [documentResponse, setDocumentResponse] = useState([]);

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [fileTypeOptions, setFileTypeOptions] = useState([]);
  const [userList, setUserList] = useState([]);

  const [globalSearch, setGlobalSearch] = useState("");
  const [selectedSourceOptions, setSelectedSourceOptions] = useState(null);
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState(null);
  const [selectedFileTypeOptions, setSelectedFileTypeOptions] = useState(null);
  const [searchedUserID, setSearchedUserID] = useState("");
  const [modifiedBy, setModifiedBy] = useState("");
  const [searchedDate, setSearchedDate] = useState(null);
  const [modifiedDate, setModifiedDate] = useState(null);

  const { token, user_id, is_admin } = useContext(AuthContext);

  const [api, setApi] = useState(
    `${baseUrl}/DMS/api/report_document?limit=20&offset=0&userId=${user_id}&isAdmin=${is_admin}`
  );

  const limit = 20;
  const offset = currentPage * limit;

  let global_search = globalSearch ? `&global_search=${globalSearch}` : "";
  let document_type = selectedFileTypeOptions
    ? `&document_type=${selectedFileTypeOptions}`
    : "";
  let source = selectedSourceOptions
    ? `&uncategorized_flag=${selectedSourceOptions.id}`
    : "";
  let category = selectedCategoryOptions
    ? `&category_id=${selectedCategoryOptions}`
    : "";
  let created_at = searchedDate
    ? `&date_entered=${searchedDate.toLocaleDateString("fr-CA")}`
    : "";
  let date_modified = modifiedDate
    ? `&date_modified=${modifiedDate.toLocaleDateString("fr-CA")}`
    : "";

  let case_number = searchedUserID ? `&case_number=${searchedUserID}` : "";
  let user_name = modifiedBy ? `&userIdFilter=${modifiedBy.id}` : "";

  const sourceList = [
    {
      id: "E33333",
      name: "Email",
    },
    {
      id: "F22222",
      name: "Fax",
    },
    {
      id: "S11111",
      name: "Scan",
    },
    {
      id: "D44444",
      name: "Service Docs",
    },
  ];

  const documentResponseFunc = async () => {
    setLoading(true);
    const response = await reportsData({ token, api });
    const notNullFileType = response?.document_type.filter(
      (response) => response !== null && response !== ""
    );
    setDocsCount(response?.docsCount);
    setPageCount(Math.ceil(response?.docsCount / limit));
    if (currentPage > Math.ceil(response?.docsCount / limit)) {
      setCurrentPage(Math.ceil(response?.docsCount / limit));
    }
    setDocumentResponse(response.data);
    setFileTypeOptions(notNullFileType);
    setUserList(response.userList);
    setCategoryOptions(response.category);
    setLoading(false);
  };

  useEffect(() => {
    documentResponseFunc();
  }, [api]);

  useEffect(() => {
    setApi(
      `${baseUrl}/DMS/api/report_document?limit=${limit}&offset=${offset}&userId=${user_id}&isAdmin=${is_admin}${global_search}${document_type}${category}${case_number}${created_at}${date_modified}${user_name}${source}`
    );
  }, [currentPage]);

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      setCurrentPage(0);
      setApi(
        `${baseUrl}/DMS/api/report_document?limit=20&offset=0&userId=${user_id}&isAdmin=${is_admin}${global_search}${document_type}${category}${case_number}${created_at}${date_modified}${user_name}${source}`
      );
    }, 2500);

    return () => clearTimeout(debounceFn);
  }, [globalSearch]);

  const getAllDataFunc = async () => {
    const response = await reportsData({
      token,
      api: `${baseUrl}/DMS/api/report_document?limit=${docsCount}&offset=0&userId=${user_id}&isAdmin=${is_admin}${global_search}${document_type}${category}${case_number}${created_at}${date_modified}${user_name}${source}`,
    });
    const data = response.data;

    const records = data.map((data) => {
      return {
        case_number: data.case_number !== "" ? data.case_number : "N/A",
        title: data.title,
        extension: data.extension,
        category: data.category,
        uncategorized_flag:
          data.uncategorized_flag === "S11111"
            ? "Scan"
            : data.uncategorized_flag === "F22222"
            ? "Fax"
            : data.uncategorized_flag === "E33333"
            ? "Email"
            : "Service Docs",
        date_entered:
          data.created_at !== "0000-00-00" &&
          data.created_at !== "0000-00-00 00:00:00"
            ? format(new Date(data.created_at), "MM-dd-yyyy HH:mm")
            : "N/A",
        date_modified:
          data.updated_at !== "0000-00-00" &&
          data.updated_at !== "0000-00-00 00:00:00"
            ? format(new Date(data.updated_at), "MM-dd-yyyy HH:mm")
            : "N/A",
        modified_user_name:
          data.modified_user_name !== null ? data.modified_user_name : "N/A",
      };
    });
    return records;
  };

  const onGlobalSearchChange = (e) => {
    setGlobalSearch(e.target.value);
  };

  const onSourceOptionChange = (selectedSource) => {
    setSelectedSourceOptions(selectedSource);
  };

  const onCategoryOptionChange = (selectedCategory) => {
    setSelectedCategoryOptions(selectedCategory);
  };

  const onFileTypeOptionChange = (selectedFileType) => {
    setSelectedFileTypeOptions(selectedFileType);
  };

  const onSearchedUserIDChange = (e) => {
    setSearchedUserID(e.target.value);
  };

  const onModifiedByChange = (selectedUser) => {
    setModifiedBy(selectedUser);
  };

  const onSearchedDateChange = (newDate) => {
    setSearchedDate(newDate);
  };

  const onModifiedDateChange = (newDate) => {
    setModifiedDate(newDate);
  };

  const filtervisibility = () => {
    setOpenFilter(!openFilter);
  };

  const applyFilters = () => {
    // setOpenFilter(false);
    setCurrentPage(0);
    setApi(
      `${baseUrl}/DMS/api/report_document?limit=${limit}&offset=0&userId=${user_id}&isAdmin=${is_admin}${global_search}${document_type}${category}${case_number}${created_at}${date_modified}${user_name}${source}`
    );
  };

  const clearFilters = () => {
    // setOpenFilter(false);
    setGlobalSearch("");
    setSelectedCategoryOptions("");
    setSelectedSourceOptions("");
    setSelectedFileTypeOptions("");
    setSearchedUserID("");
    setModifiedBy("");
    setSearchedDate(null);
    setModifiedDate(null);
    setCurrentPage(0);
    setApi(
      `${baseUrl}/DMS/api/report_document?limit=${limit}&offset=0&userId=${user_id}&isAdmin=${is_admin}`
    );
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  return (
    <div className="recent-container">
      <div className="navbar">
        <Navbar />
      </div>
      <div className="documents-content">
        <div className="table-header">
          <TextField
            name="globalSearch"
            label="Search"
            type="text"
            autoComplete="off"
            value={globalSearch}
            onChange={onGlobalSearchChange}
            variant="outlined"
            InputProps={{
              endAdornment: <AiOutlineSearch />,
            }}
          />
          <div className="doc-header-btn">
            <button className="filter-documents" onClick={filtervisibility}>
              <RiFilter2Fill />
            </button>
          </div>
        </div>
        <div
          className={openFilter ? "document-filter" : "hide-document-filter"}
        >
          <BasicDatePicker
            value={searchedDate}
            setValue={onSearchedDateChange}
            label="Uploaded"
          />
          <BasicDatePicker
            value={modifiedDate}
            setValue={onModifiedDateChange}
            label="Date Modified"
          />
          <Autocomplete
            id="select-category-filter"
            options={sourceList.map((source, id) => {
              return {
                ...source,
                label: source.name,
              };
            })}
            sx={{ width: 154 }}
            renderInput={(params) => <TextField {...params} label="Source" />}
            value={selectedSourceOptions}
            onChange={(e, selectedOption) =>
              onSourceOptionChange(selectedOption)
            }
          />
          <Autocomplete
            id="select-category-filter"
            options={categoryOptions}
            sx={{ width: 154 }}
            renderInput={(params) => <TextField {...params} label="Category" />}
            value={selectedCategoryOptions}
            onChange={(e, selectedOption) =>
              onCategoryOptionChange(selectedOption)
            }
          />
          <Autocomplete
            id="select-file-option"
            options={fileTypeOptions}
            sx={{ width: 154 }}
            renderInput={(params) => (
              <TextField {...params} label="File Type" />
            )}
            value={selectedFileTypeOptions}
            onChange={(e, selectedOption) =>
              onFileTypeOptionChange(selectedOption)
            }
          />
          <Autocomplete
            id="select-file-option"
            options={userList.map((user, id) => {
              return {
                ...user,
                label: `${user.first_name} ${user.last_name}`,
              };
            })}
            sx={{ width: 154 }}
            renderInput={(params) => (
              <TextField {...params} label="Modified By" />
            )}
            value={modifiedBy}
            onChange={(e, selectedOption) => onModifiedByChange(selectedOption)}
          />
          <TextField
            name="caseID"
            label="Case ID"
            type="text"
            autoComplete="off"
            value={searchedUserID}
            onChange={onSearchedUserIDChange}
            variant="outlined"
          />
          <ButtonUI
            formButton={false}
            variant="contained"
            buttonText="Apply"
            style={{
              width: "80px",
              height: "35px",
              color: "#007bff",
              backgroundColor: "white",
              boxShadow: "none",
              border: "1px solid #007bff",
              fontWeight: "500",
              borderRadius: "4px",
              letterSpacing: "0.07rem",
            }}
            onClickFunc={() => {
              applyFilters();
            }}
          ></ButtonUI>
          <ButtonUI
            formButton={false}
            variant="contained"
            buttonText="Reset"
            style={{
              width: "80px",
              height: "35px",
              color: "#007bff",
              backgroundColor: "white",
              boxShadow: "none",
              border: "1px solid #007bff",
              fontWeight: "500",
              borderRadius: "4px",
              letterSpacing: "0.07rem",
            }}
            onClickFunc={() => {
              clearFilters();
            }}
          ></ButtonUI>
        </div>
        {!loading ? (
          documentResponse.length > 0 ? (
            <div className="document__table">
              <Table
                loading={loading}
                openFilter={openFilter}
                documentTableData={documentResponse}
                categoryOptions={categoryOptions}
                getAllDataFunc={getAllDataFunc}
                docsCount={docsCount}
              />
              <div className="table-pagination">
                <ReactPaginate
                  previousLabel={<MdOutlineArrowBackIos />}
                  nextLabel={<MdOutlineArrowForwardIos />}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  forcePage={currentPage}
                  containerClassName={"doc-pagination"}
                  previousLinkClassName={"doc-pagination-link"}
                  nextLinkClassName={"doc-pagination-link"}
                  disabledClassName={"doc-pagination-link-disabled"}
                  activeClassName={"doc-pagination-link-active"}
                />
              </div>
            </div>
          ) : (
            <h5 className="no-doc-data">No Data Available</h5>
          )
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Reports;
