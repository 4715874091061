import baseUrl from "../config/baseUrl";

const pdfEditor = async ({
  token,
  document_url,
  document_id,
}) => {
  const formData = new FormData();
  formData.append("document_url", document_url);
  formData.append("document_id", document_id);
  https: try {
    const response = await fetch(
      `${baseUrl}/api/save-edited-document?document_id=${document_id}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default pdfEditor;
