import React, { useState, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TbChecklist } from "react-icons/tb";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import ButtonUI from "../../material-ui/ButtonUI";
import { AuthContext } from "../../../context/Auth/AuthContext";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import assignUser from "../../../api/assignUser";
import "../../../styles/AssignUserPopup.css";

const rejectCaseFormSchema = yup.object().shape({
  user: yup.string().required("User is required field"),
});

const AssignUserPopup = ({
  selectedRows,
  userList,
  setDocumentUpdatedToTrue,
  bulkAssign,
  setBulkAssign,
  caseList,
  createdAt,
  documentSourceDetails,
  setRowSelection,
  bulkAssignIds,
}) => {
  const { token } = useContext(AuthContext);
  const [docId, setDocId] = useState(
    selectedRows.map((row) => row.documentID).join(",")
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(rejectCaseFormSchema),
  });

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    ignoreCase: true, // Ignore case sensitivity
    stringify: (option) => option.user_name,
  });

  const changeDropdownVisibilityFunc = () => {
    setBulkAssign(!bulkAssign);
  };

  const handleClose = () => {
    setBulkAssign(false);
  };

  const onAssignUserFormSubmit = async (assignUserFormData) => {
    const assignUserFormResponse = await assignUser({
      token: token,
      document_source_details: documentSourceDetails,
      created_at: createdAt,
      document_source_type: caseList,
      user_id: assignUserFormData.user,
      bulkAssignIds: bulkAssignIds,
    });

    if (assignUserFormResponse.code === 200) {
      setBulkAssign(false);
      setDocumentUpdatedToTrue();
      setRowSelection({});
      changeDropdownVisibilityFunc();
      toast.success("Document is assigned successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Error while assiging document/s to an user", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <Dialog
      open={bulkAssign}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="edit-doc-modal">
        <div className="popup-header-svg-icon">
          <TbChecklist />
        </div>
        <div className="popup-header-title">Assign User</div>
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-description"> */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <FormControl className="" fullWidth>
            <Controller
              control={control}
              name="user"
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  options={userList}
                  getOptionLabel={(option) => {
                    // Find all users with the same user_name
                    const duplicates = userList.filter(
                      (item) => item.user_name === option.user_name
                    );

                    // If there are duplicates, find the index of the current option
                    const index = duplicates.findIndex(
                      (item) => item.id === option.id
                    );

                    // Return the user_name with a sequential number for duplicates
                    return duplicates.length > 1
                      ? `${option.user_name} (${index + 1})`
                      : option.user_name;
                  }}
                  filterOptions={filterOptions}
                  onChange={(e, value) => {
                    onChange(value ? value.id : "");
                  }}
                  sx={{ margin: "10px 0px" }}
                  renderInput={(params) => (
                    <TextField {...params} label="User" variant="outlined" />
                  )}
                />
              )}
            />
          </FormControl>

          {/* <FormControl className="assign-user-doc-select-ui">
            <InputLabel
              className="assign-user-doc-select-label"
              id="select-label"
            >
              User
            </InputLabel>
            <Controller
              control={control}
              name="user"
              defaultValue={""}
              render={({ field }) => (
                <Select
                  sx={{ margin: "10px 0px" }}
                  labelId="select-label"
                  variant="outlined"
                  id="simple-select"
                  {...field}
                  label="User"
                >
                  {userList.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.user_name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl> */}
          <span className="error-message">{errors["user"]?.message}</span>
        </div>
        {/* </DialogContentText> */}
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end" }}>
        <ButtonUI
          formButton={false}
          variant="outlined"
          buttonText="No"
          style={{
            margin: "15px 0px",
            marginTop: "0px",
            backgroundColor: "#fff",
            color: "#007bff",
            border: "1px solid #007bff",
          }}
          onClickFunc={handleClose}
        />
        <ButtonUI
          variant="outlined"
          buttonText="Yes"
          style={{
            margin: "15px",
            marginTop: "0px",
            backgroundColor: "#fff",
            color: "##007bff",
            border: "1px solid #007bff",
          }}
          autoFocus={true}
          handleSubmit={handleSubmit}
          onFormSubmit={onAssignUserFormSubmit}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AssignUserPopup;
