import React, { useEffect, useState, useContext } from "react";
import Navbar from "../components/common/Navbar";
import RequestsTable from "../components/requests/RequestsTable";
import { AuthContext } from "../context/Auth/AuthContext";
import requests from "../api/requests";
import baseUrl from "../config/baseUrl";
import {
  CaretSortIcon,
  ChevronDownIcon,
  StarIcon,
  StarFilledIcon,
  EyeOpenIcon,
} from "@radix-ui/react-icons";

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  useReactTable,
} from "@tanstack/react-table";

import { Button } from "../shadcn-components/ui/button";
import { Checkbox } from "../shadcn-components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../shadcn-components/ui/dropdown-menu";
import { Input } from "../shadcn-components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../shadcn-components/ui/table";

import "../styles/Starred.css";
import { Link } from "react-router-dom";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../shadcn-components/ui/tooltip";

import { useDebounce } from "use-debounce";

/*** Main component for listing the details of document requests (count of uploaded documents for requests) for clients ***/
const Requests = () => {
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [requestsDocumentsResponse, setRequestsDocumentsResponse] = useState(
    []
  );
  const { token, user_id, is_admin } = useContext(AuthContext);

  const [api, setApi] = useState(
    `${baseUrl}/api/get-doc-requested-data-of-client?limit=${paginationModel.pageSize}&offset=${paginationModel.page}&userId=${user_id}&isAdmin=${is_admin}`
  );

  //new states
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({
    contact_type: false,
  });
  const [rowSelection, setRowSelection] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [globalFilter, setGlobalFilter] = useState("");
  const [debouncedGlobalFilter] = useDebounce(globalFilter, 1000);

  const fetchRequestsDocuments = async () => {
    setLoading(true);
    const offset = currentPage - 1;
    const filterParams = {};

    columnFilters.forEach((filterItem) => {
      filterParams[filterItem.id] = filterItem.value;
    });

    const sortParam = sorting
      .map((sortItem) => {
        return `${sortItem.id}=${sortItem.desc ? "desc" : "asc"}`;
      })
      .join(",");

    const params = {
      isAdmin: 1,
      userId: 1,
      limit: pageSize,
      offset: offset,
      ...filterParams,
      global_search: debouncedGlobalFilter,
      sort: sortParam,
    };
    const queryString = new URLSearchParams(params).toString();
    const url = `${baseUrl}/api/get-doc-requested-data-of-client?${queryString}`;

    const response = await requests({ token, api: url });
    if (response.code == 200) {
      setRequestsDocumentsResponse(response?.data);
      setTotalPages(Math.ceil(response?.document_count / pageSize));
    } else {
      setRequestsDocumentsResponse([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRequestsDocuments();
  }, [
    api,
    debouncedGlobalFilter,
    currentPage,
    columnFilters,
    pageSize,
    sorting,
  ]);

  useEffect(() => {
    setApi(
      `${baseUrl}/api/get-doc-requested-data-of-client?limit=${paginationModel.pageSize}&offset=${paginationModel.page}&userId=${user_id}&isAdmin=${is_admin}`
    );
  }, [paginationModel.page, paginationModel.pageSize]);

  const handleFilterModelChange = (newModel) => {
    const queryParams = newModel.items
      .map((item) => {
        return `${item.field}=${item.value}`;
      })
      .concat(
        newModel.quickFilterValues.map((value) => `global_search=${value}`)
      )
      .join("&");

    const newApi = `${baseUrl}/api/get-doc-requested-data-of-client?limit=${paginationModel.pageSize}&offset=${paginationModel.page}&userId=${user_id}&isAdmin=${is_admin}&${queryParams}`;

    setApi(newApi);
  };

  const columns = [
    // {
    //   id: "select",
    //   header: ({ table }) => (
    //     <div className="flex items-center">
    //       <Checkbox
    //         checked={
    //           table.getIsAllPageRowsSelected() ||
    //           (table.getIsSomePageRowsSelected() && "indeterminate")
    //         }
    //         onCheckedChange={(value) =>
    //           table.toggleAllPageRowsSelected(!!value)
    //         }
    //         aria-label="Select all"
    //       />
    //     </div>
    //   ),
    //   cell: ({ row }) => (
    //     <Checkbox
    //       checked={row.getIsSelected()}
    //       onCheckedChange={(value) => row.toggleSelected(!!value)}
    //       aria-label="Select row"
    //     />
    //   ),
    //   enableSorting: false,
    //   enableHiding: false,
    // },
    {
      accessorKey: "client_name",
      displayName: "Client Name",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Client Name
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="wrap-text capitalize">
          {row.getValue("client_name")}
        </div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "contact_type",
      displayName: "Client Type",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Client Type
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("contact_type")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes("primary") || value.includes("beneficiary");
      },
    },
    {
      accessorKey: "document_status",
      displayName: "Status",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Status
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("document_status")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "document_count",
      displayName: "Document Count",
      filterable: false,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Document count
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="w-full flex items-center justify-start wrap-text limited-width">
          {row.getValue("document_count")}
        </div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "requested_by",
      displayName: "Requested by",
      filterable: true,
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Requested by
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("requested_by")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "created_at",
      displayName: "Requested date",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Requested date
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("created_at")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "updated_at",
      displayName: "Last updated",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="p-0"
        >
          Last updated
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("updated_at")}</div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      id: "actions",
      displayName: "Actions",
      header: ({ column }) => (
        <Button variant="ghost" className="p-0 !cursor-default">
          Actions
        </Button>
      ),
      enableHiding: false,
      cell: ({ row }) => {
        return (
          <Link
            to={`/clients/${row.original.contact_id}${
              row.original?.beneficiary_id
                ? `/${row.original.beneficiary_id}`
                : ""
            }`}
            target="_blank"
            className="flex gap-2"
            // onClick={() =>
            // 	localStorage.setItem("reqBenId", row.original?.beneficiary_id)
            // }
          >
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <span>
                    <Button
                      className="bg-[#ffc107] p-2 h-auto"
                      style={{
                        backgroundColor: "#ffc107",
                        color: "white",
                        marginRight: "5px",
                        padding: "8px",
                        minWidth: "fit-content",
                        border: "none",
                      }}
                    >
                      <EyeOpenIcon />
                    </Button>
                  </span>
                </TooltipTrigger>
                <TooltipContent className="bg-gray-600">
                  <p>View</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </Link>
        );
      },
    },
  ];

  const table = useReactTable({
    data: requestsDocumentsResponse,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      pageSize: 20,
    },
    manualPagination: true,
    manualSorting: true,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pageSize,
    },

    globalFilterFn: (rows, columnFilters) => {
      const [globalFilter] = columnFilters.filter(
        (filter) => filter.id === "global"
      );
      if (!globalFilter || !globalFilter.value) return rows;

      return rows.filter((row) => {
        const name = row.original.name.toLowerCase(); // Assuming 'name' is the column key
        return name.includes(globalFilter.value.toLowerCase());
      });
    },
    onGlobalFilterChange: (filterValue) => {
      setGlobalFilter(filterValue); // Update the global filter state
    },
  });

  return (
    <div className="starred-container overflow-auto">
      <div className="navbar">
        <Navbar />
      </div>
      <div className="starred-content">
        <div className="starred-table-content">
          <RequestsTable
            requestsDocumentsResponse={requestsDocumentsResponse}
            table={table}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalPages={totalPages}
            loading={loading}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </div>
  );
};

export default Requests;
