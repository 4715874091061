import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../shadcn-components/ui/card";
import { ShortcutOutlined } from "@mui/icons-material";
import { MdPendingActions } from "react-icons/md";
import { BsFillCloudArrowUpFill } from "react-icons/bs";

import {
  HiOutlineClipboardList,
  HiOutlineDocument,
  HiOutlineMail,
  HiOutlineTrash,
} from "react-icons/hi";

import { Link } from "react-router-dom";

function ShortcutCards({
  customBoxShadow,
  toggleAddDocumentForm,
  onAddUserButtonClick,
}) {
  return (
    <Card
      className="flex flex-col items-center justify-center"
      style={customBoxShadow}
    >
      <CardHeader className="flex flex-row gap-4">
        <CardTitle className="self-center letterSpacing">Shortcuts</CardTitle>
        <ShortcutOutlined />
      </CardHeader>
      <CardContent>
        <div className="flex flex-wrap gap-4 md:gap-6 justify-center items-center p-4">
          <div className="flex flex-col gap-4 md:gap-6">
            <button
              className="w-16 md:w-20 aspect-square flex flex-col items-center justify-center bg-[#e6f2ff] text-[#007bff] rounded-full shadow-sm transition-transform transform hover:bg-[#cce5ff] hover:scale-105 hover:text-black"
              onClick={toggleAddDocumentForm}
            >
              <BsFillCloudArrowUpFill className="text-lg md:text-2xl" />
              <span className="mt-1 text-[10px] md:text-xs font-medium text-center">
                Add Doc
              </span>
            </button>

            <button
              className="w-16 md:w-20 aspect-square flex flex-col items-center justify-center bg-teal-100 text-teal-700 rounded-full shadow-sm transition-transform transform hover:bg-teal-200 hover:scale-105 hover:text-black"
              onClick={onAddUserButtonClick}
            >
              <HiOutlineClipboardList className="text-lg md:text-2xl" />
              <span className="mt-1 text-[10px] md:text-xs font-medium text-center">
                Add User
              </span>
            </button>
          </div>

          <div className="flex flex-col gap-4 md:gap-6">
            <Link
              to="/requests"
              className="w-16 md:w-20 aspect-square flex flex-col items-center justify-center bg-amber-100 text-amber-700 rounded-full shadow-sm transition-transform transform hover:bg-amber-200 hover:scale-105"
            >
              <MdPendingActions className="text-lg md:text-2xl" />
              <span className="mt-1 text-[10px] md:text-xs font-medium text-center">
                Requests
              </span>
            </Link>

            <Link
              to="/clients"
              className="w-16 md:w-20 aspect-square flex flex-col items-center justify-center bg-purple-100 text-purple-700 rounded-full shadow-sm transition-transform transform hover:bg-purple-200 hover:scale-105"
            >
              <HiOutlineMail className="text-lg md:text-2xl" />
              <span className="mt-1 text-[10px] md:text-xs font-medium text-center">
                Clients
              </span>
            </Link>
          </div>

          <div className="flex flex-col gap-4 md:gap-6">
            <Link
              to="/trash-users"
              className="w-16 md:w-20 aspect-square flex flex-col items-center justify-center bg-gray-100 text-gray-700 rounded-full shadow-sm transition-transform transform hover:bg-gray-200 hover:scale-105"
            >
              <HiOutlineTrash className="text-lg md:text-2xl" />
              <span className="mt-1 text-[10px] md:text-xs font-medium text-center">
                Trash User
              </span>
            </Link>
            <Link
              to="/trash-documents"
              className="w-16 md:w-20 aspect-square flex flex-col items-center justify-center bg-gray-100 text-gray-700 rounded-full shadow-sm transition-transform transform hover:bg-gray-200 hover:scale-105"
            >
              <HiOutlineDocument className="text-lg md:text-2xl" />
              <span className="mt-1 text-[10px] md:text-xs font-medium text-center">
                Trash Doc
              </span>
            </Link>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default ShortcutCards;
