import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AiFillCloseCircle } from "react-icons/ai";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../material-ui/InputUI";
import ButtonUI from "../material-ui/ButtonUI";
import { AuthContext } from "../../context/Auth/AuthContext";
import addContractTemplate from "../../api/addContractTemplate";
import addContractTemplateImg from "../../images/add-user.png";
import "../../styles/AddDocument.css";
import SetSignaturePopup from "./SetSignaturePopup";

const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "application/pdf",
];

const addContractTemplateFormSchema = yup.object().shape({
  contractName: yup.string().required("Contract Name is required field"),
  description: yup.string().required("Description is required field"),
  documentType: yup.string().required("Document Type is required field"),
  ocrStatus: yup.string().required("OCR Status is required field"),
  clientType: yup.string().required("Client Type is required field"),
  // fileUpload: yup
  //   .mixed()
  //   .test("required", "Please select a file", (value) => {
  //     return value && value.length > 0;
  //   })
  //   .test("type", "We only support images and pdf", (value) => {
  //     return value && SUPPORTED_FORMATS.includes(value[0]?.type);
  //   })
  //   .test(
  //     "fileSize",
  //     "Please upload file having size less than 5MB",
  //     (value) => {
  //       return value && value[0]?.size <= 5000000;
  //     }
  //   ),
  status: yup.string().required("Status is required field"),

});

const AddContractTemplate = ({ typeOptions, docTypeOptions, onAddContractTemplatesButtonClick, setContractTemplatesAddedToTrue }) => {
  const [loading, setLoading] = useState(false);
  const { token, user_id, is_admin } = useContext(AuthContext);
  const [showSignature, setShowSignature] = useState(false);
  const [contractTemplateId, setContractTemplateId] = useState("");
  const [contractTemplateSlug, setContractTemplateSlug] = useState("");

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addContractTemplateFormSchema),
  });

  const statusOptions = [
    {
      name: "Active",
      value: "active",
    },
    {
      name: "Inactive",
      value: "inactive",
    },
  ];

  const ocrStatusOptions = [
    {
      name: "True",
      value: "True",
    },
    {
      name: "False",
      value: "False",
    },
  ];

  const onAddContractTemplateFormSubmit = async (addContractTemplateFormData) => {
    if (!contractTemplateId) {
      toast.error("Please set the signature", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
    setLoading(true);
    const addContractTemplateFormResponse = await addContractTemplate({
      token: token,
      userId: user_id,
      contract_name: addContractTemplateFormData.contractName,
      contract_description: addContractTemplateFormData.description,
      client_type: addContractTemplateFormData.clientType,
      document_type: addContractTemplateFormData.documentType,
      ocr_status: addContractTemplateFormData.ocrStatus,
      status: addContractTemplateFormData.status,
      is_admin: is_admin,
      template_id: contractTemplateId,
      template_slug: contractTemplateSlug,
    });

    if (addContractTemplateFormResponse?.code === 200) {
      onAddContractTemplatesButtonClick();
      setContractTemplatesAddedToTrue();
      toast.success("Contract Template is added successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Error while adding Contract Template", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setLoading(false);
  };

  const handleSetSignature = () => {
    setShowSignature(!showSignature);
  };

  return (
    <>
      {showSignature && (<SetSignaturePopup
        setContractTemplateId={setContractTemplateId}
        setContractTemplateSlug={setContractTemplateSlug}
      // setDocumentRequestSettingsAddedToTrue={
      //   setDocumentRequestSettingsAddedToTrue
      // }
      // id={row.original.document_request_setting_id}
      />)}
      <div className="add-document-page">
        <div className="add-document-container">
          <div className="add-document-header">
            <div className="add-document-title-with-img">
              <img src={addContractTemplateImg} height="100px" width="120px" alt="Add Contract Template" />
              <div className="add-document-title">Add Contract Template</div>
            </div>
            <ButtonUI
              formButton={false}
              variant="contained"
              buttonText={<AiFillCloseCircle />}
              style={{
                background: "none",
                padding: "0px",
                minWidth: "auto",
              }}
              onClickFunc={onAddContractTemplatesButtonClick}
            ></ButtonUI>
          </div>
          <div className="add-document-form">
            <div className="add-document-inputs">
              <div className="add-document-inputs-with-error-message">
                <InputUI
                  name="contractName"
                  label="Enter Contract Name"
                  type="text"
                  variant="outlined"
                  cssStyles={{
                    margin: "10px 0px",
                  }}
                  register={register}
                />
                <span className="error-message">
                  {errors["contractName"]?.message}
                </span>
              </div>
              <div className="add-document-inputs-with-error-message">
                <InputUI
                  name="description"
                  label="Enter Description"
                  type="text"
                  variant="outlined"
                  cssStyles={{ margin: "10px 0px" }}
                  register={register}
                />
                <span className="error-message">
                  {errors["description"]?.message}
                </span>
              </div>
            </div>
            <div className="add-document-inputs">
              <div className="add-document-inputs-with-error-message select-ui">
                <FormControl fullWidth>
                  <InputLabel id="select-label">Client Type</InputLabel>
                  <Controller
                    control={control}
                    name="clientType"
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <Select
                        name="clientType"
                        sx={{ margin: "10px 0px" }}
                        labelId="select-label"
                        variant="outlined"
                        id="simple-select"
                        value={value}
                        onChange={onChange}
                        label="Client Type"
                        {...register("clientType")}
                      >
                        {typeOptions?.map((option) => (
                          <MenuItem key={option.typeId} value={option.typeId}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <span className="error-message">
                  {errors["clientType"]?.message}
                </span>
              </div>
              <div className="add-document-inputs-with-error-message select-ui">
                <FormControl fullWidth>
                  <InputLabel id="select-label">Document Type</InputLabel>
                  <Controller
                    control={control}
                    name="documentType"
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <Select
                        name="documentType"
                        sx={{ margin: "10px 0px" }}
                        labelId="select-label"
                        variant="outlined"
                        id="simple-select"
                        value={value}
                        onChange={onChange}
                        label="Document Type"
                        {...register("documentType")}
                      >
                        {docTypeOptions.map((option) => (
                          <MenuItem key={option.typeId} value={option.typeId}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <span className="error-message">
                  {errors["documentType"]?.message}
                </span>
              </div>
            </div>

            <div className="add-document-inputs">
              <div className="add-document-inputs-with-error-message select-ui">
                <FormControl fullWidth>
                  <InputLabel id="select-label">OCR Status</InputLabel>
                  <Controller
                    control={control}
                    name="ocrStatus"
                    defaultValue="False"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        name="ocrStatus"
                        sx={{ margin: "10px 0px" }}
                        labelId="select-label"
                        variant="outlined"
                        id="simple-select"
                        value={value}
                        onChange={onChange}
                        label="OCR Status"
                        {...register("ocrStatus")}
                      >
                        {ocrStatusOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <span className="error-message">
                  {errors["ocrStatus"]?.message}
                </span>
              </div>
              <div className="add-document-inputs-with-error-message select-ui">
                <FormControl fullWidth>
                  <InputLabel id="select-label">Status</InputLabel>
                  <Controller
                    control={control}
                    name="status"
                    defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <Select
                        name="status"
                        sx={{ margin: "10px 0px" }}
                        labelId="select-label"
                        variant="outlined"
                        id="simple-select"
                        value={value}
                        onChange={onChange}
                        label="Status"
                        {...register("status")}
                      >
                        {statusOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <span className="error-message">
                  {errors["status"]?.message}
                </span>
              </div>
            </div>

            <div className="add-document-inputs">
              <div className="add-document-inputs-with-error-message select-ui">
                <span className="request-list-link"
                  onClick={handleSetSignature}
                >
                  Set Signature
                </span>
              </div>
              {/* <div className="add-document-inputs-with-error-message upload-ui">
              <InputUI
                name="fileUpload"
                type="file"
                variant="outlined"
                cssStyles={{
                  margin: "10px 0px",
                }}
                register={register}
              />
              <span className="error-message">
                {errors["fileUpload"]?.message}
              </span>
            </div> */}
            </div>
            <div className="add-document-button">
              <ButtonUI
                loading={loading}
                variant="contained"
                buttonText="Add Contract Template"
                style={{
                  width: "250px",
                  height: "35px",
                  color: "#fff",
                  backgroundColor: "#007bff",
                  boxShadow: "none",
                  border: "1px solid #007bff",
                  fontWeight: "500",
                  borderRadius: "4px",
                  letterSpacing: "0.07rem",
                  marginTop: "20px",
                }}
                handleSubmit={handleSubmit}
                onFormSubmit={onAddContractTemplateFormSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddContractTemplate;
