import React, { useMemo, useContext, useState } from "react";
import {
	ChevronDownIcon,
	CaretSortIcon,
	MixerHorizontalIcon,
} from "@radix-ui/react-icons";
import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";

import TableSkeleton from "../../common/TableSkeleton";
import PaginationSection from "../../common/PaginationSection";
import DataToolbar from "../../common/DataToolbar";

import { Button } from "../../../shadcn-components/ui/button";
import { Checkbox } from "../../../shadcn-components/ui/checkbox";
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuTrigger,
	DropdownMenuItem,
} from "../../../shadcn-components/ui/dropdown-menu";
import { Input } from "../../../shadcn-components/ui/input";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "../../../shadcn-components/ui/table";

import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../../../shadcn-components/ui/select";

import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "../../../shadcn-components/ui/tooltip";

import { BsFillCloudArrowUpFill, BsPatchCheckFill } from "react-icons/bs";
import { TbCircleCheck } from "react-icons/tb";
import { TiDeleteOutline } from "react-icons/ti";
import { AuthContext } from "../../../context/Auth/AuthContext";
import DeletePopup from "./DeletePopup";
import LinkPopup from "./LinkPopup";
import ApproveCasePopup from "./ApproveCasePopup";
import RejectCasePopup from "./RejectCasePopup";
import BulkDeletePopup from "./BulkDeletePopup";
import DocumentViewer from "./all-documents/DocumentViewer";
import "../../../styles/Table.css";

/*** Component for listing all the document requests  ***/
const RequestedDocumentsTable = ({
	clientID,
	setCategoryUpdatedToTrue,
	documentResponse,
	loading,
	currentPage,
	setCurrentPage,
	totalPages,
	columnFilters,
	setColumnFilters,
	pageSize,
	setPageSize,
	globalFilter,
	setGlobalFilter,
	bulkDeleteRequests,
	setBulkDeleteRequests,
	sorting,
	setSorting,
	changeDocumentViewerState,
}) => {
	const data = useMemo(() => documentResponse, [documentResponse]);
	const { token } = useContext(AuthContext);
	const [bulkDeleteRequestIds, setBulkDeleteRequestIds] = useState();
	const [columnVisibility, setColumnVisibility] = useState({});
	const [rowSelection, setRowSelection] = useState({});
	const [showFilterButton, setShowFilterButton] = useState(false);

	const columns = [
		{
			id: "select",
			header: ({ table }) => (
				<div className="flex items-center">
					<Checkbox
						checked={
							table.getIsAllPageRowsSelected() ||
							(table.getIsSomePageRowsSelected() && "indeterminate")
						}
						onCheckedChange={(value) =>
							table.toggleAllPageRowsSelected(!!value)
						}
						aria-label="Select all"
					/>
					{(table.getIsSomePageRowsSelected() ||
						table.getIsAllPageRowsSelected()) && (
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button
									variant="outline"
									className="p-0 !border-none !shadow-none bg-transparent"
								>
									<ChevronDownIcon className="" />
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align="end">
								<DropdownMenuItem onSelect={() => handleBulkAction("delete")}>
									Delete
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					)}
				</div>
			),
			cell: ({ row }) => (
				<Checkbox
					checked={row.getIsSelected()}
					onCheckedChange={(value) => row.toggleSelected(!!value)}
					aria-label="Select row"
				/>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: "document_status",
			filterable: false,
			displayName: "Status",
			header: ({ column }) => (
				<Button
					className="flex items-center justify-center w-full p-0"
					variant="ghost"
				>
					Status
				</Button>
			),
			cell: ({ row }) => (
				<span className="flex items-center justify-center case-doc-tables">
					{row.getValue("document_status") ? (
						row.getValue("document_status")?.toLowerCase() === "requested" ? (
							<TooltipProvider>
								<Tooltip>
									<TooltipTrigger>
										<span className="request-doc-request-icon request-doc-icon">
											<BsFillCloudArrowUpFill />
										</span>
									</TooltipTrigger>
									<TooltipContent className="bg-gray-600">
										<p>Requested</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						) : row.getValue("document_status").toLowerCase() === "uploaded" ? (
							<TooltipProvider>
								<Tooltip>
									<TooltipTrigger>
										<span className="request-doc-upload-icon request-doc-icon">
											<BsFillCloudArrowUpFill />
										</span>
									</TooltipTrigger>
									<TooltipContent className="bg-gray-600">
										<p>Uploaded</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						) : row.getValue("document_status").toLowerCase() === "approved" ? (
							<TooltipProvider>
								<Tooltip>
									<TooltipTrigger>
										<span className="request-doc-approved-icon request-doc-icon">
											<TbCircleCheck />
										</span>
									</TooltipTrigger>
									<TooltipContent className="bg-gray-600">
										<p>Approved</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						) : row.getValue("document_status").toLowerCase() === "rejected" ? (
							<TooltipProvider>
								<Tooltip>
									<TooltipTrigger>
										<span className="request-doc-rejected-icon request-doc-icon">
											<TiDeleteOutline />
										</span>
									</TooltipTrigger>
									<TooltipContent className="bg-gray-600">
										<p>Rejected</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						) : null
					) : null}
				</span>
			),
			enableSorting: false,
			enableHiding: false,
			enableColumnFilter: false,
		},
		{
			accessorKey: "client_name",
			displayName: "Client Name",
			//   filterable: true,
			header: ({ column }) => {
				return (
					<Button
						variant="ghost"
						className="p-0"
						onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					>
						Client Name
						<CaretSortIcon className="ml-2 h-4 w-4" />
					</Button>
				);
			},
			cell: ({ row }) => (
				<div className="capitalize">{row.getValue("client_name")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "document_req_name",
			displayName: "Requested Doc Name",
			filterable: true,
			header: ({ column }) => {
				return (
					<Button
						variant="ghost"
						className="p-0"
						onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					>
						Requested Doc Name
						<CaretSortIcon className="ml-2 h-4 w-4" />
					</Button>
				);
			},
			cell: ({ row }) => (
				<div className="wrap-text limited-width capitalize">
					{row.getValue("document_req_name")}
				</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "document_stage",
			displayName: "Service",
			filterable: true,
			header: ({ column }) => {
				return (
					<Button
						variant="ghost"
						className="p-0"
						onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					>
						Service
						<CaretSortIcon className="ml-2 h-4 w-4" />
					</Button>
				);
			},
			cell: ({ row }) => (
				<div className="capitalize">{row.getValue("document_stage")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "document_category",
			displayName: "Category",
			filterable: false,
			header: ({ column }) => (
				<Button
					variant="ghost"
					className="p-0"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					Category
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">{row.getValue("document_category")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "created_at",
			displayName: "Created At",
			header: ({ column }) => (
				<Button
					variant="ghost"
					className="p-0"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					Created At
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">{row.getValue("created_at")}</div>
			),
		},
		{
			accessorKey: "updated_at",
			displayName: "Modified At",
			header: ({ column }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					className="p-0"
				>
					Modified At
					<CaretSortIcon className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }) => (
				<div className="capitalize">{row.getValue("updated_at")}</div>
			),
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			id: "actions",
			displayName: "Actions",
			header: ({ column }) => (
				<Button variant="ghost" className="p-0 !cursor-default">
					Actions
				</Button>
			),
			enableHiding: false,
			cell: ({ row }) => {
				return (
					<div className="flex gap-2">
						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger>
									<span>
										<ApproveCasePopup
											tableValues={row.original}
											setCategoryUpdatedToTrue={setCategoryUpdatedToTrue}
										/>
									</span>
								</TooltipTrigger>
								<TooltipContent className="bg-gray-600">
									<p>Approve</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger>
									<span>
										<RejectCasePopup
											tableValues={row.original}
											setCategoryUpdatedToTrue={setCategoryUpdatedToTrue}
										/>
									</span>
								</TooltipTrigger>
								<TooltipContent className="bg-gray-600">
									<p>Reject</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
						{row.original.document_status?.toLowerCase() === "uploaded" ||
						row.original.document_status?.toLowerCase() === "approved" ? (
							<>
								<TooltipProvider>
									<Tooltip>
										<TooltipTrigger>
											<span>
												<DocumentViewer
													tableValues={row.original}
													changeDocumentViewerState={changeDocumentViewerState}
												/>
											</span>
										</TooltipTrigger>
										<TooltipContent className="bg-gray-600">
											<p>View</p>
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							</>
						) : null}
						{row.original.document_status?.toLowerCase() === "approved" ? (
							<>
								<TooltipProvider>
									<Tooltip>
										<TooltipTrigger>
											<span>
												<LinkPopup
													tableValues={row.original}
													clientID={clientID}
													setCategoryUpdatedToTrue={setCategoryUpdatedToTrue}
												/>
											</span>
										</TooltipTrigger>
										<TooltipContent className="bg-gray-600">
											<p>Download</p>
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							</>
						) : null}

						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger>
									<span>
										<DeletePopup
											tableValues={row.original}
											setCategoryUpdatedToTrue={setCategoryUpdatedToTrue}
										/>
									</span>
								</TooltipTrigger>
								<TooltipContent className="bg-gray-600">
									<p>Delete</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					</div>
				);
			},
		},
	];

	const handleBulkAction = async (actionType) => {
		const selectedRowsData = table
			.getFilteredSelectedRowModel()
			.rows.map((row) => row.original);
		const ids = selectedRowsData
			.map((row) => row.document_request_id)
			.join(",");
		if (actionType === "delete") {
			setBulkDeleteRequests(true);
			setBulkDeleteRequestIds(ids);
		}
	};

	const table = useReactTable({
		data: data,
		columns,
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		getCoreRowModel: getCoreRowModel(),
		initialState: {
			pageSize: 20,
		},
		manualPagination: true,
		manualSorting: true,
		getSortedRowModel: getSortedRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFilteredRowModel: getFilteredRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		onRowSelectionChange: setRowSelection,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
			rowSelection,
			pageSize,
		},

		globalFilterFn: (rows, columnFilters) => {
			const [globalFilter] = columnFilters.filter(
				(filter) => filter.id === "global"
			);
			if (!globalFilter || !globalFilter.value) return rows;

			return rows.filter((row) => {
				const name = row.original.name.toLowerCase(); // Assuming 'name' is the column key
				return name.includes(globalFilter.value.toLowerCase());
			});
		},
		onGlobalFilterChange: (filterValue) => {
			setGlobalFilter(filterValue); // Update the global filter state
		},
	});

	return (
		<>
			{bulkDeleteRequests ? (
				<BulkDeletePopup
					setCategoryUpdatedToTrue={setCategoryUpdatedToTrue}
					Requestbulkid={bulkDeleteRequestIds}
					bulkDeleteRequests={bulkDeleteRequests}
					setBulkDeleteRequests={setBulkDeleteRequests}
					setRowSelection={setRowSelection}
				/>
			) : null}
			<div className="table-content">
				<div className="w-full bg-white px-2">
					<div className="flex justify-between items-center py-4 flex-wrap gap-4">
						<Input
							placeholder="Search By Requested Document Name..."
							value={globalFilter}
							onChange={(event) => table.setGlobalFilter(event.target.value)}
							className="max-w-sm"
						/>
						<div className="flex gap-2">
							{/* <Button
                variant="outline"
                className=""
                onClick={() => setShowFilterButton(!showFilterButton)}
              >
                <MixerHorizontalIcon className="mr-1" />
                Filter
              </Button> */}

							<DropdownMenu>
								<DropdownMenuTrigger asChild>
									<Button variant="outline" className="ml-auto">
										Columns <ChevronDownIcon className="ml-2 h-4 w-4" />
									</Button>
								</DropdownMenuTrigger>
								<DropdownMenuContent align="end">
									{table
										.getAllColumns()
										.filter((column) => column.getCanHide())
										.map((column) => {
											return (
												<DropdownMenuCheckboxItem
													key={column.id}
													className="capitalize"
													checked={column.getIsVisible()}
													onCheckedChange={(value) =>
														column.toggleVisibility(!!value)
													}
												>
													{column.columnDef.displayName}
												</DropdownMenuCheckboxItem>
											);
										})}
								</DropdownMenuContent>
							</DropdownMenu>
						</div>
					</div>
					{showFilterButton && (
						<DataToolbar
							table={table}
							apiUrl="/api/get-documentrequest-filter-name"
							clientID={clientID}
						/>
					)}

					<div className="rounded-md border">
						{loading ? (
							<TableSkeleton />
						) : (
							<Table>
								<TableHeader className="pb-4 sticky top-0 bg-white z-[1]">
									{table.getHeaderGroups().map((headerGroup) => (
										<TableRow key={headerGroup.id}>
											{headerGroup.headers.map((header) => {
												return (
													<TableHead key={header.id}>
														{header.isPlaceholder
															? null
															: flexRender(
																	header.column.columnDef.header,
																	header.getContext()
															  )}
													</TableHead>
												);
											})}
										</TableRow>
									))}
								</TableHeader>
								<TableBody>
									{table.getRowModel().rows?.length ? (
										table.getRowModel().rows.map((row) => (
											<TableRow
												key={row.id}
												data-state={row.getIsSelected() && "selected"}
											>
												{row.getVisibleCells().map((cell) => (
													<TableCell key={cell.id}>
														{flexRender(
															cell.column.columnDef.cell,
															cell.getContext()
														)}
													</TableCell>
												))}
											</TableRow>
										))
									) : (
										<TableRow>
											<TableCell
												colSpan={columns.length}
												className="h-24 text-center"
											>
												{loading ? <TableSkeleton /> : "no records found"}
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						)}
					</div>
					<div className="flex items-center justify-between space-x-2 py-4 flex-wrap gap-4">
						<div className="flex items-center gap-5">
							<div className="flex-1 text-sm text-muted-foreground">
								{table.getFilteredSelectedRowModel().rows.length} of{" "}
								{table.getFilteredRowModel().rows.length} row(s) selected.
							</div>
							<div className="flex items-center space-x-2">
								<p className="text-sm font-medium">Rows per page</p>
								<Select
									value={`${table.getState().pageSize}`}
									onValueChange={(value) => {
										setPageSize(Number(value));
										setCurrentPage(1);
									}}
								>
									<SelectTrigger className="w-auto">
										<SelectValue placeholder={table.getState().pageSize} />
									</SelectTrigger>
									<SelectContent>
										<SelectGroup>
											{[10, 20, 30, 40, 50].map((pageSize) => (
												<SelectItem key={pageSize} value={`${pageSize}`}>
													{pageSize}
												</SelectItem>
											))}
										</SelectGroup>
									</SelectContent>
								</Select>
							</div>
						</div>
						<div className="space-x-2">
							<PaginationSection
								setCurrentPage={setCurrentPage}
								totalPages={totalPages}
								currentPage={currentPage}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default RequestedDocumentsTable;
