import { BsFillCloudArrowUpFill } from "react-icons/bs";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "../../shadcn-components/ui/card";

import { Skeleton } from "../../shadcn-components/ui/skeleton";
import { useEffect, useState } from "react";
import dashboardPendingRequests from "../../api/dashboardPendingRequests";
import { Link } from "react-router-dom";

function PendingRequestsCard({ customBoxShadow, token }) {
  const [
    dashboardPendingRequestsResponse,
    setDashboardPendingRequestsResponse,
  ] = useState({});
  const [loading, setLoading] = useState(true);

  const dashboardPendingRequestsData = async () => {
    const response = await dashboardPendingRequests({ token });
    if (response.code === 200) {
      setDashboardPendingRequestsResponse(response.data);
    }
  };

  useEffect(() => {
    async function fetchData() {
      await dashboardPendingRequestsData();
      setLoading(false);
    }
    fetchData();
  }, []);

  return (
    <Card style={customBoxShadow}>
      <CardHeader className="pb-3">
        <CardTitle className="flex items-center justify-between  gap-3 notification-title-requests letterSpacing p-4">
          <p className="text-xl font-semibold text-gray-800">
            Pending Requests
          </p>
          <span className="request-doc-request-icon request-doc-icon text-blue-600 transition-transform duration-300 hover:scale-110">
            <BsFillCloudArrowUpFill />
          </span>
        </CardTitle>
      </CardHeader>

      <CardContent className="overflow-y-scroll notification-container">
        {!loading ? (
          dashboardPendingRequestsResponse.length > 0 ? (
            dashboardPendingRequestsResponse.map((pendingRequests) => (
              <div
                className="latest-notification"
                key={pendingRequests.contact_id}
              >
                <Link
                  to={`/clients/${pendingRequests.contact_id}`}
                  className="go-to-password-reset-form"
                  target="_blank"
                >
                  {pendingRequests.name}
                </Link>
              </div>
            ))
          ) : (
            <div className="latest-notification">No pending requests</div>
          )
        ) : (
          <div className="space-y-4">
            {Array(10)
              .fill(0)
              .map((_, index) => (
                <Skeleton key={index} className="h-6 w-full" />
              ))}
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export default PendingRequestsCard;
