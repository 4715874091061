import React, { useState, useContext } from "react";
import { FaUserCircle } from "react-icons/fa";
import { MdNotificationsNone } from "react-icons/md";
import NotificationDropdown from "./NotificationDropdown";
import Dropdown from "./Dropdown";
import "../../styles/Navbar.css";

const Navbar = () => {
  const [profileImgClicked, setProfileImgClicked] = useState(false);
  const [notificationIconClicked, setNotificationIconClicked] = useState(false);

  const closeNotificationBox = () => {
    setNotificationIconClicked(false);
  };

  // const getNotification = async () => {
  //   setNotificationIconClicked(!notificationIconClicked);
  //   const data = await notificationData({ token });
  // };

  return (
    <div className="navbar-container">
      <div className="navbar-icon-container">
        {/* <div className="dropdown">
          <MdNotificationsNone
            onClick={() => {
              setNotificationIconClicked(!notificationIconClicked);
              setProfileImgClicked(false);
            }}
          />
          {notificationIconClicked && (
            <NotificationDropdown closeNotificationBox={closeNotificationBox} />
          )}
        </div> */}
        <div
          className="dropdown"
          onClick={() => {
            setNotificationIconClicked(false);
            setProfileImgClicked(!profileImgClicked);
          }}
        >
          <FaUserCircle />
          {profileImgClicked && <Dropdown />}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
