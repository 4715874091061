import baseUrl from "../config/baseUrl";

const sendEmail = async ({ token, contact_id, document_id, recipient, recipient_email_id }) => {
  try {
    const response = await fetch(
      `${baseUrl}/api/send_mail_database_entry?contact_id=${contact_id}&document_id=${document_id}&recipient_email_id=${recipient_email_id}&recipient_name=${recipient}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // body: JSON.stringify(sendData),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default sendEmail;
