import React, { useState, useContext, useEffect } from "react";
import { MdDelete } from "react-icons/md";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import ButtonUI from "../material-ui/ButtonUI";
import { LiaFileContractSolid } from "react-icons/lia";
import { DocusealBuilder } from '@docuseal/react'
import { AuthContext } from "../../context/Auth/AuthContext";
import { AiFillCloseCircle } from "react-icons/ai";

const SetSignaturePopup = (
  { setContractTemplateId, setContractTemplateSlug }
  // { setDocumentRequestSettingsAddedToTrue, id }
) => {
  const [open, setOpen] = useState(true);

  const [customCss, setCustomCss] = useState(`
  .sticky:has(> .tabler-icon-info-circle){
    display: none;
  }

  #title_container #save_button {
    display: none;
  }
`);

  const docuSignToken = process.env.REACT_APP_DOCUSEAL_TOKEN;

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpload = (data) => {
    setCustomCss(`
    .sticky:has(> .tabler-icon-info-circle){
      display: none;
    }

    #title_container #save_button {
    display: block;
  }
`);
  };

  const handleSave = (data) => {
    setContractTemplateId(data?.id);
    setContractTemplateSlug(data?.slug);
    setOpen(false);
  };

  return (
    <div className="set-signature">
      <div className="dialog-modal">

        <Dialog
          id="set-sign-modal"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            className="edit-doc-modal"
            onClose={handleClose}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
              p: 2,
            }}
          >
            <div className="popup-header-svg-icon">
              <LiaFileContractSolid />
            </div>
            <div className="popup-header-title">Set Signature</div>
            <ButtonUI
              formButton={false}
              variant="contained"
              buttonText={<AiFillCloseCircle />}
              style={{
                marginRight: "5px",
                padding: "8px",
                minWidth: "fit-content",
                border: "none",
              }}
              onClickFunc={handleClose}
            />
          </DialogTitle>
          <DialogContent>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <DocusealBuilder
                  token={docuSignToken}
                  withTitle="false"
                  autosave="false"
                  withSendButton="false"
                  withSignYourselfButton="false"
                  customCss={customCss}
                  onSave={handleSave}
                  onUpload={handleUpload}
                />
              </div>
              {/* <div style={{ width: '50%', textAlign: 'center' }}>
        <DocusealForm
          src="https://docuseal.co/d/rzk3n48d5fK1Fy"
          email="sajitha.chettiar@nablasol.com"
          customCss={customCss}
          expand="false"
        />
      </div> */}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default SetSignaturePopup;
