import baseUrl from "../config/baseUrl";

const deleteContracts = async ({ token, id }) => {
    const formData = new FormData();
    formData.append("id", id);

    try {
        const response = await fetch(
            `${baseUrl}/api/delete-contract-request-record?contract_request_id=${id}`,
            {
                method: "Delete",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        return error;
    }
};

export default deleteContracts;
