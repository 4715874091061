import React, { useContext } from "react";
import { MdContentCopy } from "react-icons/md";
import { toast } from "react-toastify";
import ButtonUI from "../../../material-ui/ButtonUI";
import { AuthContext } from "../../../../context/Auth/AuthContext";
import viewDocumentLink from "../../../../api/viewDocumentLink";

const CopyLinkPopup = ({ tableValues }) => {
  const { token } = useContext(AuthContext);
  const copyLink = () => {

    const copyLink = async () => {
      const downloadLinkFormResponse = await viewDocumentLink({
        token: token,
        docId: tableValues.document_id,
      });

      if (downloadLinkFormResponse?.code === 200 && downloadLinkFormResponse?.data[0]?.pdflink) {
        const link = downloadLinkFormResponse?.data[0].pdflink;
        navigator.clipboard.writeText(link);
        toast.success("Link copied successfully", {
          position: "top-center",
          autoClose: 3000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Download URL does not Exists", {
          position: "top-center",
          autoClose: 5000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };
    copyLink();
  };

  return (
    <ButtonUI
      formButton={false}
      variant="outlined"
      buttonText={<MdContentCopy />}
      style={{
        backgroundColor: "#28a745",
        // boxShadow: "0 0 0.4rem #2a9ceb57, inset 0 0 0 2px #2a9ceb57",
        color: "white",
        marginRight: "5px",
        padding: "8px",
        minWidth: "fit-content",
        border: "none",
      }}
      onClickFunc={copyLink}
    />
  );
};

export default CopyLinkPopup;
