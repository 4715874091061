import React, { useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TiDeleteOutline } from "react-icons/ti";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import ButtonUI from "../material-ui/ButtonUI";
import { AuthContext } from "../../context/Auth/AuthContext";
import editDocument from "../../api/editDocument";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import "../../styles/RejectCasePopup.css";

const rejectCaseFormSchema = yup.object().shape({
  addRequestName: yup.string().required("Request List is required field"),
});

const LinkToRequest = ({
  documentId,
  requestOptions,
  setLinkRequest,
  setReqStatus,
  setRequestId,
  setLinkRequestOpen,
  currentTabId,
  closeTabById,
  setSelectedRequestLabel,
}) => {
  const [open, setOpen] = useState(true);
  const { token, user_id } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(rejectCaseFormSchema),
  });

  const filterOptionsLinkList = createFilterOptions({
    matchFrom: "any",
    ignoreCase: true, // Ignore case sensitivity
    stringify: (option) => option.document_req_name,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLinkRequestOpen(false);
  };

  const onLinkRequestSubmit = async (addDocumentFormData) => {
    const linkRequestResponse = await editDocument({
      token: token,
      user_id: user_id,
      document_id: documentId,
      document_request_id: addDocumentFormData.addRequestName,
    });

    if (linkRequestResponse?.code === 200) {
      const selectedRequestName = requestOptions.find(
        (option) =>
          option.document_request_id === addDocumentFormData.addRequestName
      ).document_req_name;
      setSelectedRequestLabel(selectedRequestName);

      setOpen(false);
      toast.success("Document is linked with request successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLinkRequest(true);
      setReqStatus("Uploaded");
      if (setRequestId) {
        setRequestId(linkRequestResponse?.data?.document_request_id);
      }
      setLinkRequestOpen(false);
      if (currentTabId) {
        closeTabById(currentTabId);
      }
    } else {
      setOpen(false);
      toast.error("Error while linking the document to the request", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLinkRequest(false);
      setLinkRequestOpen(false);
    }
  };

  return (
    <div className="dialog-modal">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="edit-doc-modal">
          <div className="popup-header-svg-icon">
            <TiDeleteOutline />
          </div>
          <div className="popup-header-title">Link to Request List</div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <FormControl className="" fullWidth>
                <Controller
                  control={control}
                  name="addRequestName"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      options={requestOptions}
                      filterOptions={filterOptionsLinkList}
                      getOptionLabel={(option) =>
                        option.document_req_name || ""
                      }
                      getOptionKey={(option) =>
                        option.document_request_id || ""
                      }
                      onChange={(e, value) => {
                        onChange(value ? value.document_request_id : "");
                      }}
                      value={
                        requestOptions?.find(
                          (option) => option.document_request_id === value
                        ) || null
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={option.document_request_id}>
                          {option.document_req_name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Request List"
                          variant="outlined"
                        />
                      )}
                      sx={{ paddingTop: "10px" }}
                    />
                    // <Autocomplete
                    //   options={requestOptions}
                    //   filterOptions={filterOptionsLinkList}
                    //   getOptionKey={(option) =>
                    //     option.document_request_id || ""
                    //   }
                    //   getOptionLabel={(option) =>
                    //     option.document_req_name || ""
                    //   }
                    //   renderInput={(params) => (
                    //     <TextField
                    //       {...params}
                    //       label="Request List"
                    //       variant="outlined"
                    //     />
                    //   )}
                    // />
                  )}
                />
              </FormControl>
              {/* <FormControl className="reject-doc-select-ui">
                <InputLabel
                  className="reject-doc-select-label"
                  id="select-label"
                >
                  Request List
                </InputLabel>
                <Controller
                  control={control}
                  name="addRequestName"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="addRequestName"
                      sx={{ margin: "10px 0px" }}
                      labelId="select-label"
                      variant="outlined"
                      id="simple-select"
                      value={value}
                      onChange={onChange}
                      label="Request List"
                      {...register("addRequestName")}
                    >
                      {requestOptions.map((option) => (
                        <MenuItem
                          key={option.document_request_id}
                          value={option.document_request_id}
                        >
                          {option.document_req_name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl> */}
              <span className="error-message">
                {errors["addRequestName"]?.message}
              </span>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-end" }}>
          <ButtonUI
            formButton={false}
            variant="outlined"
            buttonText="No"
            style={{
              margin: "15px 0px",
              marginTop: "0px",
              backgroundColor: "#fff",
              color: "#007bff",
              border: "1px solid #007bff",
            }}
            onClickFunc={handleClose}
          />
          <ButtonUI
            variant="outlined"
            buttonText="Yes"
            style={{
              margin: "15px",
              marginTop: "0px",
              backgroundColor: "#fff",
              color: "##007bff",
              border: "1px solid #007bff",
            }}
            autoFocus={true}
            handleSubmit={handleSubmit}
            onFormSubmit={onLinkRequestSubmit}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LinkToRequest;
