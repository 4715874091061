import React, { useState, useEffect, useContext, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import Navbar from "../components/common/Navbar";
import PdfViewer from "../components/pdf-viewer/PdfViewer";
import ContractsTable from "../components/contracts/ContractsTable";
import { AuthContext } from "../context/Auth/AuthContext";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { DocusealForm } from '@docuseal/react'

import {
  CaretSortIcon,
  ChevronDownIcon,
  StarIcon,
  StarFilledIcon,
} from "@radix-ui/react-icons";

import "../styles/Users.css";

/*** Main component for listing all the contracts and for showing 'Send Contracts for Signature' button ***/
const Contracts = () => {
  const customCss = `
  #expand_form_button {
    display: none;
  }

  .sticky:has(> .tabler-icon-info-circle){
    display: none;
  }

  .field-area {
    pointer-events: none;
  }
`;
  const [
    showAddContractsForm,
    setShowAddContractsForm,
  ] = useState(false);

  const { token, user_id, is_admin } = useContext(AuthContext);
  const [tab, setTab] = useState([
    {
      id: uuidv4(),
      contractID: null,
      title: "All Contracts",
      content: (
        addContractTab,
        showAddContractsForm,
        onAddContractsButtonClick,
      ) => (
        <ContractsTable
          addContractTab={addContractTab}
          showAddContractsForm={
            showAddContractsForm
          }
          onAddContractsButtonClick={
            onAddContractsButtonClick
          }
          contractsType="AllContracts"
        />
      ),
      canClose: false,
    },
  ]);
  const [tabValue, setTabValue] = useState(tab[0].id);
  const firstTabId = tab[0].id;

  const scrollRef = useRef(null);

  const truncate = (string, n) => {
    return string?.length > n ? string.substr(0, n - 1) + "..." : string;
  };

  const scrollLeft = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset;
  };

  const scrollRight = (scrollOffset) => {
    scrollRef.current.scrollLeft -= scrollOffset;
  };

  const changeActiveTab = () => {
    setTabValue(tab[0].id);
  };

  const addContractTab = (contract) => {
    const isContractAlreadySelected = tab.find(
      (tab) => tab.contractID === contract.contract_id
    );

    if (isContractAlreadySelected !== undefined) {
      return;
    }

    const findIndex = tab.splice(1, 0, {
      id: uuidv4(),
      contractID: contract.contract_request_id,
      title: truncate(contract.contract_name, 9),
      content: () => {
        return (
          !contract.contracts_revision_signed_date ? (
            <>
              <div className="pdf-viewer-container" style={{ maxHeight: 'fit-content' }}>
                <div className="pdf-viewer">
                  <DocusealForm
                    src={"https://docuseal.co/d/" + contract.template_slug}
                    email={process.env.REACT_APP_DOCUSEAL_USER_EMAIL}
                    customCss={customCss}
                    withTitle="false"
                    expand="false"
                  />
                </div>
              </div>
            </>) : (
            <PdfViewer
              documentType="pdf"
              clientID={contract.contract_request_id}
              documentID={contract.contract_request_id}
              documentTitle={contract.contract_name}
              changeActiveTab={changeActiveTab}
              document_table="contract_requests"
            />
          )
        );
      },
      canClose: true,
    });

    const filteredTab = tab.filter((value, index) => index !== findIndex);

    setTab(filteredTab);
  };

  const handleChange = (index) => {
    setTabValue(index);
  };

  const closeTab = (e, index) => {
    e.stopPropagation();
    const newTab = tab.filter((tab) => tab.id !== index);
    const allContractTabId = newTab[0].id;
    setTab(newTab);
    setTabValue(allContractTabId);
  };

  const onAddContractsButtonClick = () => {
    setShowAddContractsForm(!showAddContractsForm);
  };

  return (
    <div className="user-container">
      <div className="navbar">
        <Navbar />
      </div>
      <div className="user-table">
        <div className="user-content">
          {is_admin === "1" ? (
            <button
              className="add-user"
              onClick={onAddContractsButtonClick}
              style={{ visibility: firstTabId === tabValue ? 'visible' : 'hidden' }}
            >
              Send Contracts for Signature
            </button>
          ) : null}
          {!showAddContractsForm ? (
            <div
              className={
                tab.length > 7 ? "scrollable-tabs" : "not-scrollable-tabs"
              }
            >
              {tab.length > 7 ? (
                <div className="scroll-icons" onClick={() => scrollRight(150)}>
                  <IoIosArrowDropleftCircle />
                </div>
              ) : null}
              <div className="tabs-header" ref={scrollRef}>
                {tab.map((tab, index) => (
                  <div
                    key={tab.id}
                    className={
                      tabValue === tab.id
                        ? "tab-title active-tab-title"
                        : "tab-title"
                    }
                    onClick={() => handleChange(tab.id)}
                  >
                    <span>{tab.title}</span>
                    {tab.canClose ? (
                      <button
                        className="tab-close-icon"
                        onClick={(e) => closeTab(e, tab.id)}
                      >
                        <AiOutlineCloseCircle />
                      </button>
                    ) : null}
                  </div>
                ))}
              </div>
              {tab.length > 7 ? (
                <div className="scroll-icons" onClick={() => scrollLeft(150)}>
                  <IoIosArrowDroprightCircle />
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="tab-content">
            {tab.map((tab, index) => {
              return tabValue === tab.id ? (
                <div key={index}>
                  {tab.title === "All Contracts"
                    ? tab.content(
                      addContractTab,
                      showAddContractsForm,
                      onAddContractsButtonClick,
                    )
                    : tab.content()}
                </div>
              ) : null;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contracts;
