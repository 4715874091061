import React, { useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TiDeleteOutline } from "react-icons/ti";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import ButtonUI from "../../material-ui/ButtonUI";
import { AuthContext } from "../../../context/Auth/AuthContext";
import rejectRequestedCase from "../../../api/rejectRequestedCase";
import "../../../styles/RejectCasePopup.css";

const rejectCaseFormSchema = yup.object().shape({
  reason: yup.string().required("Reason is required field"),
});

/*** Component for rejecting the document from the Requested section ***/
const RejectCasePopup = ({ tableValues, setCategoryUpdatedToTrue }) => {
  const [open, setOpen] = useState(false);
  const { token } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(rejectCaseFormSchema),
  });

  const reasonList = [
    "Wrong Document",
    "Unreadable Document",
    "Document Incomplete",
    "Document Outdated",
    "Document Not Translated",
    "Document Missing Signatures",
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onRejectDocumentFormSubmit = async (addDocumentFormData) => {
    const rejectDocumentFormResponse = await rejectRequestedCase({
      token: token,
      docId: tableValues.document_request_id,
      reason: addDocumentFormData.reason,
    });

    if (rejectDocumentFormResponse?.code === 200) {
      setOpen(false);
      setCategoryUpdatedToTrue();
      toast.success("Document is rejected successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="dialog-modal">
      <ButtonUI
        formButton={false}
        variant="outlined"
        buttonText={<TiDeleteOutline />}
        style={{
          backgroundColor:
            tableValues.document_status?.toLowerCase() === "uploaded" ? "#dc3545" : "#d8d8e4df",
          // boxShadow:
          //   tableValues.status_c === "Uploaded"
          //     ? "0 0 0.4rem #2a9ceb57, inset 0 0 0 1px #2a9ceb57"
          //     : "none",
          color:
            tableValues.document_status?.toLowerCase() === "uploaded" ? "white" : "rgb(215 18 18)",
          marginRight: "5px",
          padding: "8px",
          minWidth: "fit-content",
          border: "none",
        }}
        disabled={tableValues.document_status?.toLowerCase() === "uploaded" ? false : true}
        onClickFunc={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="edit-doc-modal">
          <div className="popup-header-svg-icon">
            <TiDeleteOutline />
          </div>
          <div className="popup-header-title">Reject Document</div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <FormControl className="reject-doc-select-ui">
                <InputLabel
                  className="reject-doc-select-label"
                  id="select-label"
                >
                  Reason
                </InputLabel>
                <Controller
                  control={control}
                  name="addDocCategories"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="reason"
                      sx={{ margin: "10px 0px" }}
                      labelId="select-label"
                      variant="outlined"
                      id="simple-select"
                      value={value}
                      onChange={onChange}
                      label="Reason"
                      {...register("reason")}
                    >
                      {reasonList.map((reason) => (
                        <MenuItem key={reason} value={reason}>
                          {reason}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <span className="error-message">{errors["reason"]?.message}</span>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-end" }}>
          <ButtonUI
            formButton={false}
            variant="outlined"
            buttonText="No"
            style={{
              margin: "15px 0px",
              marginTop: "0px",
              backgroundColor: "#fff",
              color: "#007bff",
              border: "1px solid #007bff",
            }}
            onClickFunc={handleClose}
          />
          <ButtonUI
            variant="outlined"
            buttonText="Yes"
            style={{
              margin: "15px",
              marginTop: "0px",
              backgroundColor: "#fff",
              color: "##007bff",
              border: "1px solid #007bff",
            }}
            autoFocus={true}
            handleSubmit={handleSubmit}
            onFormSubmit={onRejectDocumentFormSubmit}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RejectCasePopup;
