import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MdModeEdit } from "react-icons/md";
import { RiCloseCircleFill } from "react-icons/ri";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import EditDocumentRequestSettingsForm from "./EditDocumentRequestSettingsForm";
import ButtonUI from "../material-ui/ButtonUI";
import { AuthContext } from "../../context/Auth/AuthContext";
import editDocumentRequestSettings from "../../api/editDocumentRequestSettings";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <RiCloseCircleFill />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const editDocumentRequestSettingsFormSchema = yup.object().shape({
  documentName: yup.string().required("Document Name is required field"),
  description: yup.string().required("Description is required field"),
  status: yup.string().required("Status is required field"),
  clientType: yup.string().nullable().required("Client Type is required field"),
  clientSubType: yup.string().nullable().required("Client Sub Type is required field"),
  requestType: yup.string().required("Request Type is required field"),
  stage: yup.string().required("Stage is required field"),
  ocrStatus: yup.string().required("OCR Status is required field"),
});

/*** Component for editing the Document Request Settings details ***/
const EditDocumentRequestSettingsPopup = ({ setDocumentRequestSettingsAddedToTrue, tableValues, typeOptions }) => {
  const [open, setOpen] = useState(false);
  const { token, user_id, is_admin } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(editDocumentRequestSettingsFormSchema),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onEditDocumentRequestSettingsFormSubmit = async (editDocumentRequestSettingsFormData) => {
    setLoading(true);
    const editDocumentFormResponse = await editDocumentRequestSettings({
      token: token,
      user_id: user_id,
      document_request_setting_id: tableValues.document_request_setting_id,
      document_name: editDocumentRequestSettingsFormData.documentName,
      document_desc: editDocumentRequestSettingsFormData.description,
      status: editDocumentRequestSettingsFormData.status,
      client_type: editDocumentRequestSettingsFormData.clientType,
      service_sub_type_c: editDocumentRequestSettingsFormData.clientSubType,
      request_type: editDocumentRequestSettingsFormData.requestType,
      document_stage: editDocumentRequestSettingsFormData.stage,
      ocr_status: editDocumentRequestSettingsFormData.ocrStatus,
      is_admin: is_admin,
    });

    if (editDocumentFormResponse.code === 200) {
      setDocumentRequestSettingsAddedToTrue();
      setOpen(false);
      setLoading(false);
      toast.success("Document Request Settings is updated successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      toast.error("Error while updating Document Request Settings", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="dialog-modal">
      <ButtonUI
        formButton={false}
        variant="outlined"
        buttonText={<MdModeEdit />}
        style={{
          backgroundColor: "#17a2b8",
          color: " white",
          marginRight: "5px",
          padding: "8px",
          minWidth: "fit-content",
          border: "none",
        }}
        onClickFunc={handleClickOpen}
      />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        className="custom-edit-modal"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          className="edit-doc-modal"
          onClose={handleClose}
        >
          <div className="popup-header-svg-icon">
            <MdModeEdit />
          </div>
          <div className="popup-header-title">Edit Document Request Template</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <EditDocumentRequestSettingsForm
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            tableValues={tableValues}
            typeOptions={typeOptions}
          />
        </DialogContent>
        <DialogActions sx={{ paddingBottom: "15px !important" }}>
          <div className="popup_submit_btn">
            <ButtonUI
              loading={loading}
              variant="contained"
              buttonText="Submit"
              style={{
                backgroundColor: "#007bff",
                boxShadow: "none",
                border: "1px solid #007bff",
                fontWeight: "500",
                borderRadius: "4px",
                letterSpacing: "0.07rem",
                marginTop: "20px",
              }}
              handleSubmit={handleSubmit}
              onFormSubmit={onEditDocumentRequestSettingsFormSubmit}
            />
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default EditDocumentRequestSettingsPopup;
