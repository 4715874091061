import React, { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import Navbar from "../components/common/Navbar";
import DocumentTable from "../components/document-table/DocumentTable";
import PdfViewer from "../components/pdf-viewer/PdfViewer";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import "../styles/Documents.css";
// import GlobalSearch from "../components/document-table/GlobalSearch";

/*** Main component for listing the document table & for showing the 'Add Documents' button ***/
const Documents = () => {
  const [tab, setTab] = useState([
    {
      id: uuidv4(),
      documentID: null,
      title: "All Documents",
      content: (
        addDocumentTab,
        showAddDocumentForm,
        onAddDocumentButtonClick
      ) => (
        <DocumentTable
          addDocumentTab={addDocumentTab}
          showAddDocumentForm={showAddDocumentForm}
          onAddDocumentButtonClick={onAddDocumentButtonClick}
        />
      ),
      canClose: false,
    },
  ]);
  const [tabValue, setTabValue] = useState(tab[0].id);
  const [documentDetails, setDocumentDetails] = useState([]);
  const [updatedFromPDF, setUpdatedFromPDF] = useState(false);

  const firstTabId = tab[0].id;

  const [showAddDocumentForm, setShowAddDocumentForm] = useState(false);
  const scrollRef = useRef(null);

  const truncate = (string, n) => {
    return string?.length > n ? string.substr(0, n - 1) + "..." : string;
  };

  const scrollLeft = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset;
  };

  const scrollRight = (scrollOffset) => {
    scrollRef.current.scrollLeft -= scrollOffset;
  };

  const changeActiveTab = () => {
    setTabValue(tab[0].id);
  };

  const addDocumentTab = (document) => {
    setUpdatedFromPDF(false);
    setDocumentDetails(document);
  };

  const addPdfViewerTab = (documentDetails) => {
    const isDocumentAlreadySelected = tab.find(
      (tab) => tab.documentID === documentDetails.document_id
    );

    const currentTabId = uuidv4();

    const newTab = {
      id: currentTabId,
      documentID: documentDetails.document_id,
      title: truncate(documentDetails.document_name, 9),
      content: () => {
        return (
          <DndProvider backend={HTML5Backend}>
            <PdfViewer
              documentType={documentDetails.document_type}
              clientID={documentDetails.contact_id}
              documentID={documentDetails.document_id}
              documentTitle={documentDetails.document_name}
              documentURL={documentDetails.document_url}
              changeActiveTab={changeActiveTab}
              document_table="documents"
              document_version={documentDetails.document_version}
              documentRequestID={documentDetails.document_request_id}
              documentRequestStatus={documentDetails.document_request_status}
              documentCategory={documentDetails.doc_category}
              documentDesc={documentDetails.document_desc}
              currentTabId={currentTabId}
              closeTabById={closeTabById}
              setDocumentDetails={setDocumentDetails}
              fromTable="Documents"
              setUpdatedFromPDF={setUpdatedFromPDF}
              linkedDocReqName={documentDetails.document_request_setting_name}
              documentSubCategory={documentDetails.document_sub_category}
              clientPhone={documentDetails.phone_number}
            />
          </DndProvider>
        );
      },
      canClose: true,
    };

    setTab((prevTabs) => {
      const tabIndex = prevTabs.findIndex(
        (tab) => tab.documentID === documentDetails.document_id
      );
      if (tabIndex !== -1) {
        // Update the existing tab at its current position
        const updatedTabs = [...prevTabs];
        updatedTabs[tabIndex] = newTab;
        return updatedTabs;
      } else {
        // Add the new tab at the second position
        return [prevTabs[0], newTab, ...prevTabs.slice(1)];
      }
    });

    if (isDocumentAlreadySelected !== undefined && updatedFromPDF) {
      setTabValue(currentTabId);
    }
  };

  const onAddDocumentButtonClick = () => {
    setShowAddDocumentForm(!showAddDocumentForm);
  };

  const handleChange = (index) => {
    setTabValue(index);
  };

  const closeTab = (e, index) => {
    e.stopPropagation();
    const newTab = tab.filter((tab) => tab.id !== index);
    const allDocumentTabId = newTab[0].id;
    setTab(newTab);
    setTabValue(allDocumentTabId);
  };

  const closeTabById = (index) => {
    const newTab = tab.filter((tab) => tab.id !== index);
    const allDocumentTabId = newTab[0].id;
    setTab(newTab);
    setTabValue(allDocumentTabId);
  };

  useEffect(() => {
    if (documentDetails.document_id) {
      addPdfViewerTab(documentDetails);
    }
  }, [documentDetails]);

  return (
    <div className="document-container">
      <div className="navbar">
        <Navbar />
      </div>
      <div className="document-content ">
        <div className="flex justify-between items-center flex-wrap-reverse mx-2 my-4 gap-5">
          {/* <GlobalSearch /> */}
          <button
            style={{
              visibility: firstTabId === tabValue ? "visible" : "hidden",
            }}
            className="add-document"
            onClick={onAddDocumentButtonClick}
          >
            Add Document
          </button>
        </div>

        {!showAddDocumentForm ? (
          <div
            className={
              tab.length > 7 ? "scrollable-tabs" : "not-scrollable-tabs"
            }
          >
            {tab.length > 7 ? (
              <div className="scroll-icons" onClick={() => scrollRight(150)}>
                <IoIosArrowDropleftCircle />
              </div>
            ) : null}
            <div className="tabs-header" ref={scrollRef}>
              {tab.map((tab, index) => (
                <div
                  key={tab.id}
                  className={
                    tabValue === tab.id
                      ? "tab-title active-tab-title"
                      : "tab-title"
                  }
                  onClick={() => handleChange(tab.id)}
                >
                  <span className="capitalize truncate">{tab.title}</span>
                  {tab.canClose ? (
                    <button
                      className="tab-close-icon"
                      onClick={(e) => closeTab(e, tab.id)}
                    >
                      <AiOutlineCloseCircle />
                    </button>
                  ) : null}
                </div>
              ))}
            </div>
            {tab.length > 7 ? (
              <div className="scroll-icons" onClick={() => scrollLeft(150)}>
                <IoIosArrowDroprightCircle />
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="tab-content">
          {tab.map((tab, index) => {
            return tabValue === tab.id ? (
              <div key={index}>
                {tab.title === "All Documents"
                  ? tab.content(
                      addDocumentTab,
                      showAddDocumentForm,
                      onAddDocumentButtonClick
                    )
                  : tab.content()}
              </div>
            ) : null;
          })}
        </div>
      </div>
    </div>
  );
};

export default Documents;
