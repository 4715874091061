import { createContext, useEffect, useReducer } from "react";
import AuthReducer from "./AuthReducer";

const INITIAL_STATE = {
  token: JSON.parse(localStorage.getItem("token")) || null,
  id: JSON.parse(localStorage.getItem("user"))?.id || null,
  user_id: JSON.parse(localStorage.getItem("user"))?.user_id || null,
  firstName: JSON.parse(localStorage.getItem("user"))?.firstName || null,
  lastName: JSON.parse(localStorage.getItem("user"))?.lastName || null,
  phoneNumber: JSON.parse(localStorage.getItem("user"))?.phoneNumber || null,
  is_admin: JSON.parse(localStorage.getItem("user"))?.is_admin || null,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  useEffect(() => {
    localStorage.setItem("token", JSON.stringify(state.token));
    localStorage.setItem(
      "user",
      JSON.stringify({
        id: state.id,
        user_id: state.user_id,
        firstName: state.firstName,
        lastName: state.lastName,
        phoneNumber: state.phoneNumber,
        is_admin: state.is_admin,
      })
    );
  }, [state.token]);

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        id: state.id,
        user_id: state.user_id,
        firstName: state.firstName,
        lastName: state.lastName,
        phoneNumber: state.phoneNumber,
        is_admin: state.is_admin,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
