import React, { useState, useContext, useEffect } from "react";
import { Controller } from "react-hook-form";
import { Grid, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../material-ui/InputUI";
import { AuthContext } from "../../context/Auth/AuthContext";
import getAllSubTypeList from "../../api/getAllSubTypeList";

/*** Component for listing all the form fields for editing the client details ***/
const EditClientForm = ({
	register,
	control,
	setValue,
	watch,
	errors,
	tableValues,
	typeOptions,
	open,
}) => {
	const [subTypeOptions, setSubTypeOptions] = useState([]);

	const { token } = useContext(AuthContext);

	const statusOptions = [
		{
			name: "Active",
			value: "active",
		},
		{
			name: "Inactive",
			value: "inactive",
		},
	];

	const selectedClientType = watch("addClientType");

	const clientSubTypeLoad = async (clientType) => {
		const typeId = clientType;
		const data = await getAllSubTypeList({ token, typeId });
		if (data.code == 200) {
			const filteredTypeList =
				data?.data !== null
					? data?.data.map((type) => {
							return {
								label: type.fields_name,
								typeId: type.field_setting_id,
							};
					  })
					: [];
			filteredTypeList.unshift({ label: "None", typeId: "None" });
			setSubTypeOptions(filteredTypeList);
		} else {
			setSubTypeOptions([]);
		}
	};

	useEffect(() => {
		if (
			selectedClientType !== undefined &&
			selectedClientType !== tableValues.type
		) {
			setValue("addClientSubType", null);
			clientSubTypeLoad(selectedClientType);
		}
		if (
			(selectedClientType === undefined && tableValues.type !== undefined) ||
			selectedClientType === tableValues.type
		) {
			clientSubTypeLoad(tableValues.type);
		}
	}, [selectedClientType]);

	return (
		<Grid className="edit-document">
			<div className="edit-document-form ">
				<div className="add-document-inputs">
					<div className="add-document-inputs-with-error-message">
						<Controller
							name="name"
							defaultValue={tableValues.name}
							control={control}
							render={({ field: { onChange, value } }) => (
								<InputUI
									name="name"
									label="Name"
									type="text"
									value={value ? value : ""}
									setValue={onChange}
									variant="outlined"
									cssStyles={{
										margin: "10px 0px",
									}}
									register={register}
									disabled={true}
								/>
							)}
						/>
						<span className="error-message">{errors["name"]?.message}</span>
					</div>
					<div className="add-document-inputs-with-error-message">
						<Controller
							name="client_reference_number"
							control={control}
							defaultValue={tableValues.client_reference_number}
							render={({ field: { onChange, value } }) => (
								<InputUI
									name="client_reference_number"
									label="Reference Number"
									type="text"
									value={value ? value : ""}
									setValue={onChange}
									variant="outlined"
									cssStyles={{
										margin: "10px 0px",
									}}
									register={register}
									disabled={true}
								/>
							)}
						/>

						<span className="error-message">
							{errors["client_reference_number"]?.message}
						</span>
					</div>
				</div>
				<div className="add-document-inputs">
					<div
						className="add-document-inputs-with-error-message"
						style={{ width: "100%" }}
					>
						<Controller
							name="description"
							control={control}
							defaultValue={tableValues.description || ""}
							render={({ field: { onChange, value } }) => (
								<InputUI
									name="description"
									label="Description"
									type="text"
									value={value ? value : ""}
									setValue={onChange}
									variant="outlined"
									cssStyles={{ margin: "10px 0px" }}
									register={register}
									disabled={false}
								/>
							)}
						/>

						<span className="error-message">
							{errors["description"]?.message}
						</span>
					</div>
				</div>
				<div className="add-document-inputs">
					<div className="add-document-inputs-with-error-message">
						<Controller
							name="phone_number"
							control={control}
							defaultValue={tableValues.phone_number}
							render={({ field: { onChange, value } }) => (
								<InputUI
									name="phone_number"
									label="Phone Number"
									type="text"
									value={value ? value : ""}
									disabled={true}
									setValue={onChange}
									variant="outlined"
									cssStyles={{ margin: "10px 0px" }}
									register={register}
								/>
							)}
						/>

						<span className="error-message">
							{errors["phone_number"]?.message}
						</span>
					</div>
					<div className="add-document-inputs-with-error-message">
						<Controller
							name="email_id"
							control={control}
							defaultValue={tableValues.email_id}
							render={({ field: { onChange, value } }) => (
								<InputUI
									name="email_id"
									label="Email ID"
									type="email"
									disabled={false}
									value={value ? value : ""}
									setValue={onChange}
									variant="outlined"
									cssStyles={{ margin: "10px 0px" }}
									register={register}
								/>
							)}
						/>

						<span className="error-message">{errors["email_id"]?.message}</span>
					</div>
				</div>
				<div className="add-document-inputs">
					<div className="add-document-inputs-with-error-message select-ui">
						<FormControl fullWidth>
							<InputLabel id="select-label">Client Category</InputLabel>
							<Controller
								control={control}
								name="addClientType"
								defaultValue={tableValues.type}
								render={({ field: { onChange, value } }) => (
									<Select
										name="addClientType"
										sx={{ margin: "10px 0px" }}
										labelId="select-label"
										variant="outlined"
										id="simple-select"
										value={value}
										disabled={true}
										onChange={onChange}
										label="Client Category"
										{...register("addClientType")}
									>
										{typeOptions?.map((option) => (
											<MenuItem key={option.typeId} value={option.typeId}>
												{option.label}
											</MenuItem>
										))}
									</Select>
								)}
							/>
						</FormControl>
						<span className="add-doc-error-message">
							{errors["addClientType"]?.message}
						</span>
					</div>
					<div className="add-document-inputs-with-error-message select-ui">
						<FormControl fullWidth>
							<InputLabel id="select-label">Client Sub Category</InputLabel>
							<Controller
								control={control}
								name="addClientSubType"
								defaultValue={tableValues.sub_type}
								render={({ field: { onChange, value } }) => (
									<Select
										name="addClientSubType"
										sx={{ margin: "10px 0px" }}
										labelId="select-label"
										variant="outlined"
										id="simple-select"
										value={value}
										onChange={onChange}
										disabled={true}
										label="Client Sub Category"
										{...register("addClientSubType")}
									>
										{subTypeOptions?.map((option) => (
											<MenuItem key={option.typeId} value={option.typeId}>
												{option.label}
											</MenuItem>
										))}
									</Select>
								)}
							/>
						</FormControl>
						<span className="add-doc-error-message">
							{errors["addClientSubType"]?.message}
						</span>
					</div>
				</div>
				{/* <div className="add-document-inputs">
					<div className="add-document-inputs-with-error-message select-ui">
						<FormControl fullWidth>
							<InputLabel id="select-label">Status</InputLabel>
							<Controller
								control={control}
								name="addClientStatus"
								defaultValue={tableValues.status
									.toLowerCase()
									.replace(/\s+/g, "")}
								render={({ field: { onChange, value } }) => (
									<Select
										name="addClientStatus"
										sx={{ margin: "10px 0px" }}
										labelId="select-label"
										variant="outlined"
										id="simple-select"
										value={value}
										onChange={onChange}
										label="Status"
										{...register("addClientStatus")}
									>
										{statusOptions.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.name}
											</MenuItem>
										))}
									</Select>
								)}
							/>
						</FormControl>
						<span className="add-doc-error-message">
							{errors["addClientStatus"]?.message}
						</span>
					</div>
				</div> */}
			</div>
		</Grid>
	);
};

export default EditClientForm;
