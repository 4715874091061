import baseUrl from "../config/baseUrl";

const linkDocument = async ({ token, docRequestId }) => {
  try {
    const response = await fetch(`${baseUrl}/api/link-document-url?document_request_id=${docRequestId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default linkDocument;
