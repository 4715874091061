import React from 'react';
import Avatar from '@mui/material/Avatar';

const AvatarUI = ({ children }) => {
	return (
		<Avatar
			style={{
				margin: '10px auto',
				backgroundColor: 'rgb(25 118 210)'
			}}>
			{children}
		</Avatar>
	);
};

export default AvatarUI;
