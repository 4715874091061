import * as React from "react";
import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import { cn } from "../../lib/utils";
import { Badge } from "../../shadcn-components/ui/badge";
import { Button } from "../../shadcn-components/ui/button";
import { ScrollArea } from "../../shadcn-components/ui/scroll-area";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from "../../shadcn-components/ui/command";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "../../shadcn-components/ui/popover";
import { Separator } from "../../shadcn-components/ui/separator";
import { Input } from "../../shadcn-components/ui/input";
import FilterSkeleton from "../common/FilterSkeleton";

const DataTableFacetedFilter = ({
	column,
	title,
	table,
	options,
	setParams,
	loading,
}) => {
	const [selectedValues, setSelectedValues] = React.useState(
		new Set(column?.getFilterValue() || [])
	);
	const [inputValue, setInputValue] = React.useState("");
	const [filteredOptions, setFilteredOptions] = React.useState(options);

	const handleSelectOption = (option) => {
		setSelectedValues((prevSelectedValues) => {
			const newSelectedValues = new Set(prevSelectedValues);
			if (newSelectedValues.has(option)) {
				newSelectedValues.delete(option);
			} else {
				newSelectedValues.add(option);
			}
			return newSelectedValues;
		});
	};

	const applyFilter = () => {
		const filterValues = Array.from(selectedValues).map(String);
		column?.setFilterValue(filterValues.length ? filterValues : undefined);
	};

	const clearFilter = () => {
		setSelectedValues(new Set());
		column?.setFilterValue(undefined);
	};

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
		setParams(() => ({
			[column?.id]: e.target.value,
		}));
	};

	React.useEffect(() => {
		setSelectedValues(new Set(column?.getFilterValue() || []));
	}, [column]);

	React.useEffect(() => {
		if (inputValue) {
			setFilteredOptions((prevFilteredOptions) => [
				...options?.filter(
					(option) =>
						option?.toLowerCase().includes(inputValue.toLowerCase()) &&
						!prevFilteredOptions.includes(option) // Prevent duplicates
				),
			]);
		} else {
			setFilteredOptions(options); // Show all options when input is empty
		}
	}, [inputValue, options]);

	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					size="sm"
					className="h-8 border-dashed capitalize"
				>
					{title}
					<ChevronDownIcon className="mr-2 h-4 w-4 ml-2" />
					{selectedValues?.size > 0 && (
						<>
							<Separator orientation="vertical" className="mx-2 h-4" />
							<Badge
								variant="secondary"
								className="rounded-sm px-1 font-normal lg:hidden bg-bg_app !text-white hover:!bg_bg_app hover:!text-white"
							>
								{selectedValues.size}
							</Badge>
							<div className="hidden space-x-1 lg:flex">
								{selectedValues.size > 2 ? (
									<Badge
										variant="secondary"
										className="rounded-sm px-1 font-normal bg-bg_app !text-white hover:!bg_bg_app hover:!text-white"
									>
										{selectedValues.size} selected
									</Badge>
								) : (
									filteredOptions
										.filter((option) => selectedValues.has(option))
										.map((option) => (
											<Badge
												key={option}
												variant="secondary"
												className="rounded-sm px-1 font-normal bg-bg_app !text-white hover:bg_bg_app hover:!text-white"
											>
												{option}
											</Badge>
										))
								)}
							</div>
						</>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-auto p-0" align="start">
				<Command>
					<Input
						placeholder={title}
						onChange={handleInputChange}
						className="capitalize"
					/>
					<CommandList className="max-h-[initial]">
						{loading ? (
							<FilterSkeleton />
						) : (
							<ScrollArea className="h-[200px] h-max-[200px] h-min-[100px]">
								{filteredOptions.length > 0 ? (
									<CommandGroup className="!overflow-y-auto">
										{filteredOptions?.map((option) => {
											return (
												<CommandItem
													key={option}
													onSelect={() =>
														handleSelectOption(option, selectedValues, column)
													}
												>
													<div
														className={cn(
															"mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
															selectedValues.has(option)
																? "bg-primary text-primary-foreground"
																: "opacity-50 [&_svg]:invisible"
														)}
													>
														<CheckIcon className={cn("h-4 w-4")} />
													</div>
													<span className="capitalize">
														{option.split("_").join(" ")}
													</span>
												</CommandItem>
											);
										})}
									</CommandGroup>
								) : (
									<span className="flex justify-center m-2">
										no results found!
									</span>
								)}
							</ScrollArea>
						)}

						{selectedValues.size > 0 && (
							<>
								<CommandSeparator />
								<CommandGroup>
									<CommandItem
										onSelect={applyFilter}
										className="justify-center text-center shadow-lg bg-bg_app text-white rounded-full hover:!bg_bg_app cursor-pointer"
									>
										Apply Filter
									</CommandItem>
									<CommandItem
										onSelect={clearFilter}
										className="justify-center text-center hover:underline cursor-pointer"
									>
										Clear filters
									</CommandItem>
								</CommandGroup>
							</>
						)}
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
};

export default DataTableFacetedFilter;
