import baseUrl from "../config/baseUrl";

const addClientContracts = async ({ token, contracts }) => {
  try {
    const response = await fetch(`${baseUrl}/api/add-contract-request-record`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(contracts),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default addClientContracts;
