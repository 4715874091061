import React, { useState, useContext } from "react";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonUI from "../../material-ui/ButtonUI";
import { AuthContext } from "../../../context/Auth/AuthContext";
import deleteRequestedClient from "../../../api/deleteRequestedClient";

/*** Component for showing the confirmation message before bulk deleting the Document requests from Document requests table ***/
const BulkDeletePopup = ({
  setCategoryUpdatedToTrue,
  Requestbulkid = [],
  bulkDeleteRequests,
  setBulkDeleteRequests,
  setRowSelection
}) => {
  const { token } = useContext(AuthContext);

  const handleClose = () => {
    setBulkDeleteRequests(false);
  };

  const onBulkDelete = async (Requestbulkid) => {
    const deleteAllRequestFormResponse = await deleteRequestedClient({
      token: token,
      docId: Requestbulkid,
    });

    if (deleteAllRequestFormResponse.code === 200) {
      setRowSelection({});
      setBulkDeleteRequests(false);
      setCategoryUpdatedToTrue();
      toast.success("Document request/s deleted successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Error while bulk deleting document request/s", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="dialog-modal">
      <Dialog
        open={bulkDeleteRequests}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="edit-doc-modal">
          <div className="popup-header-svg-icon">
            <MdDelete />
          </div>
          <div className="popup-header-title">Delete Document Request</div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete these document requests ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonUI
            formButton={false}
            variant="outlined"
            buttonText="No"
            style={{ marginTop: "15px" }}
            onClickFunc={handleClose}
          />
          <ButtonUI
            formButton={false}
            variant="outlined"
            buttonText="Yes"
            style={{ marginTop: "15px" }}
            autoFocus={true}
            onClickFunc={() => {
              onBulkDelete(Requestbulkid);
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BulkDeletePopup;
