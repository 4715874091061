import baseUrl from "../config/baseUrl";

const addContractTemplate = async ({
  token,
  userId,
  contract_name,
  contract_description,
  client_type,
  document_type,
  ocr_status,
  status,
  is_admin,
  template_id,
  template_slug
}) => {
  const formData = new FormData();
  formData.append("userId", userId);
  formData.append("contract_name", contract_name);
  formData.append("contract_description", contract_description);
  formData.append("client_type", client_type);
  formData.append("document_type", document_type);
  formData.append("ocr_status", ocr_status);
  formData.append("status", status);
  formData.append("is_admin", is_admin);
  formData.append("template_id", template_id);
  formData.append("template_slug", template_slug);

  try {
    const response = await fetch(`${baseUrl}/api/add-contract-request-setting-data`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default addContractTemplate;
